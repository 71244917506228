export const pricing = [
  {
    id: "cupid_lite",
    title: "Lite - 10 points",
    subtitle: "For the cost conscious",
    monthly: 49,
    annual: 480,
    oneTime: 1,
    priceSuffix: " / MO",
    features: ["10 match points", "0 bonus match points"],
    disclaimer: "All prices reflected are in USD",
    isHighlighted: false,
  },
  {
    id: "cupid_enhanced",
    title: "Intermediate - 25 points",
    subtitle: "Things are getting a little serious...",
    monthly: 79,
    annual: 780,
    oneTime: 2,
    priceSuffix: " / MO",
    features: ["20 match points", "5 bonus match points"],
    disclaimer: "All prices reflected are in USD",
    isHighlighted: true,
  },
  {
    id: "cupid_turbo",
    title: "Elite - 60 points",
    subtitle: "Everything you need to find the one",
    monthly: 69,
    annual: 680,
    oneTime: 4,
    priceSuffix: " / MO",
    features: ["40 match points", "20 bonus match points"],
    disclaimer: "All prices reflected are in USD",
    isHighlighted: false,
  },
];

export const faq = [
  {
    id: "faq-1",
    title: "What are match points?",
    subtitle: "Match points",
    text:
      "To reveal a person's photo or send a match request, you need match points. Revealing a person's photo will cost 1 match point while sending a match request will cost 2 match points. You are awarded free match points when you check-in everyday. You can also purchase more match points at very affordable prices!",
    link: "Check it out",
  },
  {
    id: "faq-3",
    title: "Do you do recurring payments or plans?",
    subtitle: "Recurring payments",
    text:
      "We have no recurring payment plans at this point. All purchases are one-time charges made through Stripe.",
    link: "Check it out",
  },
  {
    id: "faq-4",
    title: "What is your refund policy?",
    subtitle: "Refund policy",
    text:
      "We will do a one time, no questions asked refund to you. Simply email us at support@hollerdate.com with your billing email address so that we can track your purchase and issue a refund.",
    link: "Check it out",
  },
  {
    id: "faq-5",
    title: "Do you store my credit card information?",
    subtitle: "Credit card information",
    text:
      "No we don't and never will. All payments are handled securely using Stripe, an extremely popular payment processor used by many reputable online businesses.",
    link: "Check it out",
  },
  {
    id: "faq-6",
    title: "How can I contact you for more information?",
    subtitle: "Contact us",
    text:
      "Go to our contact page or simply drop us an email at support@hollerdate.com! We're extremely responsive.",
    link: "Check it out",
  },
];

export const plans = [
  {
    title: "Move deals through your funnel – fast",
    subtitle:
      "Our chatbots and live chat capture more of your best leads and convert them while they’re hot.",
  },
  {
    title: "On demand services right at your front-door",
    subtitle:
      "Our chatbots and live chat capture more of your best leads and convert them while they’re hot.",
  },
  {
    title: "Online 24/7 support",
    subtitle:
      "Our chatbots and live chat capture more of your best leads and convert them while they’re hot.",
  },
];
