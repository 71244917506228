export const AUTH_UI_START_LOADING = "AUTH_UI_START_LOADING";
export const AUTH_UI_STOP_LOADING = "AUTH_UI_STOP_LOADING";
export const REGISTER_UI_START_LOADING = "REGISTER_UI_START_LOADING";
export const REGISTER_UI_STOP_LOADING = "REGISTER_UI_STOP_LOADING";
export const RESET_UI_START_LOADING = "RESET_UI_START_LOADING";
export const RESET_UI_STOP_LOADING = "RESET_UI_STOP_LOADING";
export const SET_SNACK_BAR_MESSAGE = "SET_SNACK_BAR_MESSAGE";
export const CHANGE_UI_START_LOADING = "CHANGE_UI_START_LOADING";
export const CHANGE_UI_STOP_LOADING = "CHANGE_UI_STOP_LOADING";
export const MEMBERS_UI_START_LOADING = "MEMBERS_UI_START_LOADING";
export const MEMBERS_UI_STOP_LOADING = "MEMBERS_UI_STOP_LOADING";
export const MEMBERS_UI_UPDATE_START_LOADING =
  "MEMBERS_UI_UPDATE_START_LOADING";
export const MEMBERS_UI_UPDATE_STOP_LOADING = "MEMBERS_UI_UPDATE_STOP_LOADING";
export const ADMIN_UI_START_LOADING = "ADMIN_UI_START_LOADING";
export const ADMIN_UI_STOP_LOADING = "ADMIN_UI_STOP_LOADING";
export const PLAN_UI_START_LOADING = "PLAN_UI_START_LOADING";
export const PLAN_UI_STOP_LOADING = "PLAN_UI_STOP_LOADING";
export const CHAT_UI_START_LOADING = "CHAT_UI_START_LOADING";
export const CHAT_UI_STOP_LOADING = "CHAT_UI_STOP_LOADING";
export const CHAT_UPDATE_START_LOADING = "CHAT_UPDATE_START_LOADING";
export const CHAT_UPDATE_STOP_LOADING = "CHAT_UPDATE_STOP_LOADING";
export const DIALOG_UI_DISPLAY = "DIALOG_UI_DISPLAY";
export const DIALOG_UI_RESET_DISPLAY = "DIALOG_UI_RESET_DISPLAY";
export const FILTER_DISPLAY_UI_MODAL_DISPLAY =
  "FILTER_DISPLAY_UI_MODAL_DISPLAY";
export const SELECTED_PLAN_CONFIRMATION_UI_MODAL_DISPLAY =
  "SELECTED_PLAN_CONFIRMATION_UI_MODAL_DISPLAY";
