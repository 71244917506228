import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import AudioPlayer from "react-h5-audio-player";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Button,
  Chip,
  colors,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Icon } from "components/atoms";
import { signOut } from "store/actions/auth";
import { HOME_ROUTE } from "constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { getPaginatedMembersModerate } from "store/actions/members";
import {
  APPROVAL_STATUS,
  AUDIO_HELPER_TEXT,
  AUDIO_UNAVAILABLE_TEXT,
  GENDER,
  GENDER_PREFERENCE,
  LOAD_MORE,
  LOADING,
} from "constants/messages";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import BlockIcon from "@material-ui/icons/Block";
import { CardProduct } from "components/organisms";
import { SwiperImage } from "components/molecules";
import { retrieveCountryOptionWithCode } from "shared/config/ui";
import {
  ERROR,
  LABEL,
  PRIMARY,
  SUCCESS,
  WARNING,
  WHITE,
} from "constants/colors";
import { ALL } from "constants/auth";
import { approveUser, rejectUser, suspendUser } from "store/actions/admin";

const MALE = "male";

const useStyles = makeStyles((theme) => ({
  root: {},
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  titleCta: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  previewRoot: {
    marginBottom: 16,
  },
  swiperNavButton: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    padding: `${theme.spacing(2)}px !important`,
    background: `${PRIMARY} !important`,
  },
  locationCardPrice: {
    padding: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    background: WHITE,
    borderRadius: theme.spacing(1),
    zIndex: 3,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  fontPending: {
    color: WARNING,
  },
  locationCardReviewAvatar: {
    marginLeft: theme.spacing(-2),
    border: "3px solid white",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  locationCardReviewStar: {
    color: colors.yellow[800],
    marginRight: theme.spacing(1 / 2),
  },
  reviewCount: {
    marginLeft: theme.spacing(1),
  },
  audioRoot: {
    // width: 429,
    height: 300,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: "40%",
  },
  audioRootFemale: {
    backgroundImage: `url("/custom_images/hero/woman_shadow.jpg")`,
  },
  audioRootMale: {
    backgroundImage: `url("/custom_images/hero/man_shadow.jpg")`,
  },
  audioContainer: {
    marginBottom: 16,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  audioHelperText: {
    color: WHITE,
    textAlign: "center",
  },
  swiperRoot: {
    // width: 429,
  },
  image: {
    borderBottomLeftRadius: "40%",
  },
  iconButton: {
    width: 50,
    height: 50,
    boxShadow: "0 2px 10px 0 rgba(23,70,161,.11)",
  },
  iconReveal: {
    background: LABEL,
    "&:hover": {
      background: LABEL,
    },
  },
  iconInvite: {
    background: SUCCESS,
    "&:hover": {
      background: SUCCESS,
    },
  },
  iconApprove: {
    background: SUCCESS,
    "&:hover": {
      background: SUCCESS,
    },
    "&:disabled": {
      opacity: 0.4,
      background: SUCCESS,
    },
    marginRight: 8,
  },
  iconReject: {
    background: WARNING,
    "&:hover": {
      background: WARNING,
    },
    "&:disabled": {
      opacity: 0.4,
      background: WARNING,
    },
    marginRight: 8,
  },
  iconBlock: {
    background: ERROR,
    "&:hover": {
      background: ERROR,
    },
    "&:disabled": {
      opacity: 0.4,
      background: ERROR,
    },
  },
  iconUnblock: {
    background: SUCCESS,
    "&:hover": {
      background: SUCCESS,
    },
    "&:disabled": {
      opacity: 0.4,
      background: SUCCESS,
    },
  },
  icon: {
    color: WHITE,
    width: 25,
    height: 25,
  },
  genderContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  gender: {
    color: LABEL,
    borderColor: LABEL,
  },
  genderPreference: {
    color: PRIMARY,
    borderColor: PRIMARY,
    marginLeft: 8,
  },
  loadMoreContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const APPROVED = "APPROVED";
const SUSPENDED = "SUSPENDED";

const Moderate = (props) => {
  const { className, history, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [reveal, setReveal] = useState({});
  const adminIsLoading = useSelector((state) => state.ui.adminIsLoading);
  const membersIsLoading = useSelector((state) => state.ui.membersIsLoading);
  const membersModerate = useSelector((state) => state.members.membersModerate);

  const handleSignOut = () => {
    dispatch(signOut());
    history.replace(HOME_ROUTE);
  };

  useEffect(() => {
    dispatch(getPaginatedMembersModerate(2, ALL));
  }, [dispatch]);

  const renderAudio = (gender, audioURL) => {
    return (
      <div
        className={clsx(
          classes.audioRoot,
          gender === MALE ? classes.audioRootMale : classes.audioRootFemale
        )}
      >
        <div className={classes.audioContainer}>
          <Typography variant="body1" className={classes.audioHelperText}>
            {audioURL ? AUDIO_HELPER_TEXT : AUDIO_UNAVAILABLE_TEXT}
          </Typography>
        </div>
        {audioURL && <AudioPlayer src={audioURL} volume={0.5} />}
      </div>
    );
  };

  const renderReveal = (images) => {
    return (
      <SwiperImage
        className={classes.swiperRoot}
        navigationButtonStyle={classes.swiperNavButton}
        items={images}
        imageClassName={classes.image}
      />
    );
  };

  const handleVerdict = (status, userId) => {
    if (status === APPROVED) {
      dispatch(rejectUser(userId));
    } else {
      dispatch(approveUser(userId));
    }
  };

  const handleSuspend = (userId) => {
    dispatch(suspendUser(userId));
  };

  const loadMore = () => {
    dispatch(getPaginatedMembersModerate(2, ALL));
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <div className={classes.titleCta}>
            <Typography variant="h6" color="textPrimary">
              Moderate Members
            </Typography>
            <Button variant="outlined" color="primary" onClick={handleSignOut}>
              Log out
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} className={classes.container}>
            {Object.keys(membersModerate).map((docId) => {
              const member = membersModerate[docId];
              const {
                userId,
                audioURL,
                status,
                displayName,
                demographic,
              } = member;
              const { age, country, gender, genderPreference } =
                demographic || {};
              const countryOptions = retrieveCountryOptionWithCode(country);
              const { title } = countryOptions;
              const images = [
                { src: member.photoURL, srcSet: member.photoURL },
              ];
              return (
                <Grid key={userId} item xs={12} md={6} data-aos="fade-up">
                  <CardProduct
                    className={classes.previewRoot}
                    withShadow
                    liftUp
                    mediaContent={
                      <>
                        {!reveal[userId]
                          ? renderAudio(gender, audioURL)
                          : renderReveal(images)}

                        <div className={classes.locationCardPrice}>
                          <Typography
                            variant="body1"
                            color="primary"
                            className={clsx(
                              classes.fontWeight700,
                              classes.fontPending
                            )}
                          >
                            {APPROVAL_STATUS(status)}
                          </Typography>
                        </div>
                      </>
                    }
                    cardContent={
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            color="textPrimary"
                            align="left"
                            className={classes.fontWeight700}
                          >
                            {displayName}, {age}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            align="left"
                          >
                            {title}
                          </Typography>
                        </Grid>
                        <Grid item container justify="space-between" xs={12}>
                          <Grid item container xs={6} wrap="nowrap">
                            {/*<Chip*/}
                            {/*  className={classes.gender}*/}
                            {/*  label={GENDER(gender)}*/}
                            {/*  color="secondary"*/}
                            {/*  variant="outlined"*/}
                            {/*/>*/}
                            {gender === "female" ? (
                              <Icon
                                fontIconClass="fas fa-female"
                                size="medium"
                                fontIconColor={colors.pink[500]}
                              />
                            ) : (
                              <Icon
                                fontIconClass="fas fa-male"
                                size="medium"
                                fontIconColor={colors.blue[500]}
                              />
                            )}
                            <Chip
                              className={classes.genderPreference}
                              label={GENDER_PREFERENCE(genderPreference)}
                              color="secondary"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            alignItems="center"
                            justify="flex-end"
                            xs={6}
                          >
                            <IconButton
                              className={clsx(
                                classes.iconButton,
                                !reveal[userId]
                                  ? classes.iconReveal
                                  : classes.iconInvite
                              )}
                              onClick={() => {
                                reveal[userId] = !reveal[userId];
                                setReveal({ ...reveal });
                              }}
                            >
                              {!reveal[userId] ? (
                                <FavoriteIcon className={classes.icon} />
                              ) : (
                                <PersonAddIcon className={classes.icon} />
                              )}
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid item container justify="flex-end" xs={12}>
                          <IconButton
                            disabled={adminIsLoading || status === SUSPENDED}
                            className={clsx(
                              classes.iconButton,
                              status === APPROVED
                                ? classes.iconReject
                                : classes.iconApprove
                            )}
                            onClick={() => handleVerdict(status, userId)}
                          >
                            {status === APPROVED ? (
                              <ClearIcon className={classes.icon} />
                            ) : (
                              <CheckIcon className={classes.icon} />
                            )}
                          </IconButton>
                          <IconButton
                            disabled={adminIsLoading}
                            className={clsx(
                              classes.iconButton,
                              status === SUSPENDED
                                ? classes.iconUnblock
                                : classes.iconBlock
                            )}
                            onClick={() => handleSuspend(userId)}
                          >
                            <BlockIcon className={classes.icon} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.loadMoreContainer}>
            <Button
              disabled={membersIsLoading}
              variant="outlined"
              color="primary"
              onClick={loadMore}
            >
              {membersIsLoading ? LOADING : LOAD_MORE}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Moderate.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default withRouter(Moderate);
