const content = {
  overviewTitle: "Privacy policy",
  overviewDescription: "Here’s an overview of our privacy policy:",
  preambleTitle: "Preamble",
  preambleDescription:
    "Holler Away Pte. Limited built the Holler Date website as a Free service. This SERVICE is provided by Holler Away Pte. Limited at no cost and is intended for use as is. This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service. If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Holler Date unless otherwise defined in this Privacy Policy.",
  informationTitle: "Information Collection and Use",
  informationDescription:
    "For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to name, email. The information that we request will be retained on your device and is not collected by me in any way. The app does use third party services that may collect information used to identify you. Link to privacy policy of third party service providers used by the website.",
  logDataTitle: "Log Data",
  logDataDescription:
    "We want to inform you that whenever you use my Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.",
  cookiesTitle: "Cookies",
  cookiesDescription:
    "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.",
  serviceProvidersTitle: "Service Providers",
  serviceProvidersDescription:
    "We may employ third-party companies and individuals due to the following reasons: To facilitate our Service; To provide the Service on our behalf; To perform Service-related services; or To assist us in analyzing how our Service is used. We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.",
  securityTitle: "Security",
  securityDescription:
    "We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.",
  linkTitle: "Links to Other Sites",
  linkDescription:
    "This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.",
  childrenPrivacyTitle: "Minor’s Privacy",
  childrenPrivacyDescription:
    "These Services do not address anyone under the age of 18. We do not knowingly collect personally identifiable information from children under 18. In the case that we discover that a child under 18 has provided us with personal information, we will immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.",
  privacyChangesTitle: "Changes to This Privacy Policy",
  privacyChangesDescription:
    "We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. we will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.",
  contactUsTitle: "Contact Us",
  contactUsDescription:
    "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@hollerdate.com.",
  rights: "Information rights",
  rightsDescription:
    "You, as the user, will always have complete control of your information. You can update or delete any parts of your dating profile and account information via your profile settings page. You can also toggle your profile visibility from public view at any time. If you decide to close your account with us, you can choose to delete your account via your profile settings page. This account deletion is irreversible and will remove all traces of your data and account information from our systems. If your account has been suspended and you are unable to log in to delete your account information, please reach out to us via support@hollerdate.com to assist in your account deletion. We may, however, still retain non-personal information such as system account identifiers from errant users that violate our terms of use or community rules to prevent re-registration of the account. The only exception where we will retain your account information is if there is a legal request to do so, or when we have good faith to believe that such information is required to comply with Singapore or international law.",
};

export default content;
