import { SET_PLAN } from "store/types/planTypes";

const initialState = {
  plan: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAN:
      return {
        ...state,
        plan: action.plan,
      };
    default:
      return state;
  }
};

export default reducer;
