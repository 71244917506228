import {
  AUTH_UI_START_LOADING,
  AUTH_UI_STOP_LOADING,
  CHANGE_UI_START_LOADING,
  CHANGE_UI_STOP_LOADING,
  REGISTER_UI_START_LOADING,
  REGISTER_UI_STOP_LOADING,
  RESET_UI_START_LOADING,
  RESET_UI_STOP_LOADING,
  MEMBERS_UI_START_LOADING,
  MEMBERS_UI_STOP_LOADING,
  ADMIN_UI_START_LOADING,
  ADMIN_UI_STOP_LOADING,
  SET_SNACK_BAR_MESSAGE,
  DIALOG_UI_DISPLAY,
  DIALOG_UI_RESET_DISPLAY,
  PLAN_UI_START_LOADING,
  PLAN_UI_STOP_LOADING,
  MEMBERS_UI_UPDATE_START_LOADING,
  MEMBERS_UI_UPDATE_STOP_LOADING,
  CHAT_UI_START_LOADING,
  CHAT_UI_STOP_LOADING,
  CHAT_UPDATE_START_LOADING,
  CHAT_UPDATE_STOP_LOADING,
  SELECTED_PLAN_CONFIRMATION_UI_MODAL_DISPLAY,
  FILTER_DISPLAY_UI_MODAL_DISPLAY,
} from "store/types/uiTypes";

export const authUIStartLoading = () => {
  return {
    type: AUTH_UI_START_LOADING,
  };
};

export const authUIStopLoading = () => {
  return {
    type: AUTH_UI_STOP_LOADING,
  };
};

export const registerUIStartLoading = () => {
  return {
    type: REGISTER_UI_START_LOADING,
  };
};

export const registerUIStopLoading = () => {
  return {
    type: REGISTER_UI_STOP_LOADING,
  };
};

export const resetUIStartLoading = () => {
  return {
    type: RESET_UI_START_LOADING,
  };
};

export const resetUIStopLoading = () => {
  return {
    type: RESET_UI_STOP_LOADING,
  };
};

export const changeUIStartLoading = () => {
  return {
    type: CHANGE_UI_START_LOADING,
  };
};

export const changeUIStopLoading = () => {
  return {
    type: CHANGE_UI_STOP_LOADING,
  };
};

export const membersUIStartLoading = () => {
  return {
    type: MEMBERS_UI_START_LOADING,
  };
};

export const membersUIStopLoading = () => {
  return {
    type: MEMBERS_UI_STOP_LOADING,
  };
};

export const membersUpdateUIStartLoading = () => {
  return {
    type: MEMBERS_UI_UPDATE_START_LOADING,
  };
};

export const membersUpdateUIStopLoading = () => {
  return {
    type: MEMBERS_UI_UPDATE_STOP_LOADING,
  };
};

export const adminUIStartLoading = () => {
  return {
    type: ADMIN_UI_START_LOADING,
  };
};

export const adminUIStopLoading = () => {
  return {
    type: ADMIN_UI_STOP_LOADING,
  };
};

export const planUIStartLoading = () => {
  return {
    type: PLAN_UI_START_LOADING,
  };
};

export const planUIStopLoading = () => {
  return {
    type: PLAN_UI_STOP_LOADING,
  };
};

export const chatUIStartLoading = () => {
  return {
    type: CHAT_UI_START_LOADING,
  };
};

export const chatUIStopLoading = () => {
  return {
    type: CHAT_UI_STOP_LOADING,
  };
};

export const chatUpdateStartLoading = () => {
  return {
    type: CHAT_UPDATE_START_LOADING,
  };
};

export const chatUpdateStopLoading = () => {
  return {
    type: CHAT_UPDATE_STOP_LOADING,
  };
};

export const setSnackBarMessage = (snackBarMessage) => {
  return {
    type: SET_SNACK_BAR_MESSAGE,
    snackBarMessage,
  };
};

export const setDialog = (title, message, actions) => {
  return {
    type: DIALOG_UI_DISPLAY,
    dialog: {
      title,
      message,
      actions,
    },
  };
};

export const resetDialog = () => {
  return {
    type: DIALOG_UI_RESET_DISPLAY,
  };
};

export const setSelectedPricingPlanModal = (selectedPricingPlan) => {
  return {
    type: SELECTED_PLAN_CONFIRMATION_UI_MODAL_DISPLAY,
    selectedPricingPlan,
  };
};

export const setFilterModal = (filterDisplay) => {
  return {
    type: FILTER_DISPLAY_UI_MODAL_DISPLAY,
    filterDisplay,
  };
};
