import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Button } from "@material-ui/core";
import { SectionHeader, SwiperImage } from "components/molecules";
import { HeroShaped } from "components/organisms";
import {
  ACCOUNT_ONBOARD,
  BROWSE_ROUTE,
  REGISTER_ROUTE,
} from "constants/routes";
import {
  BROWSE_DATES,
  DOWNLOAD,
  GET_STARTED,
  HERO_SUBTITLE,
} from "constants/messages";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
  swiperNavButton: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    padding: `${theme.spacing(2)}px !important`,
    background: `${theme.palette.primary.main} !important`,
  },
}));

const Hero = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const authUser = useSelector((state) => state.auth.authUser);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <HeroShaped
        leftSide={
          <SectionHeader
            title={
              <span>
                Voice dating made{" "}
                <span className="text-highlighted__primary">easy</span>
              </span>
            }
            subtitle={HERO_SUBTITLE}
            ctaGroup={[
              <Button
                variant="contained"
                color="primary"
                size={isMd ? "large" : "medium"}
                href={authUser ? ACCOUNT_ONBOARD : REGISTER_ROUTE}
              >
                {GET_STARTED}
              </Button>,
              <Button
                variant="outlined"
                color="primary"
                size={isMd ? "large" : "medium"}
                href={BROWSE_ROUTE}
              >
                {BROWSE_DATES}
              </Button>,
              // <Button
              //   variant="outlined"
              //   color="primary"
              //   size={isMd ? "large" : "medium"}
              //   href="#download"
              // >
              //   {DOWNLOAD}
              // </Button>,
            ]}
            align="left"
            titleVariant="h3"
          />
        }
        rightSide={
          <SwiperImage
            navigationButtonStyle={classes.swiperNavButton}
            autoPlay
            items={[
              {
                src: "/custom_images/hero/audio_2.jpg",
                srcSet: "/custom_images/hero/audio_2@2x.jpg 2x",
                alt: "hero_2",
              },
              {
                src: "/custom_images/hero/audio_3.jpg",
                srcSet: "/custom_images/hero/audio_3@2x.jpg 2x",
                alt: "hero_3",
              },
              {
                src: "/custom_images/hero/audio_1.jpg",
                srcSet: "/custom_images/hero/audio_1@2x.jpg 2x",
                alt: "hero_1",
              },
            ]}
          />
        }
      />
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
