import { INCOMPLETE } from "constants/auth";

export const userSchema = (uid, displayName, socialAvatar) => ({
  userId: uid,
  // email: currentUser.email, // email is private info, and should not be in public user profile
  displayName: displayName,
  photoURL: null,
  photoRef: null,
  audioURL: null,
  audioRef: null,
  audioDuration: 0,
  createdAt: new Date().toISOString(),
  fcmToken: null,
  type: "user",
  hidden: false, // direct query
  demographic: {
    country: "",
    region: "",
    age: "",
    gender: "",
    genderPreference: "",
  },
  blocked: {},
  reported: {},
  revealed: {},
  matched: {}, // matched requests
  inBoundMatchedRequests: {}, // requests that others have sent to me
  outBoundMatchedRequests: {}, // requests that I sent to others
  settings: {
    emailNotifications: true,
    pushNotifications: true,
  },
  premium: false,
  socialAvatar: null,
  status: INCOMPLETE,
  lastNotificationTimestamp: null,
  lastMessageNotificationTimestamp: null,
  popularityScore: 0,
});
