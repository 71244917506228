import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { RouteWithLayout } from "common";
import { Main as MainLayout } from "layouts";
import {
  Home as HomeView,
  Register as RegisterView,
  SignIn as SignInView,
  Reset as ResetView,
  ChangePassword as ChangePasswordView,
  Account as AccountView,
  Onboard as OnboardView,
  Complete as CompleteView,
  Browse as BrowseView,
  Terms as TermsView,
  Privacy as PrivacyView,
  Rules as RulesView,
  Contact as ContactView,
  Pricing as PricingView,
  Faq as FaqView,
} from "views";
import {
  HOME_ROUTE,
  REGISTER_ROUTE,
  LOGIN_ROUTE,
  RESET_ROUTE,
  VERIFY_ROUTE,
  ACCOUNT_ROUTE,
  BROWSE_ROUTE,
  TERMS_ROUTE,
  ACCOUNT_ONBOARD,
  ACCOUNT_ONBOARD_COMPLETE,
  PRIVACY_ROUTE,
  RULES_ROUTE,
  CONTACT_ROUTE,
  PRICING_ROUTE,
  FAQ_ROUTE,
} from "constants/routes";

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path={HOME_ROUTE}
      />
      <RouteWithLayout
        component={RegisterView}
        exact
        layout={MainLayout}
        path={REGISTER_ROUTE}
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MainLayout}
        path={LOGIN_ROUTE}
      />
      <RouteWithLayout
        component={ResetView}
        exact
        layout={MainLayout}
        path={RESET_ROUTE}
      />
      <RouteWithLayout
        component={ChangePasswordView}
        exact
        layout={MainLayout}
        path={VERIFY_ROUTE}
      />
      <RouteWithLayout
        component={BrowseView}
        exact
        layout={MainLayout}
        path={BROWSE_ROUTE}
      />
      <RouteWithLayout
        component={TermsView}
        exact
        layout={MainLayout}
        path={TERMS_ROUTE}
      />
      <RouteWithLayout
        component={PrivacyView}
        exact
        layout={MainLayout}
        path={PRIVACY_ROUTE}
      />
      <RouteWithLayout
        component={RulesView}
        exact
        layout={MainLayout}
        path={RULES_ROUTE}
      />
      <RouteWithLayout
        component={ContactView}
        exact
        layout={MainLayout}
        path={CONTACT_ROUTE}
      />
      <RouteWithLayout
        component={PricingView}
        exact
        layout={MainLayout}
        path={PRICING_ROUTE}
      />
      <RouteWithLayout
        component={FaqView}
        exact
        layout={MainLayout}
        path={FAQ_ROUTE}
      />
      <RouteWithLayout
        component={AccountView}
        exact
        auth
        layout={MainLayout}
        path={ACCOUNT_ROUTE}
      />
      <RouteWithLayout
        component={OnboardView}
        exact
        auth
        layout={MainLayout}
        path={ACCOUNT_ONBOARD}
      />
      <RouteWithLayout
        component={CompleteView}
        exact
        auth
        layout={MainLayout}
        path={ACCOUNT_ONBOARD_COMPLETE}
      />
      <Redirect to={HOME_ROUTE} status="404" />
    </Switch>
  );
};

export default Routes;
