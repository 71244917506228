import React, { forwardRef, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { useDispatch, useSelector } from "react-redux";
import { resetDialog, setDialog } from "store/actions/ui";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPrompt = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.ui.dialog);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (dialog) {
      const { title, message } = dialog;
      setTitle(title);
      setMessage(message);
    } else {
      setTitle("");
      setMessage("");
    }
  }, [dialog]);

  const handleClose = () => {
    dispatch(resetDialog());
  };
  return (
    <Dialog
      open={dialog !== null}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{message}</Typography>
      </DialogContent>
      <DialogActions>
        {dialog?.actions?.map((action) => (
          <Button
            key={action.key}
            autoFocus
            onClick={action.callback}
            color={action.color}
          >
            {action.title}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default DialogPrompt;
