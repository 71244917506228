import { CountryRegionData } from "react-country-region-selector";
import isEmpty from "lodash.isempty";

export const formatCountryOptions = () => {
  const options = {};
  CountryRegionData.forEach((regionData) => {
    const title = regionData[0];
    const code = regionData[1];
    const regions = regionData[2].split("|").map((regionPair) => {
      const [regionName, regionShortCode] = regionPair.split("~");
      return { regionName, regionShortCode };
    });
    options[code] = { title, code, regions };
  });
  return options;
};

export const retrieveCountryOptionWithCode = (countryCode) => {
  if (isEmpty(countryCode)) return {};
  let countryOption = {};
  CountryRegionData.forEach((regionData) => {
    const title = regionData[0];
    const code = regionData[1];
    const regions = regionData[2].split("|").map((regionPair) => {
      const [regionName, regionShortCode] = regionPair.split("~");
      return { regionName, regionShortCode };
    });
    if (code === countryCode) {
      countryOption = { title, code, regions };
    }
  });
  return countryOption;
};
