import validUrl from "valid-url";

// export const avatar = "https://api.adorable.io/avatars";
export const avatar = "https://ui-avatars.com/api";

export const FB_NORMAL = "normal";
export const FB_LARGE = "large";
export const FULL_WIDTH = "1024";

const AVATAR_BACKGROUND = "9c26b0";
const AVATAR_COLOR = "ffffff";

export const profileImg = (
  { photoURL, displayName, socialAvatar },
  size = "160",
  fbSize = FB_NORMAL
) => {
  if (photoURL) return photoURL;
  // const avatarDefault = `${avatar}/${size}/${displayName}.png`;
  const avatarDefault = `${avatar}/?name=${displayName}&size=${size}&background=${AVATAR_BACKGROUND}&color=${AVATAR_COLOR}`;
  try {
    if (!validUrl.isWebUri(socialAvatar)) {
      return avatarDefault;
    }
    const hostName = new URL(socialAvatar).toString();
    if (hostName.includes("graph.facebook.com")) {
      if (FB_LARGE) {
        return `${socialAvatar}?width=${FULL_WIDTH}`;
      }
      return `${socialAvatar}?type=${fbSize}`;
    }
    return socialAvatar;
  } catch (error) {
    return avatarDefault;
  }
};

export const formatSocialAvatar = (socialAvatar, fbSize = FB_NORMAL) => {
  try {
    if (!validUrl.isWebUri(socialAvatar)) {
      return null;
    }
    const socialURL = new URL(socialAvatar).toString();
    if (socialURL.includes("graph.facebook.com")) {
      const tokens = socialURL.split("?");
      const hostName = tokens[0];
      if (fbSize === FB_LARGE) {
        return `${hostName}?width=${FULL_WIDTH}`;
      }
      return `${hostName}?type=${fbSize}`;
    }
    return socialAvatar;
  } catch (error) {
    return null;
  }
};
