import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
// import { Section, SectionAlternate } from "components/organisms";
import { DownloadFooter } from "components/molecules";
import { Contact, Hero } from "./components";

import { mapData } from "./data";

const meta = {
  title: "Holler Date | Contact Us",
  description: "Contact us if you've any questions about Holler Date.",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
}));

const ContactPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Hero />
      <Contact data={mapData} />
      {/*<SectionAlternate>*/}
      {/*  <Form />*/}
      {/*</SectionAlternate>*/}
      <Divider />
      <DownloadFooter />
    </div>
  );
};

export default ContactPage;
