import axios from "axios";
import { adminUIStartLoading, adminUIStopLoading } from "./ui";
import { setMembers } from "./members";

export const approveUser = (userId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(adminUIStartLoading());
      const members = getState().members.members;
      const res = await axios.post("/admin/user/approve", {
        userId,
      });
      const user = res.data;
      const { docId } = user;
      members[docId] = user;
      dispatch(setMembers(members));
      dispatch(adminUIStopLoading());
    } catch (error) {
      dispatch(adminUIStopLoading());
    }
  };
};

export const rejectUser = (userId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(adminUIStartLoading());
      const members = getState().members.members;
      const res = await axios.post("/admin/user/reject", {
        userId,
      });
      const user = res.data;
      const { docId } = user;
      members[docId] = user;
      dispatch(setMembers(members));
      dispatch(adminUIStopLoading());
    } catch (error) {
      dispatch(adminUIStopLoading());
    }
  };
};

export const suspendUser = (userId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(adminUIStartLoading());
      const members = getState().members.members;
      const res = await axios.post("/admin/user/suspend", {
        userId,
      });
      const user = res.data;
      const { docId } = user;
      members[docId] = user;
      dispatch(setMembers(members));
      dispatch(adminUIStopLoading());
    } catch (error) {
      dispatch(adminUIStopLoading());
    }
  };
};
