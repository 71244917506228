import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import {
  Widget,
  toggleWidget,
  isWidgetOpened,
  dropMessages,
  addUserMessage,
  addResponseMessage,
} from "@qinzhengquan/react-chat-widget";
import moment from "moment";
import PropTypes from "prop-types";
import clsx from "clsx";
import AudioPlayer from "react-h5-audio-player";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import useSound from "use-sound";
import {
  Button,
  Chip,
  colors,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Icon } from "components/atoms";
import isEmpty from "lodash.isempty";
import { signOut } from "store/actions/auth";
import { HOME_ROUTE } from "constants/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  APPROVAL_STATUS,
  GENDER_PREFERENCE,
  UNMATCHED_TOOLTIP,
  MATCHED_TOOLTIP,
  CHAT_TITLE,
  CHAT_SUBTITLE,
  MATCHES_NONE,
  AUDIO_HELPER_TEXT,
  AUDIO_UNAVAILABLE_TEXT,
  LOAD_MORE,
  LOADING,
  REVEAL_PHOTO_TOOLTIP,
  UNMATCHED_TITLE,
  UNMATCHED_MESSAGE,
  MATCH_POINTS,
  REVEAL_POINTS,
  CHAT_PAGE_TITLE,
  // GENDER,
} from "constants/messages";
import Tooltip from "@material-ui/core/Tooltip";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ClearIcon from "@material-ui/icons/Clear";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { CardProduct } from "components/organisms";
import { Dialog, SwiperImage, DownloadFooter } from "components/molecules";
import { retrieveCountryOptionWithCode } from "shared/config/ui";
import {
  ERROR,
  LABEL,
  PRIMARY,
  SUCCESS,
  WARNING,
  WHITE,
} from "constants/colors";
import { MALE } from "constants/members";
import { getPaginatedMembersMatches, unMatch } from "store/actions/members";
import { marshallReceiver, marshallSender } from "store/utils/chat";
import { getRoom, queryChatLogs, sendMessage } from "store/actions/chat"; // overriding styles

import "@qinzhengquan/react-chat-widget/lib/styles.css";
import "assets/scss/chat.scss";
import notification from "assets/audio/notification.mp3";
import { resetDialog, setDialog } from "store/actions/ui";
import { unMatchDialogActions } from "store/utils/ui";
import isNumber from "is-number";

const meta = {
  title: "Holler Date | Voice Dating Made Easy",
  description:
    "Meet new dates using the power of your voice. Holler Date - Your trusted voice dating site.",
};

const useStyles = makeStyles((theme) => ({
  root: {},
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  titleCta: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  previewRoot: {
    marginBottom: 16,
  },
  swiperNavButton: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    padding: `${theme.spacing(2)}px !important`,
    background: `${PRIMARY} !important`,
  },
  locationCardPrice: {
    padding: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    background: WHITE,
    borderRadius: theme.spacing(1),
    zIndex: 3,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  fontPending: {
    color: WARNING,
  },
  fontLabel: {
    color: LABEL,
  },
  locationCardReviewAvatar: {
    marginLeft: theme.spacing(-2),
    border: "3px solid white",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  locationCardReviewStar: {
    color: colors.yellow[800],
    marginRight: theme.spacing(1 / 2),
  },
  reviewCount: {
    marginLeft: theme.spacing(1),
  },
  audioRoot: {
    // width: 429,
    height: 300,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: "40%",
  },
  audioRootFemale: {
    backgroundImage: `url("/custom_images/hero/woman_shadow.jpg")`,
  },
  audioRootMale: {
    backgroundImage: `url("/custom_images/hero/man_shadow.jpg")`,
  },
  audioContainer: {
    marginBottom: 16,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  audioHelperText: {
    color: WHITE,
    textAlign: "center",
  },
  swiperRoot: {
    // width: 429,
  },
  image: {
    borderBottomLeftRadius: "40%",
  },
  iconButton: {
    width: 50,
    height: 50,
    boxShadow: "0 2px 10px 0 rgba(23,70,161,.11)",
  },
  iconReveal: {
    background: PRIMARY,
    "&:hover": {
      background: PRIMARY,
    },
  },
  iconInvite: {
    background: SUCCESS,
    "&:hover": {
      background: SUCCESS,
    },
  },
  iconChat: {
    background: LABEL,
    "&:hover": {
      background: LABEL,
    },
    "&:disabled": {
      opacity: 0.4,
      background: PRIMARY,
    },
  },
  iconReject: {
    background: ERROR,
    "&:hover": {
      background: ERROR,
    },
    "&:disabled": {
      opacity: 0.4,
      background: ERROR,
    },
    marginRight: 8,
  },
  icon: {
    color: WHITE,
    width: 25,
    height: 25,
  },
  genderContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  gender: {
    color: LABEL,
    borderColor: LABEL,
  },
  genderPreference: {
    color: PRIMARY,
    borderColor: PRIMARY,
    marginLeft: 8,
  },
  loadMoreContainer: {
    display: "flex",
    justifyContent: "center",
  },
  noMatchesContainer: {
    display: "flex",
    justifyContent: "center",
  },
  statusContainer: {
    paddingTop: "0px !important",
  },
  matchPoints: {
    backgroundColor: WARNING,
    color: WHITE,
    margin: "8px 0",
  },
  revealPoints: {
    backgroundColor: ERROR,
    color: WHITE,
    margin: "8px 8px",
  },
}));

const Matches = (props) => {
  const { className, history, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [reveal, setReveal] = useState({});
  const [chatMatchID, setChatMatchID] = useState(null);
  const [chatRecipient, setChatRecipient] = useState(null);
  const [chatSessionTimeStamp, setChatSessionTimeStamp] = useState(new Date());
  const [responseNotification, setResponseNotification] = useState(false);
  const [pageTitle, setPageTitle] = useState(meta.title);
  const membersIsLoading = useSelector((state) => state.ui.membersIsLoading);
  const plan = useSelector((state) => state.plan.plan);
  const authUser = useSelector((state) => state.auth.authUser);
  const membersMatches = useSelector((state) => state.members.membersMatches);
  const room = useSelector((state) => state.chat.room);

  // view more use cases here: https://www.joshwcomeau.com/react/announcing-use-sound-react-hook/
  const [play] = useSound(notification, { volume: 0.5 });

  const handleSignOut = () => {
    dispatch(signOut());
    history.replace(HOME_ROUTE);
  };

  useEffect(() => {
    if (authUser) {
      dispatch(getPaginatedMembersMatches(12));
    }
  }, [dispatch, authUser]);

  useEffect(() => {
    if (authUser && membersMatches[chatMatchID]) {
      dropMessages();
      const match = membersMatches[chatMatchID];
      setChatRecipient(match);
      dispatch(getRoom(authUser.profile.userId, match.userId));
      setChatSessionTimeStamp(new Date());
    }
  }, [chatMatchID, membersMatches, authUser, dispatch]);

  useEffect(() => {
    let unsubChats;
    if (room && authUser) {
      const query = queryChatLogs(room.docId, null);
      unsubChats = query?.onSnapshot((snapshot) => {
        snapshot?.docChanges()?.forEach((change) => {
          if (change.type === "added") {
            const chat = change.doc.data();
            const { createdAt } = chat;
            if (chat.authorId !== authUser.profile.userId) {
              addResponseMessage(
                chat.message,
                change.doc.id,
                moment(createdAt).toDate()
              );
              setResponseNotification(true);
            } else {
              addUserMessage(
                chat.message,
                change.doc.id,
                moment(createdAt).toDate()
              );
            }
          }
        });
      });
    }
    return () => {
      if (unsubChats) {
        unsubChats();
      }
    };
  }, [authUser, room, setResponseNotification]);

  useEffect(() => {
    if (responseNotification && play && chatSessionTimeStamp) {
      const duration = moment.duration(
        moment(new Date()).diff(moment(chatSessionTimeStamp))
      );
      const seconds = duration.seconds();
      if (seconds > 20) {
        setPageTitle(CHAT_PAGE_TITLE(chatRecipient?.displayName));
        play();
      }
      setResponseNotification(false);
    }
  }, [responseNotification, play, chatSessionTimeStamp, chatRecipient]);

  const renderAudio = (gender, audioURL) => {
    return (
      <div
        className={clsx(
          classes.audioRoot,
          gender === MALE ? classes.audioRootMale : classes.audioRootFemale
        )}
      >
        <div className={classes.audioContainer}>
          <Typography variant="body1" className={classes.audioHelperText}>
            {audioURL ? AUDIO_HELPER_TEXT : AUDIO_UNAVAILABLE_TEXT}
          </Typography>
        </div>
        {audioURL && <AudioPlayer src={audioURL} volume={0.5} />}
      </div>
    );
  };

  const renderReveal = (images) => {
    return (
      <SwiperImage
        className={classes.swiperRoot}
        navigationButtonStyle={classes.swiperNavButton}
        items={images}
        imageClassName={classes.image}
      />
    );
  };

  const loadMore = () => {
    if (authUser) {
      dispatch(getPaginatedMembersMatches(12));
    }
  };

  const handleNewUserMessage = (newMessage) => {
    if (authUser) {
      const sender = marshallSender(authUser?.profile);
      const receiver = marshallReceiver(chatRecipient);
      dispatch(
        sendMessage(newMessage, room?.docId, sender, receiver, null, null)
      );
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <div className={classes.titleCta}>
            <Typography variant="h6" color="textPrimary">
              My Matches
            </Typography>
            <Button variant="outlined" color="primary" onClick={handleSignOut}>
              Log out
            </Button>
          </div>
        </Grid>
        <Grid item md={8} xs={12} className={classes.statusContainer}>
          <div>
            <Chip
              className={classes.matchPoints}
              label={MATCH_POINTS(
                isNumber(plan?.pointsLimit) ? plan?.pointsLimit : 0
              )}
              color="secondary"
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} className={classes.container}>
            {isEmpty(membersMatches) && !membersIsLoading && (
              <Grid item xs={12} data-aos="fade-up">
                <div className={classes.noMatchesContainer}>
                  <Typography
                    variant="body1"
                    color="primary"
                    className={clsx(classes.fontWeight700, classes.fontLabel)}
                  >
                    {MATCHES_NONE}
                  </Typography>
                </div>
              </Grid>
            )}
            {Object.keys(membersMatches).map((docId) => {
              const member = membersMatches[docId];
              const {
                userId,
                audioURL,
                status,
                displayName,
                demographic,
              } = member;
              const { age, country, gender, genderPreference } =
                demographic || {};
              const countryOptions = retrieveCountryOptionWithCode(country);
              const { title } = countryOptions;
              const images = [
                { src: member.photoURL, srcSet: member.photoURL },
              ];
              return (
                <Grid key={userId} item xs={12} md={6} data-aos="fade-up">
                  <CardProduct
                    className={classes.previewRoot}
                    withShadow
                    liftUp
                    mediaContent={
                      <>
                        {!reveal[userId]
                          ? renderAudio(gender, audioURL)
                          : renderReveal(images)}

                        <div className={classes.locationCardPrice}>
                          <Typography
                            variant="body1"
                            color="primary"
                            className={clsx(
                              classes.fontWeight700,
                              classes.fontPending
                            )}
                          >
                            {APPROVAL_STATUS(status)}
                          </Typography>
                        </div>
                      </>
                    }
                    cardContent={
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            color="textPrimary"
                            align="left"
                            className={classes.fontWeight700}
                          >
                            {displayName}, {age}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            align="left"
                          >
                            {title}
                          </Typography>
                        </Grid>
                        <Grid item container justify="space-between" xs={12}>
                          <Grid item container xs={6} wrap="nowrap">
                            {/*<Chip*/}
                            {/*  className={classes.gender}*/}
                            {/*  label={GENDER(gender)}*/}
                            {/*  color="secondary"*/}
                            {/*  variant="outlined"*/}
                            {/*/>*/}
                            {gender === "female" ? (
                              <Icon
                                fontIconClass="fas fa-female"
                                size="medium"
                                fontIconColor={colors.pink[500]}
                              />
                            ) : (
                              <Icon
                                fontIconClass="fas fa-male"
                                size="medium"
                                fontIconColor={colors.blue[500]}
                              />
                            )}
                            <Chip
                              className={classes.genderPreference}
                              label={GENDER_PREFERENCE(genderPreference)}
                              color="secondary"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            alignItems="center"
                            justify="flex-end"
                            xs={6}
                          >
                            <Tooltip
                              title={REVEAL_PHOTO_TOOLTIP}
                              placement="bottom-start"
                              arrow
                            >
                              <div>
                                <IconButton
                                  className={clsx(
                                    classes.iconButton,
                                    !reveal[userId]
                                      ? classes.iconReveal
                                      : classes.iconInvite
                                  )}
                                  onClick={() => {
                                    reveal[userId] = !reveal[userId];
                                    setReveal({ ...reveal });
                                  }}
                                >
                                  {!reveal[userId] ? (
                                    <FavoriteIcon className={classes.icon} />
                                  ) : (
                                    <PersonAddIcon className={classes.icon} />
                                  )}
                                </IconButton>
                              </div>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid item container justify="flex-end" xs={12}>
                          <Tooltip
                            title={UNMATCHED_TOOLTIP(displayName)}
                            placement="bottom-start"
                            arrow
                          >
                            <div>
                              <IconButton
                                className={clsx(
                                  classes.iconButton,
                                  classes.iconReject
                                )}
                                onClick={() => {
                                  dispatch(
                                    setDialog(
                                      UNMATCHED_TITLE,
                                      UNMATCHED_MESSAGE(displayName),
                                      unMatchDialogActions(dispatch, () => {
                                        dispatch(resetDialog());
                                        if (chatMatchID === docId) {
                                          setChatMatchID(null);
                                          setChatRecipient(null);
                                        }
                                        dispatch(
                                          unMatch(userId, docId, displayName)
                                        );
                                      })
                                    )
                                  );
                                }}
                              >
                                <ClearIcon className={classes.icon} />
                              </IconButton>
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={MATCHED_TOOLTIP(displayName)}
                            placement="bottom-start"
                            arrow
                          >
                            <div>
                              <IconButton
                                className={clsx(
                                  classes.iconButton,
                                  classes.iconChat
                                )}
                                onClick={() => {
                                  setChatMatchID(docId);
                                  if (!isWidgetOpened()) {
                                    toggleWidget();
                                  }
                                }}
                              >
                                <ChatBubbleIcon className={classes.icon} />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {!isEmpty(membersMatches) && (
          <Grid item xs={12}>
            <div className={classes.loadMoreContainer}>
              <Button
                disabled={membersIsLoading}
                variant="outlined"
                color="primary"
                onClick={loadMore}
              >
                {membersIsLoading ? LOADING : LOAD_MORE}
              </Button>
            </div>
          </Grid>
        )}

        {chatRecipient && authUser && (
          <Widget
            chatId={chatMatchID || "rcw-chat-container"}
            handleSubmit={handleNewUserMessage}
            titleAvatar={chatRecipient.photoURL}
            profileAvatar={chatRecipient.photoURL}
            title={CHAT_TITLE}
            subtitle={CHAT_SUBTITLE(chatRecipient.displayName)}
            showTimeStamp
          />
        )}
      </Grid>
      <Dialog />
      <DownloadFooter />
    </div>
  );
};

Matches.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default withRouter(Matches);
