import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "components/organisms";
import { DownloadFooter } from "components/molecules";
import { Questions } from "./components";

import { questions } from "./data";

const meta = {
  title: "Holler Date | FAQs",
  description: "Our frequently asked questions.",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  sectionBreadcrumb: {
    "& .section-alternate__content": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
}));

const Faq = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      {/*<SectionAlternate className={classes.sectionBreadcrumb}>*/}
      {/*  <Breadcrumb data={breadcrumb} />*/}
      {/*</SectionAlternate>*/}
      <Section className={classes.pagePaddingTop}>
        <Questions data={questions} />
      </Section>
      {/*<SectionAlternate>*/}
      {/*  <Categories data={categories} />*/}
      {/*</SectionAlternate>*/}
      <DownloadFooter />
    </div>
  );
};

export default Faq;
