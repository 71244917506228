import React, { Component } from "react";
import { Router } from "react-router-dom";
import AOS from "aos";
// note: using v4 of history due to rendering issues with v5. see: https://github.com/ReactTraining/history/issues/822
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Routes from "./Routes";

import "aos/dist/aos.css";
import "react-image-crop/lib/ReactCrop.scss";
import "./assets/scss/index.scss";

const browserHistory = createBrowserHistory();

browserHistory.listen((location) => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (location.action === "POP") {
      return;
    }
    // In all other cases, scroll to top
    // Note: comment out the below code if we are using anchor tag
    window.scrollTo(0, 0);
  });
});

class App extends Component {
  render() {
    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: "ease-in-out",
    });
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
