import {
  SET_ROOM,
  RESET_CHATS,
  GET_CHATS,
  CHAT_SNAPSHOT,
} from "../types/chatTypes";

const initialState = {
  room: null,
  rooms: {},
  chats: {},
  chatSnapshot: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROOM:
      return {
        ...state,
        room: action.room,
      };
    case GET_CHATS:
      return {
        ...state,
        chats: {
          ...state.chats,
          ...action.chats,
        },
      };
    case RESET_CHATS:
      return {
        ...state,
        chats: action.chats,
      };
    case CHAT_SNAPSHOT:
      return {
        ...state,
        chatSnapshot: action.chatSnapshot,
      };
    default:
      return state;
  }
};

export default reducer;
