import { PENDING, APPROVED, SUSPENDED, INCOMPLETE } from "constants/auth";

export const SIGN_IN_TITLE = "Sign in";

export const SIGN_UP_TITLE = "Sign up";
export const SIGN_UP_SUBTITLE =
  "Begin your journey with us to find your soulmate.";

export const RESET_TITLE = "Reset password";
export const RESET_SUBTITLE =
  "Enter your email address and we'll send you the reset password instructions.";

export const CHANGE_PASSWORD_TITLE = "New password";
export const CHANGE_PASSWORD_SUBTITLE = "Please enter your new password below.";

export const PASSWORD_RESET_PENDING = "Resetting password... Please Wait.";
export const PASSWORD_RESET_SUCCESS =
  "Password reset successfully. Please login with your new password.";
export const PASSWORD_RESET_ERROR = "Password reset failed. Please try again.";

export const EMAIL_VERIFIED_SUCCESS =
  "Your email has been verified successfully.";
export const EMAIL_VERIFIED_ERROR = "Failed to verify email. Please try again.";

export const EMAIL_RESET_PASSWORD_PENDING =
  "Sending reset password instructions... Please Wait.";
export const EMAIL_RESET_PASSWORD_SUCCESS = (email) =>
  `Reset password instructions sent to ${email}. Please check your email for details.`;
export const EMAIL_RESET_PASSWORD_ERROR = (email) =>
  `There was a problem sending the reset password instructions to ${email}. Please try again.`;

export const HERO_SUBTITLE =
  "Let your personality shine through your voice. With voice dating, your personality comes first, not looks.";

export const GET_STARTED = "Get Started";
export const BROWSE_DATES = "Find Dates";
export const DOWNLOAD = "Download";
export const REGISTER = "Register";
export const RESET_PASSWORD = "Reset password";
export const LOGIN = "Login";
export const LOGOUT = "Logout";
export const ACCOUNT = "Account";

export const HERO_WELCOME_TITLE =
  "Hurray! 🎊 🎉 Welcome to Holler Date and congratulations for creating your account with us. Let's find out more about you.";

export const AUTH_SUCCESS = (displayName) => `Welcome back, ${displayName}! 🤗`;
export const AUTH_ERROR = "Failed to login. Please try again.";

export const AUTH_LOGOUT_PENDING = "Signing out... Please Wait.";
export const AUTH_LOGOUT_SUCCESS = "Signed out successfully!";
export const AUTH_LOGOUT_ERROR = "Failed to sign out. Please try again.";

export const PROFILE_UPDATE_PENDING = "Updating Profile... Please Wait.";
export const PROFILE_UPDATE_SUCCESS = "Profile Updated Successfully!";
export const PROFILE_UPDATE_ERROR =
  "Failed to update profile. Please try again.";

export const PROFILE_PHOTO_UPDATE_PENDING =
  "Updating Profile Picture... Please Wait.";
export const PROFILE_PHOTO_UPDATE_SUCCESS =
  "Profile Picture Updated Successfully!";
export const PROFILE_PHOTO_UPDATE_ERROR =
  "Failed to delete update picture. Please try again.";

export const PROFILE_PHOTO_DELETE_PENDING =
  "Deleting Profile Picture... Please Wait.";
export const PROFILE_PHOTO_DELETE_SUCCESS =
  "Profile Picture Deleted Successfully!";
export const PROFILE_PHOTO_DELETE_ERROR =
  "Failed to delete profile picture. Please try again.";

export const PROFILE_AUDIO_UPDATE_PENDING =
  "Updating Audio Recording... Please Wait.";
export const PROFILE_AUDIO_UPDATE_SUCCESS =
  "Audio Recording Updated Successfully!";
export const PROFILE_AUDIO_UPDATE_ERROR =
  "Failed to delete audio recording. Please try again.";

export const PROFILE_AUDIO_DELETE_PENDING =
  "Deleting Audio Recording... Please Wait.";
export const PROFILE_AUDIO_DELETE_SUCCESS =
  "Audio Recording Deleted Successfully!";
export const PROFILE_AUDIO_DELETE_ERROR =
  "Failed to delete audio recording. Please try again.";

export const ACCOUNT_DELETE_PENDING = "Deleting Account... Please Wait.";
export const ACCOUNT_DELETE_SUCCESS = "Account Deleted Successfully!";
export const ACCOUNT_DELETE_ERROR =
  "Failed to delete account. Please try again.";

export const AUDIO_UNSUPPORTED =
  "Sorry, audio recording is currently not supported on Safari. Please try other browsers.";
export const AUDIO_PERMISSION_REQUEST =
  "Please grant us microphone permissions in your browser settings to record audio.";

export const ONBOARD_TITLE = "Welcome!";
export const ONBOARD_SUBTITLE = "Let's find out more about you.";

export const ONBOARD_COMPLETE_TITLE = "Setup Complete.";
export const ONBOARD_COMPLETE_SUBTITLE =
  "Preview your profile below. Start browsing for dates!";

export const AUDIO_HELPER_TEXT =
  "If you like this audio clip, click the heart icon to reveal the person's photo!";

export const AUDIO_UNAVAILABLE_TEXT = "Audio not available";

export const APPROVAL_STATUS = (status) => {
  if (status === PENDING) {
    return "pending approval";
  } else if (status === APPROVED) {
    return "live";
  } else if (status === SUSPENDED) {
    return "suspended";
  } else if (status === INCOMPLETE) {
    return "incomplete";
  } else {
    return "processing";
  }
};

export const GENDER_PREFERENCE = (genderPreference) =>
  genderPreference === "male" ? `Interested in men` : `Interested in women`;

export const GENDER = (gender) => (gender === "male" ? "Male" : "Female");

export const REVEAL_PROMPT = (displayName) =>
  `This will reveal the ${displayName}'s photo and cost you 1 daily heart point.`;
export const INVITE_PROMPT = (displayName) =>
  `This will send ${displayName} a match request. You can start chatting with each other if ${displayName} accepts your match request.`;

export const NEWEST_MEMBERS_SUBTITLE =
  "Preview voice clips from prospective dates";

export const BROWSE_MORE_MEMBERS = "Browse more members";

export const LOAD_MORE = "Load more";
export const LOADING = "Loading";

export const ACCOUNT_CREATION_INVITATION_TITLE = "Create Account 🤗";
export const ACCOUNT_CREATION_INVITATION_MESSAGE = (displayName) =>
  `Create an account with us to reveal ${displayName}'s photo.`;
export const ACCOUNT_CREATION_FILTER_MESSAGE = `Create an account with us to filter profiles`;

export const ACCOUNT_INCOMPLETE = "Incomplete Profile 😬";
export const ACCOUNT_INCOMPLETE_MESSAGE = (incompleteFields) =>
  `Your profile is missing the following fields: ${incompleteFields}. Please click the link below to complete your profile before sending a reveal/match request!`;

export const REVEAL_PHOTO_TITLE = "Reveal photo?";
export const REVEAL_PHOTO_MESSAGE = (displayName, pointsLimit, configs) =>
  `Revealing ${displayName} photo will cost ${configs.revealCost} point. You have ${pointsLimit} points left.`;
export const REVEAL_PHOTO_MESSAGE_EXCEEDED = (
  displayName,
  pointsLimit,
  configs
) =>
  `Revealing ${displayName} photo will cost ${configs.revealCost} point. You have ${pointsLimit} points left. Come back tomorrow or purchase points!`;
export const REVEAL_PHOTO_TOOLTIP = "Click to reveal photo!";

export const MATCH_REQUEST_TITLE = "Send Match Request? 😻";

export const MATCH_REQUEST_MESSAGE = (displayName, pointsLimit, configs) =>
  `Sending ${displayName} a match request will cost ${configs.matchCost} points. You have ${pointsLimit} points left.`;
export const MATCH_REQUEST_MESSAGE_EXCEEDED = (
  displayName,
  pointsLimit,
  configs
) =>
  `Sending ${displayName} a match request will cost ${configs.matchCost} points. You have ${pointsLimit} points left. Come back tomorrow or purchase points!`;

export const CANCEL = "Cancel";
export const CREATE_ACCOUNT = "Create Account";
export const REVEAL_PHOTO = "Reveal Photo";
export const PURCHASE_POINTS = "Purchase Points";
export const SEND_MATCH_REQUEST = "Send Match Request";

export const UNMATCHED_TITLE = "Un-match with this person? 💔";
export const UNMATCHED_MESSAGE = (displayName) =>
  `Please confirm if you wish to un-match with ${displayName}. Both of you will not longer be able to chat with each other. 😢`;
export const UNMATCHED_REQUEST = "Confirm un-match";

export const UNMATCHED_REQUEST_SUCCESSFUL = (displayName) =>
  `You have unmatched with ${displayName} unsuccessfully.`;
export const UNMATCHED_REQUEST_ERROR = (displayName) =>
  `You have unmatched with ${displayName} unsuccessfully.`;

export const MATCH_REQUEST_SUCCESSFUL = (displayName) =>
  `Your match request to ${displayName} has been sent successfully. 🤗`;
export const MATCH_REQUEST_FAILED = (displayName) =>
  `Your match request to ${displayName} was not successful. Please try again.`;
export const MATCH_REQUEST_TOOLTIP = (displayName) =>
  `Click to send a match request to ${displayName}.`;
export const MATCH_REQUEST_PENDING_TOOLTIP =
  "Your match request is pending acceptance";
export const UNMATCHED_TOOLTIP = (displayName) =>
  `Click to un-match with ${displayName}`;
export const MATCHED_TOOLTIP = (displayName) =>
  `You're already matched with ${displayName}`;
export const MATCH_REQUEST_ACCEPT_TOOLTIP = (displayName) =>
  `Click to accept match request from ${displayName}`;
export const MATCH_REQUEST_ACCEPTED_TOOLTIP = (displayName) =>
  `You have already accepted the match request from ${displayName}. Go to matches to chat with your new found friend!.`;
export const MATCH_REQUEST_REJECT_TOOLTIP = (displayName) =>
  `Click to reject match request from ${displayName}`;

export const MATCH_REQUEST_NONE = "You have no match requests";
export const MATCH_REQUEST_REJECTED = "Rejected";

export const MATCHES_NONE = "You have no matches currently";

export const DATES_NONE =
  "Oops you're early... Holler Date just launched so we weren't able to find matches for you at this time. 🥲";

export const DATES_NONE_ADDITIONAL =
  "But fret not! We're going on a full scale marketing bliz to get like minded folks like you on this platform. Do check back regularly for updates! 🤗";

export const OWNER_FORBIDDEN_TOOLTIP = "You cannot reveal yourself.";
export const COMPLETE_PROFILE = "Complete my profile";

export const MISSING_PHOTO_TAG_LABEL = "Missing photo";
export const MISSING_AUDIO_TAG_LABEL = "Missing audio";
export const MISSING_GENDER_TAG_LABEL = "Please specify your gender";

export const RECORD_AUDIO = "Record Audio";
export const RECORD_STOP_AUDIO = "Stop Record";

export const DELETE_CONFIRMATION_TITLE = "Delete account?";
export const DELETE_CONFIRMATION_MESSAGE =
  "This will delete your account and all relevant data on our system and is not reversible.";
export const DELETE_ACCOUNT = "Delete Account";

export const CHAT_TITLE = "Live Chat";

export const CHAT_SUBTITLE = (displayName) =>
  `You're chatting with ${displayName}`;
export const CHAT_PAGE_TITLE = (displayName) =>
  `${displayName} has sent you a new message.`;

export const PURCHASE_CONFIRMATION = (planName, price) =>
  `Purchase ${planName} for $${price}?`;

export const BENEFITS_TITLE = `You will gain access to the following benefits: `;
export const PAYMENT_TITLE = `Please enter your payment details.`;
export const NAME_BILLING_ERROR = "Please enter your name";
export const CHARACTER_LIMIT = (limit) =>
  `Please keep to ${limit} characters limit.`;

export const MAKE_PAYMENT = (price) => `Pay $${price} now`;
export const PAYMENT_SUCCESS = (amount, planTitle) =>
  `Thank you for your purchase! You have paid $${amount} for the ${planTitle} and will receive a confirmation email shortly. Please reach out to support@holleraway.com or message us on Facebook if there is a problem with your purchase.`;
export const PAYMENT_ERROR = (planTitle) =>
  `Failed to process payment for ${planTitle}. Please try again or contact support@hollerdate.com.`;

export const PLAN_UPDATED = (points) =>
  `Your plan has been updated! You now have a total of ${points} match points.`;

export const MATCH_POINTS = (matchPoints) => `${matchPoints} match points`;
export const REVEAL_POINTS = (revealPoints) => `${revealPoints} reveal points`;

export const FILTER_TITLE = "Select the following match filters";
export const FILTER_GENDER_PLACEHOLDER = "Select your gender preference";
export const FILTER_GENDER_LABEL = "Gender Preference";

export const FILTER_COUNTRY_PLACEHOLDER = "Select your location preference";
export const FILTER_COUNTRY_LABEL = "Location Preference";
export const FILTER_APPLY = "Apply Filter";

export const DOWNLOAD_TITLE = "on Google Play and Apple App Store";
export const DOWNLOAD_SUBTITLE =
  "Download our app for the full experience. Enjoy new features and more!";

export const DOWNLOAD_INVITATION = "Download for more features!";
