import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import { useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ImageCrop({
  image,
  closeCrop,
  saveCrop,
  aspect,
  keepSelection,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [crop, setCrop] = useState({
    unit: "px",
    aspect,
    width: maxWidth,
    height: maxHeight,
  });

  const [imageElement, setImageElement] = useState(null);

  const handleClose = () => {
    closeCrop();
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onSaveSelection = () => {
    saveCrop(imageElement, crop);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="image-crop-dialog-title"
      open={!!image}
      PaperProps={{
        style: {
          width: "100%",
        },
      }}
    >
      <DialogTitle id="image-crop-dialog-title" onClose={handleClose}>
        Image Crop
      </DialogTitle>
      <DialogContent dividers>
        <ReactCrop
          src={image}
          crop={crop}
          onChange={onCropChange}
          circularCrop={true}
          onImageLoaded={(image) => {
            setImageElement(image);
            return true;
          }}
          keepSelection={keepSelection}
          minWidth={minWidth}
          minHeight={minHeight}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onSaveSelection} color="secondary">
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
