import React, { useEffect } from "react";
import queryString from "query-string";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "./components";
import { SectionHeader } from "components/molecules";
import { Section } from "components/organisms";
import {
  CHANGE_PASSWORD_TITLE,
  CHANGE_PASSWORD_SUBTITLE,
} from "constants/messages";
import { useDispatch } from "react-redux";
import { verifyEmail } from "store/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const ChangePassword = ({ location, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.search) {
      const query = queryString.parse(location.search) || {};
      const { oobCode, mode } = query;
      if (mode === "verifyEmail" && oobCode) {
        dispatch(verifyEmail(oobCode, history));
      }
    }
  }, [dispatch, history, location.search]);

  return (
    <div className={classes.root}>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            title={CHANGE_PASSWORD_TITLE}
            subtitle={CHANGE_PASSWORD_SUBTITLE}
            titleProps={{
              variant: "h3",
            }}
          />
          <Form />
        </div>
      </Section>
    </div>
  );
};

export default ChangePassword;
