import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Section, SectionAlternate } from "components/organisms";
import { DownloadFooter } from "components/molecules";
// import { Faq, Main, Plans, Support } from "./components";
import { Main, Support, Faq } from "./components";

// import { pricing, faq, plans } from "./data";
import { pricing, faq } from "./data";
import { withRouter } from "react-router-dom";

const meta = {
  title: "Holler Date | Pricing",
  description:
    "Purchase match points to turbo charge your prospects of finding the one.",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
}));

const Pricing = (props) => {
  const classes = useStyles();
  const { history } = props;

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Main data={pricing} history={history} />
      <SectionAlternate>
        <Support />
      </SectionAlternate>
      <Section>
        <Faq data={faq} />
      </Section>
      {/*<SectionAlternate>*/}
      {/*  <Plans data={plans} />*/}
      {/*</SectionAlternate>*/}
      <DownloadFooter />
    </div>
  );
};

export default withRouter(Pricing);
