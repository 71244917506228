import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MUTED, CAPTION, WHITE } from "constants/colors";
import logo from "assets/images/logo/logo192.png";
import StoreOptions from "./StoreOptions";
import { DOWNLOAD_INVITATION, DOWNLOAD_SUBTITLE } from "constants/messages";
import { isMobileDevice } from "../../../store/utils/ui";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    minWidth: 32,
    color: CAPTION,
    "&:hover": {
      color: MUTED,
    },
  },
  downloadBar: {
    position: "fixed", // can experiment with "absolute, fixed"
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1001,
    background: WHITE,
    boxShadow: "0 0 18px 0 rgba(0, 0, 0, 0.3)",
    padding: 10,
    display: "flex",
    alignItems: "center",
    "@media (min-width: 768px)": {
      order: 1,
    },
  },
  downloadContent: {
    flex: 0.75,
    minWidth: 0,
    height: 44,
    overflow: "hidden",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  downloadBarCenterColumn: {
    marginLeft: 8,
    flex: "auto",
    float: "left",
  },
  downloadBarTitle: {
    fontSize: 14,
    fontWeight: 600,
    "@media (max-width: 320px)": {
      display: "none",
    },
  },
  downloadBarLogoContainer: {
    float: "left",
  },
  downloadBarLogo: {
    borderRadius: 10,
  },
  downloadBarTagLine: {
    color: CAPTION,
    fontSize: 12,
    whiteSpace: "nowrap",
    textTransform: "none",
    // "@media (max-width: 640px)": {
    //   display: "none",
    // },
  },
  storeContainer: {
    flex: 0.25,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const DownloadFooter = (props) => {
  const classes = useStyles();
  const [showBar, setShowBar] = useState(true);
  return (
    showBar && (
      <div className={classes.downloadBar}>
        <div>
          <Button
            onClick={() => setShowBar(false)}
            className={classes.closeButton}
          >
            <i className="fas fa-times" />
          </Button>
        </div>
        <div className={classes.downloadContent}>
          <div className={classes.downloadBarCenterColumn}>
            <Typography
              align="left"
              variant="h6"
              className={classes.downloadBarTitle}
            >
              Holler Date
            </Typography>
            <Typography
              align="left"
              variant="subtitle1"
              className={classes.downloadBarTagLine}
            >
              {isMobileDevice(navigator)
                ? DOWNLOAD_INVITATION
                : DOWNLOAD_SUBTITLE}
            </Typography>
          </div>
          <div className={classes.downloadBarLogoContainer}>
            <img
              src={logo}
              alt="holler_date_logo"
              width="36"
              height="36"
              className={classes.downloadBarLogo}
            />
          </div>
        </div>
        <div className={classes.storeContainer}>
          <StoreOptions />
        </div>
      </div>
    )
  );
};

DownloadFooter.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default DownloadFooter;
