import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import stripeBadge from "assets/images/stripe/powered_by_stripe.png";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { setSelectedPricingPlanModal } from "store/actions/ui";
import {
  BENEFITS_TITLE,
  PAYMENT_TITLE,
  NAME_BILLING_ERROR,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  PURCHASE_CONFIRMATION,
  MAKE_PAYMENT,
} from "constants/messages";
import { colors, TextField } from "@material-ui/core";
import { Icon } from "components/atoms";
import validate from "validate.js";
import { ERROR, MUTED, SUCCESS } from "constants/colors";
import { setSnackBarMessage } from "store/actions/ui";

const schema = {
  name: {
    presence: { allowEmpty: false, message: NAME_BILLING_ERROR },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {},
  featureHeader: {
    marginBottom: 24,
  },
  feature: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  featureDescription: {
    marginBottom: 0,
    marginLeft: 6,
    color: theme.palette.text.primary,
  },
  cardErrorContainer: {
    marginBottom: 8,
  },
  cardError: {
    fontSize: 12,
    color: ERROR,
    fontWeight: 500,
  },
  powerByStripeContainer: {
    marginTop: 16,
  },
  nextInputContainer: {
    marginTop: 16,
  },
  cardInputContainer: {
    marginTop: 24,
  },
  loading: {
    marginRight: 16,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Checkout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const authUser = useSelector((state) => state.auth.authUser);
  const selectedPricingPlan = useSelector(
    (state) => state.ui.selectedPricingPlan
  );
  const [processingPayment, setProcessingPayment] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [open, setOpen] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [displayPayment, setDisplayPayment] = useState(false);
  const [cardElement, setCardElement] = useState(null);
  const [complete, setComplete] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleClose = () => {
    dispatch(setSelectedPricingPlanModal(null));
    setDisplayPayment(false);
  };

  const handleProceed = () => {
    setDisplayPayment(true);
  };

  useEffect(() => {
    if (selectedPricingPlan) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [selectedPricingPlan]);

  useEffect(() => {
    if (authUser) {
      const { email } = authUser.session;
      setEmail(email);
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          email,
        },
      }));
    }
  }, [authUser]);

  useEffect(() => {
    if (!cardElement && elements && displayPayment) {
      setCardElement(elements.getElement(CardElement));
    }
  }, [cardElement, displayPayment, elements]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const isPaymentDetailsComplete = () =>
    stripe && complete && formState.isValid;

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePayment = async () => {
    if (!selectedPricingPlan || !email) return;
    const { id, title, oneTime } = selectedPricingPlan;
    try {
      setProcessingPayment(true);
      const res = await axios.post("/plan/intent", {
        planId: id,
        billingEmail: email,
      });
      const payment = res.data;
      const { client_secret } = payment;
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: name,
          email: email,
        },
      });
      if (paymentMethodReq.error) {
        dispatch(
          setSnackBarMessage({
            message: PAYMENT_ERROR(title),
            snackColor: ERROR,
            autoHideDuration: 10000,
          })
        );
        setCardError(paymentMethodReq.error.message);
        setProcessingPayment(false);
        return;
      }
      const { paymentIntent } = await stripe.confirmCardPayment(client_secret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });
      // note: we use hooks to process our promise to the customer
      if (paymentIntent && paymentIntent.status === "succeeded") {
        setProcessingPayment(false);
        setPaymentSuccess(true);
        handleClose();
        // display success page
        dispatch(
          setSnackBarMessage({
            message: PAYMENT_SUCCESS(oneTime, title),
            snackColor: SUCCESS,
            autoHideDuration: 2000,
          })
        );
      } else {
        dispatch(
          setSnackBarMessage({
            message: PAYMENT_ERROR(title),
            snackColor: ERROR,
            autoHideDuration: 2000,
          })
        );
        setProcessingPayment(false);
      }
    } catch (error) {
      dispatch(
        setSnackBarMessage({
          message: PAYMENT_ERROR(title),
          snackColor: ERROR,
          autoHideDuration: 2000,
        })
      );
      setProcessingPayment(false);
    }
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const renderPaymentOptions = () => {
    if (processingPayment) {
      return (
        <CircularProgress
          color="primary"
          size={30}
          classes={{
            root: classes.loading,
          }}
        />
      );
    }
    return (
      <Button
        disabled={!isPaymentDetailsComplete()}
        onClick={handlePayment}
        color={"primary"}
      >
        {MAKE_PAYMENT(selectedPricingPlan?.oneTime)}
      </Button>
    );
  };

  const renderConfirmation = () => {
    return (
      <div>
        <DialogContentText className={classes.featureHeader}>
          {BENEFITS_TITLE}
        </DialogContentText>
        {selectedPricingPlan?.features.map((feature, index) => (
          <div key={index} className={classes.feature}>
            <Icon
              fontIconClass="far fa-check-circle"
              fontIconColor={colors.pink[500]}
            />
            <DialogContentText className={classes.featureDescription}>
              {feature}
            </DialogContentText>
          </div>
        ))}
      </div>
    );
  };

  const renderPayment = () => {
    return (
      <div>
        <TextField
          placeholder="Cardholder Name"
          label="Name *"
          variant="outlined"
          size="medium"
          name="name"
          fullWidth
          helperText={hasError("name") ? formState.errors.name[0] : null}
          error={hasError("name")}
          onChange={handleChange}
          type="name"
          value={formState.values.name || ""}
        />
        <TextField
          className={classes.nextInputContainer}
          placeholder="Email Address"
          label="Email *"
          variant="outlined"
          size="medium"
          name="email"
          fullWidth
          helperText={hasError("email") ? formState.errors.email[0] : null}
          error={hasError("email")}
          onChange={handleChange}
          type="email"
          value={formState.values.email || ""}
        />
        {cardError && (
          <div className={classes.cardErrorContainer}>
            <span className={classes.cardError}>{cardError}</span>
          </div>
        )}

        <div className={classes.cardInputContainer}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: MUTED,
                  "::placeholder": {
                    color: MUTED,
                  },
                },
                invalid: {
                  color: ERROR,
                },
              },
            }}
            onChange={(changeObject) => {
              if (changeObject.complete) {
                // enable payment button
                setComplete(true);
              } else if (changeObject.error) {
                // show validation to customer
                setCardError(changeObject.error.message);
                setComplete(false);
              } else if (!changeObject.error) {
                setCardError(null);
              }
            }}
          />
        </div>

        <div className={classes.powerByStripeContainer}>
          <a
            href="https://www.stripe.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={stripeBadge} alt={"stripe-badge"} />
          </a>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle>
        {displayPayment
          ? PAYMENT_TITLE
          : PURCHASE_CONFIRMATION(
              selectedPricingPlan?.title,
              selectedPricingPlan?.oneTime
            )}
      </DialogTitle>
      <DialogContent>
        {displayPayment ? renderPayment() : renderConfirmation()}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="default"
          disabled={processingPayment}
        >
          Cancel
        </Button>
        {displayPayment ? (
          renderPaymentOptions()
        ) : (
          <Button onClick={handleProceed} color="primary">
            Proceed
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Checkout;
