export const HOME_ROUTE = "/";
export const REGISTER_ROUTE = "/register";
export const LOGIN_ROUTE = "/login";
export const LOGOUT_ROUTE = "/logout";
export const RESET_ROUTE = "/reset";
export const BROWSE_ROUTE = "/browse";
export const VERIFY_ROUTE = "/verify";
export const TERMS_ROUTE = "/terms";
export const PRIVACY_ROUTE = "/privacy";
export const RULES_ROUTE = "/rules";
export const CONTACT_ROUTE = "/contact";
export const PRICING_ROUTE = "/pricing";
export const FAQ_ROUTE = "/faqs";

// auth routes
export const ACCOUNT_ROUTE = "/account/:pageId?";
export const ACCOUNT_GENERAL_ROUTE = "/account/general";
export const ACCOUNT_ONBOARD = "/onboard";
export const ACCOUNT_ONBOARD_COMPLETE = "/completed";

export const FACEBOOK_ROUTE = "https://www.facebook.com/hollerdate";
export const TWITTER_ROUTE = "https://twitter.com/hollerdate";
export const INSTAGRAM_ROUTE = "https://www.instagram.com/hollerdate/";

export const APP_STORE_ROUTE =
  "https://apps.apple.com/us/app/holler-date-voice-dating/id1564975956";
export const PLAY_STORE_ROUTE =
  "https://play.google.com/store/apps/details?id=com.hollerdate.hollerdate";
