import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Waypoint } from "react-waypoint";

export default function VideoPlayer(props) {
  const [playing, setPlaying] = useState(false);
  return (
    <Waypoint
      onEnter={() => setPlaying(true)}
      onLeave={() => setPlaying(false)}
    >
      <div>
        <ReactPlayer playing={playing} {...props} />
      </div>
    </Waypoint>
  );
}
