import React, { useEffect, useState, useRef } from "react";
import { ReactMicGold } from "react-mic-gold";
import AudioPlayer from "react-h5-audio-player";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import validate from "validate.js";
import { AUDIO_TYPE, IMAGE_TYPE, SELECT_TYPE, TEXT_TYPE } from "constants/ui";
import { ACCOUNT_ONBOARD_COMPLETE } from "constants/routes";
import { Image } from "components/atoms";
import { ImageCrop, Webcam, StopWatch } from "components/molecules";
import { getCroppedImg } from "shared/images";
import { ERROR, LABEL, WHITE } from "constants/colors";
import {
  AUDIO_PERMISSION_REQUEST,
  AUDIO_UNSUPPORTED,
} from "constants/messages";
import { isSafari, isMobileSafari } from "react-device-detect";

// import clsx from "clsx";
import "assets/scss/audio.scss";
import { setSnackBarMessage } from "store/actions/ui";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    marginTop: 8,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
  loadingIndicator: {
    margin: 8,
  },
  image: {
    objectFit: "cover",
    borderRadius: theme.spacing(1),
  },
  previewContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 16,
  },
  audioContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  canvasContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "8px 0",
  },
  canvas: {
    width: "60%",
  },
  playBackContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 16,
  },
  timerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 16,
  },
  timer: {
    fontWeight: 700,
  },
  title: {
    textAlign: "center",
  },
}));

const unsupportedBrowsers = () => isSafari || isMobileSafari;

const Preferences = ({
  history,
  data,
  submit,
  nextStep,
  currentStep,
  totalSteps,
  imageUpload,
  imageDelete,
  audioUpload,
  audioDelete,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pendingAuthLoading = useRef(false);
  const authIsLoading = useSelector((state) => state.ui.authIsLoading);
  const authUser = useSelector((state) => state.auth.authUser);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [schema, setSchema] = useState({});
  const [isAudioType, setIsAudioType] = useState(false);
  const [isImageType, setIsImageType] = useState(false);
  // audio uploads
  const [audioURL, setAudioURL] = useState(null);
  const [recording, setRecording] = useState(false);
  const [runAudioTimer, setRunAudioTimer] = useState(false);
  const duration = useRef(0);
  // end audio uploads

  // photo uploads
  const [photoURL, setPhotoURL] = useState(null);
  const [image, setImage] = useState(null);
  const [selfie, setSelfie] = useState(false);
  // end photo uploads

  const handleSubmit = () => {
    const errors = validate(formState.values, schema);
    const touched = {};
    Object.keys(data).forEach((dataId) => {
      const argument = data[dataId];
      const { name } = argument;
      touched[name] = true;
    });

    if (!errors && authUser) {
      submit(formState.values);
      pendingAuthLoading.current = true;
    }

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
      touched,
    }));
  };

  useEffect(() => {
    if (authUser) {
      const { profile } = authUser;
      setPhotoURL(profile.photoURL);
      setAudioURL(profile.audioURL);
    }
  }, [authUser]);

  // note: this is a very bad implementation, however I have trouble finding a better alternative
  // since the steps wizard render all the child components at once
  // the only prop value that gives us a hint of the current wizard is current step
  useEffect(() => {
    setIsAudioType(false);
    setIsImageType(false);
    if (currentStep === 1) {
      setIsAudioType(true);
    } else if (currentStep === 5) {
      setIsImageType(true);
    }
  }, [currentStep]);

  useEffect(() => {
    if (!authIsLoading) {
      if (pendingAuthLoading.current) {
        pendingAuthLoading.current = false;
        if (currentStep === totalSteps) {
          history.replace(ACCOUNT_ONBOARD_COMPLETE);
        } else {
          nextStep();
        }
      }
    }
  }, [authIsLoading, nextStep, currentStep, totalSteps, history]);

  useEffect(() => {
    let temp = {};
    Object.keys(data).forEach((dataId) => {
      const argument = data[dataId];
      temp = { ...temp, ...argument.schema };
    });
    setSchema(temp);
  }, [data]);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values, schema]);

  useEffect(() => {
    if (!recording) {
      setRunAudioTimer(false);
    }
  }, [runAudioTimer, recording]);

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const handleUploadSelect = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
  };

  const handleImageUpload = (optionID) => {
    if (optionID === "selfie") {
      setSelfie(true);
    } else {
      handleUploadSelect();
    }
  };

  const handleImageDelete = () => {
    imageDelete();
  };

  const handleBlock = () => {
    dispatch(
      setSnackBarMessage({
        message: AUDIO_PERMISSION_REQUEST,
        snackColor: ERROR,
        autoHideDuration: 3000,
      })
    );
  };

  const isFieldInvalidForAudioOrImage = () => {
    if (isAudioType && !audioURL) return true;
    else return isImageType && !image && !photoURL;
  };

  const saveCropDialog = async (imageElement, crop) => {
    if (imageElement && crop.width && crop.height) {
      try {
        const blob = await getCroppedImg(imageElement, crop, document);
        imageUpload(blob);
        closeCropDialog();
      } catch (err) {
        console.error("failed to crop image", err);
      }
    }
  };

  const closeCropDialog = () => {
    setImage(null);
    document.getElementById("imageInput").value = "";
  };

  const renderSelect = (placeholder, label, name, options) => (
    <TextField
      select
      placeholder={placeholder}
      label={label}
      variant="outlined"
      size="medium"
      name={name}
      value={formState.values[name] || ""}
      helperText={hasError(name) ? formState.errors[name][0] : null}
      error={hasError(name)}
      fullWidth
      onChange={(event) => {
        setFormState({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value,
          },
          touched: {
            ...formState.touched,
            [event.target.name]: true,
          },
        });
      }}
    >
      {Object.keys(options).map((optionKey) => {
        const option = options[optionKey];
        const { title, code } = option;
        return (
          <MenuItem key={code} value={code}>
            {title}
          </MenuItem>
        );
      })}
    </TextField>
  );

  const renderText = (placeholder, label, name, inputType = TEXT_TYPE) => (
    <TextField
      type={inputType}
      placeholder={placeholder}
      label={label}
      variant="outlined"
      size="medium"
      name={name}
      value={formState.values[name] || ""}
      helperText={hasError(name) ? formState.errors[name][0] : null}
      error={hasError(name)}
      fullWidth
      onChange={(event) => {
        setFormState({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value,
          },
          touched: {
            ...formState.touched,
            [event.target.name]: true,
          },
        });
      }}
    />
  );

  const renderImage = (options) => {
    return (
      <>
        {photoURL && (
          <div className={classes.previewContainer}>
            <Image
              src={photoURL}
              srcSet={photoURL}
              className={classes.image}
              lazyProps={{ width: 100, height: 100 }}
            />
          </div>
        )}
        {Object.keys(options).map((optionId) => {
          const option = options[optionId];
          const { title } = option;
          return (
            <div key={optionId} className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => handleImageUpload(optionId)}
                style={{ width: "60%", margin: "8px 0" }}
              >
                {title}
              </Button>
            </div>
          );
        })}
        {photoURL && (
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              size="large"
              onClick={handleImageDelete}
              style={{ width: "60%", margin: "8px 0" }}
            >
              delete photo
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderAudio = (options) => {
    return (
      <>
        <div className={classes.audioContainer}>
          <div className={classes.timerContainer}>
            <StopWatch
              autoStart={runAudioTimer}
              durationCallback={(seconds) => {
                duration.current = seconds;
              }}
              maxDuration={15}
              maxDurationCallback={() => {
                setRecording(false);
              }}
            />
          </div>
          <div className={classes.canvasContainer}>
            <ReactMicGold
              className={classes.canvas}
              // visualSetting="frequencyBars"
              mimeType="audio/mp3"
              record={recording}
              // onData={(blob) => onData(blob)}
              onBlock={handleBlock} // available in gold version only
              onStart={onStart}
              onStop={onStop}
              strokeColor={LABEL}
              backgroundColor={WHITE}
              echoCancellation={true}
              autoGainControl={true}
              noiseSuppression={true}
              bitRate={320000}
            />
          </div>
          {audioURL && !recording && (
            <div className={classes.playBackContainer}>
              <AudioPlayer src={audioURL} volume={0.5} />
            </div>
          )}
        </div>

        {Object.keys(options).map((optionId) => {
          const option = options[optionId];
          const { title, inverseTitle } = option;
          return (
            <div key={optionId} className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="outlined"
                color={recording ? "secondary" : "primary"}
                size="large"
                onClick={recordAudio}
                style={{ width: "60%", margin: "8px 0" }}
              >
                {recording ? inverseTitle : title}
              </Button>
            </div>
          );
        })}
        {audioURL && !recording && (
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              size="large"
              onClick={audioDelete}
              style={{ width: "60%", margin: "8px 0" }}
            >
              delete audio
            </Button>
          </div>
        )}
      </>
    );
  };

  const recordAudio = () => {
    // if (unsupportedBrowsers()) {
    //   dispatch(
    //     setSnackBarMessage({
    //       message: AUDIO_UNSUPPORTED,
    //       snackColor: ERROR,
    //       autoHideDuration: 3000,
    //     })
    //   );
    //   return;
    // }

    if (recording) {
      setRecording(false);
    } else {
      setRecording(true);
    }
  };

  // const onData = (blob) => {
  //   if (blob) {
  //   }
  // };

  const onStart = () => {
    setRunAudioTimer(true);
  };

  // called from audio mic => cannot retrieve redux state
  // only use ref works
  const onStop = (blob) => {
    audioUpload(blob, duration.current);
  };

  const renderFieldUsingType = (dataPoint) => {
    const { type, label, placeholder, name, options } = dataPoint;
    switch (type) {
      case SELECT_TYPE:
        return renderSelect(placeholder, label, name, options);
      case AUDIO_TYPE:
        return renderAudio(options);
      case IMAGE_TYPE:
        return renderImage(options);
      default:
        return renderText(placeholder, label, name, type);
    }
  };

  const renderTitleUsingType = (dataPoint) => {
    const { type, title, secondaryTitle, subtitle } = dataPoint;
    switch (type) {
      case AUDIO_TYPE:
        return (
          <>
            <Typography variant="h6" className={classes.title}>
              {recording ? secondaryTitle : title}
            </Typography>
            {subtitle && (
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.title}
              >
                {subtitle}
              </Typography>
            )}
          </>
        );
      default:
        return (
          <>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.title}
              >
                {subtitle}
              </Typography>
            )}
          </>
        );
    }
  };

  return (
    <div className={classes.root}>
      <input
        type="file"
        id="imageInput"
        hidden="hidden"
        onChange={handleImageChange}
      />
      <ImageCrop
        image={image}
        closeCrop={closeCropDialog}
        saveCrop={saveCropDialog}
        aspect={1}
        keepSelection={true}
        minWidth={200}
        minHeight={200}
        maxWidth={400}
        maxHeight={400}
      />
      <Webcam
        showWebcam={selfie}
        closeWebcam={() => {
          setSelfie(false);
        }}
        takeScreenshot={(imageSrc) => {
          setSelfie(false);
          setImage(imageSrc);
        }}
      />
      <Grid container spacing={2}>
        {data.map((dataPoint) => {
          const { name } = dataPoint;
          return (
            <React.Fragment key={name}>
              <Grid item xs={12}>
                {renderTitleUsingType(dataPoint)}
              </Grid>
              <Grid item xs={12}>
                {renderFieldUsingType(dataPoint)}
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid item xs={12} className={classes.divider}>
          <Button
            disabled={authIsLoading || isFieldInvalidForAudioOrImage()}
            size="large"
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            {authIsLoading ? "saving" : "next"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Preferences.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Preferences;
