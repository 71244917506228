// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import firebaseConfig from "./config/firebaseConfig";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const store = firebase.firestore();
export const storage = firebase.storage();
export const GeoPoint = firebase.firestore.GeoPoint;
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
