const content = {
  overviewTitle: "Community Rules",
  overviewDescription: "Here’s an overview of our community rules:",
  preambleTitle: "Preamble",
  preambleDescription:
    "Welcome to Holler Date! We are truly grateful you are keen to join our community of singles and we are excited for the awesome folks you're going to meet here! Users like yourself are truly the driving force of this platform and we truly believe that communities become stronger when we communicate respectfully with others. Like most content platforms, Holler Date also have our own community posting guidelines and rules that we believe would keep the content on our platforms respectful for our readers. Do be mindful that as an online platform, our readers may come from all walks of life and ages. We thus have a responsibility to ensure that no inappropriate content is posted on our platforms. Our moderators screen our platforms actively to take down such content, and if necessary, to suspend the errant user accounts. When necessary, we will reach out to our local authorities for advice and assistance. We also reserve the right to revise and update the community rules at any time without prior notice.",
  minimumAgeTitle: "No Minors allowed",
  minimumAgeDescription:
    "You must be aged 18 years or older to use Holler Date. We prohibit all minors to use our services. We also do not permit any images of unaccompanied minors. If you see a profile that includes a photo of a minor, or appears to depicts a minor in a sexually suggestive manner, please use our in-app reporting tools to flag to our moderators immediately. In addition, our moderators frequently scout existing profiles and will terminate such accounts as well as delete any content relating to minors.",
  illegalTitle: "No illegal activity",
  illegalDescription:
    "No illegal activity whatsoever. Do not use this platform to conduct illegal business. We will not hesitate to refer such activity to our local authorities. In addition, our moderators will suspend the user account and block such content.",
  sugarTitle: "No transactional dating (sugar dating)",
  sugarDescription:
    "We don't do transactional dating on Holler Date. This platform is meant for singles looking for love and genuine relationships. Our moderators actively scout such content and if necessary, we will block the content or suspend the errant user account.",
  sexualRelatedTitle: "No sexual related content",
  sexualRelatedDescription:
    "Do not post any content relating to hookups, prostitution or advertise escort services or seek sexual favors. Do not post any pornographic content or sexually suggestive photos. Our moderators actively scout such content and if necessary, we will block the content or suspend the errant user account.",
  abusiveTitle: "No abusive and gory content",
  abusiveDescription:
    "Do not post violent material or gory content on our platform. Do not post content that discriminates or condones violence against any person, or groups based on race, ethnicity, religion, gender, age, nationality, sexual orientation or any aspects that is not listed here. Do not use vulgar or abusive language in your correspondence with other users, in your voice clips, on your profile or anywhere on the site. Do not abuse or threaten other users. We will not hesitate to refer such matters to our local authorities if we deem the user is a threat to public safety. Our moderators actively scout such content and if necessary, we will block the content or suspend the errant user account.",
};

export default content;
