const firebaseConfigProd = {
  apiKey: "AIzaSyAyRU-ROpUKFsQVeq1ldQvXFlTG86kH5A0",
  authDomain: "holler-date-prod.firebaseapp.com",
  projectId: "holler-date-prod",
  storageBucket: "holler-date-prod.appspot.com",
  messagingSenderId: "156235305771",
  appId: "1:156235305771:web:deabf969a60b99ce8aeb6e",
  measurementId: "G-H90955VFK4",
};

const firebaseConfigDev = {
  apiKey: "AIzaSyCufd1FwbO8ihZ6285C5U08CAo8-dBZHDg",
  authDomain: "holler-date-dev.firebaseapp.com",
  projectId: "holler-date-dev",
  storageBucket: "holler-date-dev.appspot.com",
  messagingSenderId: "206377577122",
  appId: "1:206377577122:web:f19dcf0bf2bbd00f98c5fb",
  measurementId: "G-ZRD5HDJLKY",
};

export default process.env.NODE_ENV === "development"
  ? firebaseConfigDev
  : firebaseConfigProd;
