import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, useMediaQuery, colors } from "@material-ui/core";
import { LearnMoreLink } from "components/atoms";
import { SectionHeader } from "components/molecules";
import { CONTACT_ROUTE } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {},
  gridItemBorder: {
    [theme.breakpoints.up("md")]: {
      borderRight: `1px solid ${colors.grey[200]}`,
    },
  },
}));

const Support = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={12} className={classes.gridItemBorder}>
          <SectionHeader
            title="Need Support?"
            titleVariant="h5"
            subtitle="Contact us at the following link. We're always here for you."
            subtitleVariant="body1"
            subtitleColor="textPrimary"
            ctaGroup={[
              <LearnMoreLink title="Contact Us" href={CONTACT_ROUTE} />,
            ]}
            disableGutter
          />
        </Grid>
        {/*<Grid item xs={12} sm={6}>*/}
        {/*  <SectionHeader*/}
        {/*    title="Customize Plan"*/}
        {/*    titleVariant="h5"*/}
        {/*    subtitle="Looking for something else? Request a Consultation to customize a plan."*/}
        {/*    subtitleVariant="body1"*/}
        {/*    subtitleColor="textPrimary"*/}
        {/*    ctaGroup={[<LearnMoreLink title="Contact us" />]}*/}
        {/*    disableGutter*/}
        {/*  />*/}
        {/*</Grid>*/}
      </Grid>
    </div>
  );
};

Support.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Support;
