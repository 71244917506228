import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  IconButton,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";

import { Image } from "components/atoms";
import {
  HOME_ROUTE,
  FACEBOOK_ROUTE,
  TWITTER_ROUTE,
  INSTAGRAM_ROUTE,
} from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 0),
    },
    background: theme.palette.primary.main,
  },
  footerContainer: {
    maxWidth: 1100,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 120,
    height: 32,
  },
  logoImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  groupTitle: {
    textTransform: "uppercase",
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: "rgba(255,255,255,.6)",
    "&:hover": {
      background: "transparent",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  menuListContainer: {
    padding: "0 !important",
  },
  menu: {
    display: "flex",
  },
  menuItem: {
    margin: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  menuGroupTitle: {
    textTransform: "uppercase",
    color: "white",
  },
  divider: {
    width: "100%",
  },
  navLink: {
    color: "rgba(255,255,255,.6)",
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const Footer = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const tncPages = pages.tnc;
  const contactPages = pages.contact;
  const accountPages = pages.account;

  const MenuGroup = (props) => {
    const { item } = props;
    return (
      <List disablePadding className={classes.menuItem}>
        <ListItem disableGutters className={classes.menuGroupItem}>
          <Typography variant="body2" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body2"
              component={CustomRouterLink}
              to={page.href}
              className={clsx(classes.navLink, "submenu-item")}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const TNCPages = () => {
    const { tnc } = tncPages.children;
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={tnc} />
        </div>
      </div>
    );
  };

  const ContactPages = () => {
    const { contact } = contactPages.children;
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={contact} />
        </div>
      </div>
    );
  };

  const AccountPages = () => {
    const { account } = accountPages.children;
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={account} />
        </div>
      </div>
    );
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={2}>
            <List disablePadding>
              <ListItem disableGutters className={classes.logoContainerItem}>
                <div className={classes.logoContainer}>
                  <a href={HOME_ROUTE} title="holler_date">
                    <Image
                      className={classes.logoImage}
                      src="/custom_images/logos/logo_2.png"
                      alt="holler_date"
                      lazy={false}
                    />
                  </a>
                </div>
              </ListItem>
              <ListItem disableGutters>
                <IconButton
                  className={classes.socialIcon}
                  href={FACEBOOK_ROUTE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon className={classes.icon} />
                </IconButton>
                <IconButton
                  className={classes.socialIcon}
                  href={INSTAGRAM_ROUTE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon className={classes.icon} />
                </IconButton>
                <IconButton
                  className={classes.socialIcon}
                  href={TWITTER_ROUTE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon className={classes.icon} />
                </IconButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={10} className={classes.menuListContainer}>
            <Grid container spacing={0}>
              <Grid item className={classes.listItem}>
                <TNCPages />
              </Grid>
              <Grid item className={classes.listItem}>
                <ContactPages />
              </Grid>
              <Grid item className={classes.listItem}>
                <AccountPages />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
