import React from "react";
import { Helmet } from "react-helmet";
// import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { SectionHeader, DescriptionCta } from "components/molecules";
import { Section } from "components/organisms";
import content from "./content";

const meta = {
  title: "Holler Date | Terms of Service",
  description: "Our terms of service.",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    "& .description-cta__button-group": {
      flexWrap: "nowrap",
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: "white",
  },
  cardHighlighted: {
    background: theme.palette.primary.main,
  },
  checkBox: {
    background: "transparent",
    borderRadius: 0,
    width: 30,
    height: 30,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const Terms = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Section className={classes.pagePaddingTop}>
        <DescriptionCta
          title="Terms of Service"
          subtitle="Updated on 20th Feb, 2021"
          primaryCta={
            null
            // <Button variant="outlined" color="primary" size="large">
            //   Print
            // </Button>
          }
          align={"left"}
          titleProps={{
            className: classes.fontWeightBold,
            color: "textPrimary",
          }}
          subtitleProps={{
            color: "textSecondary",
          }}
        />
        <Divider className={classes.divider} />
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <SectionHeader
              title={content.overviewTitle}
              subtitle={content.overviewDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
            />
            <SectionHeader
              title={content.tnCTitle}
              subtitle={content.tnCDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              disableGutter
            />
            <List className={classes.list}>
              {content.tncTerms.map((term, index) => (
                <ListItem disableGutters key={index}>
                  <ListItemAvatar>
                    <Avatar
                      src="/images/illustrations/check-icon-yellow.svg"
                      className={classes.checkBox}
                    />
                  </ListItemAvatar>
                  <Typography variant="body1" color="textPrimary">
                    {term}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <SectionHeader
              title={content.eligibilityTitle}
              subtitle={content.eligibilityDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              disableGutter
            />
            <List className={classes.list}>
              {content.eligibilityTerms.map((term, index) => (
                <ListItem disableGutters key={index}>
                  <ListItemAvatar>
                    <Avatar
                      src="/images/illustrations/check-icon-yellow.svg"
                      className={classes.checkBox}
                    />
                  </ListItemAvatar>
                  <Typography variant="body1" color="textPrimary">
                    {term}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <SectionHeader
              title={content.communityRulesTitle}
              subtitle={content.communityRulesDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.cookiesTitle}
              subtitle={content.cookiesDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.licenseTitle}
              subtitle={content.licenseDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              disableGutter
            />
            <List className={classes.list}>
              {content.licenseTerms.map((term, index) => (
                <ListItem disableGutters key={index}>
                  <ListItemAvatar>
                    <Avatar
                      src="/images/illustrations/check-icon-yellow.svg"
                      className={classes.checkBox}
                    />
                  </ListItemAvatar>
                  <Typography variant="body1" color="textPrimary">
                    {term}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <SectionHeader
              title={content.hyperLinkTitle}
              subtitle={content.hyperLinkDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              disableGutter
            />
            <List className={classes.list}>
              {content.hyperLinkTerms.map((term, index) => (
                <ListItem disableGutters key={index}>
                  <ListItemAvatar>
                    <Avatar
                      src="/images/illustrations/check-icon-yellow.svg"
                      className={classes.checkBox}
                    />
                  </ListItemAvatar>
                  <Typography variant="body1" color="textPrimary">
                    {term}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <SectionHeader
              title={content.iFrameTitle}
              subtitle={content.iFrameDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.contentLiabilityTitle}
              subtitle={content.contentLiabilityDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.privacyTitle}
              subtitle={content.privacyDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.reservationRightsTitle}
              subtitle={content.reservationRightsDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.removalLinksTitle}
              subtitle={content.removalLinksDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.disclaimerTitle}
              subtitle={content.disclaimerDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              disableGutter
            />
            <List className={classes.list}>
              {content.disclaimerTerms.map((term, index) => (
                <ListItem disableGutters key={index}>
                  <ListItemAvatar>
                    <Avatar
                      src="/images/illustrations/check-icon-yellow.svg"
                      className={classes.checkBox}
                    />
                  </ListItemAvatar>
                  <Typography variant="body1" color="textPrimary">
                    {term}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Section>
      <Divider />
    </div>
  );
};

export default Terms;
