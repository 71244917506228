import { resetDialog } from "store/actions/ui";
import {
  ACCOUNT_ONBOARD,
  REGISTER_ROUTE,
  PRICING_ROUTE,
} from "constants/routes";
import {
  CANCEL,
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  REVEAL_PHOTO,
  PURCHASE_POINTS,
  SEND_MATCH_REQUEST,
  UNMATCHED_REQUEST,
  COMPLETE_PROFILE,
} from "constants/messages";

export const createAccountDialogActions = (dispatch, history) => [
  {
    key: "cancel",
    title: CANCEL,
    color: "default",
    callback: () => dispatch(resetDialog()),
  },
  {
    key: "create",
    title: CREATE_ACCOUNT,
    color: "secondary",
    callback: () => {
      history.replace(REGISTER_ROUTE);
      dispatch(resetDialog());
    },
  },
];

export const completeProfileDialogActions = (dispatch, history) => [
  {
    key: "cancel",
    title: CANCEL,
    color: "default",
    callback: () => dispatch(resetDialog()),
  },
  {
    key: "create",
    title: COMPLETE_PROFILE,
    color: "secondary",
    callback: () => history.replace(ACCOUNT_ONBOARD),
  },
];

export const revealPhotoDialogActions = (dispatch, revealCallBack) => [
  {
    key: "cancel",
    title: CANCEL,
    color: "default",
    callback: () => dispatch(resetDialog()),
  },
  {
    key: "reveal",
    title: REVEAL_PHOTO,
    color: "secondary",
    callback: revealCallBack,
  },
];

export const revealPhotoExceededDialogActions = (dispatch, history) => [
  {
    key: "cancel",
    title: CANCEL,
    color: "default",
    callback: () => dispatch(resetDialog()),
  },
  {
    key: "reveal",
    title: PURCHASE_POINTS,
    color: "secondary",
    callback: () => {
      history.replace(PRICING_ROUTE);
      dispatch(resetDialog());
    },
  },
];

export const sendMatchRequestDialogActions = (dispatch, matchCallBack) => [
  {
    key: "cancel",
    title: CANCEL,
    color: "default",
    callback: () => dispatch(resetDialog()),
  },
  {
    key: "match",
    title: SEND_MATCH_REQUEST,
    color: "secondary",
    callback: matchCallBack,
  },
];

export const sendMatchRequestExceededDialogActions = (dispatch, history) => [
  {
    key: "cancel",
    title: CANCEL,
    color: "default",
    callback: () => dispatch(resetDialog()),
  },
  {
    key: "match",
    title: PURCHASE_POINTS,
    color: "secondary",
    callback: () => {
      history.replace(PRICING_ROUTE);
      dispatch(resetDialog());
    },
  },
];

export const unMatchDialogActions = (dispatch, unMatchCallback) => [
  {
    key: "cancel",
    title: CANCEL,
    color: "default",
    callback: () => dispatch(resetDialog()),
  },
  {
    key: "match",
    title: UNMATCHED_REQUEST,
    color: "secondary",
    callback: unMatchCallback,
  },
];

export const deleteAccountDialogActions = (dispatch, deleteCallBack) => [
  {
    key: "cancel",
    title: CANCEL,
    color: "default",
    callback: () => dispatch(resetDialog()),
  },
  {
    key: "delete",
    title: DELETE_ACCOUNT,
    color: "secondary",
    callback: deleteCallBack,
  },
];

export const isMobileDevice = (navigator) => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /Mobi|Android|(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i.test(
    userAgent
  );
};

export const isAndroidDevice = (navigator) => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /Android|(android)/i.test(userAgent);
};

export const isIOSDevice = (navigator) => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPad|iPhone|iPod/i.test(userAgent);
};
