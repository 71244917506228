import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid, Divider } from "@material-ui/core";
import { SectionHeader, DescriptionCta } from "components/molecules";
import { Section } from "components/organisms";
import content from "./content";

const meta = {
  title: "Holler Date | Community Rules",
  description: "Our community rules.",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    "& .description-cta__button-group": {
      flexWrap: "nowrap",
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: "white",
  },
  cardHighlighted: {
    background: theme.palette.primary.main,
  },
  checkBox: {
    background: "transparent",
    borderRadius: 0,
    width: 30,
    height: 30,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const Rules = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Section className={classes.pagePaddingTop}>
        <DescriptionCta
          title="Community Rules"
          subtitle="Updated on 20th Feb, 2021"
          primaryCta={
            null
            // <Button variant="outlined" color="primary" size="large">
            //   Print
            // </Button>
          }
          align={"left"}
          titleProps={{
            className: classes.fontWeightBold,
            color: "textPrimary",
          }}
          subtitleProps={{
            color: "textSecondary",
          }}
        />
        <Divider className={classes.divider} />
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <SectionHeader
              title={content.overviewTitle}
              subtitle={content.overviewDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
            />
            <SectionHeader
              title={content.preambleTitle}
              subtitle={content.preambleDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.minimumAgeTitle}
              subtitle={content.minimumAgeDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.illegalTitle}
              subtitle={content.illegalDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.sugarTitle}
              subtitle={content.sugarDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.sexualRelatedTitle}
              subtitle={content.sexualRelatedDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
            <SectionHeader
              title={content.abusiveTitle}
              subtitle={content.abusiveDescription}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: "textPrimary",
              }}
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              // disableGutter
            />
          </Grid>
        </Grid>
      </Section>
      <Divider />
    </div>
  );
};

export default Rules;
