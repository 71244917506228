import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Chip,
  Button,
  colors,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { CardProduct } from "components/organisms";
import { SwiperImage } from "components/molecules";
import { LearnMoreLink } from "components/atoms";
import AudioPlayer from "react-h5-audio-player";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  INFO,
  LABEL,
  PRIMARY,
  SUCCESS,
  WARNING,
  WHITE,
} from "constants/colors";
import {
  AUDIO_HELPER_TEXT,
  GENDER_PREFERENCE,
  REVEAL_PROMPT,
  INVITE_PROMPT,
  APPROVAL_STATUS,
  REVEAL_PHOTO_TOOLTIP,
  MATCH_REQUEST_TOOLTIP,
} from "constants/messages";
import { retrieveCountryOptionWithCode } from "shared/config/ui";
import { setSnackBarMessage } from "store/actions/ui";
import { PENDING } from "constants/auth";
import { ACCOUNT_ONBOARD, BROWSE_ROUTE } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
  },
  previewRoot: {
    height: "90%",
    marginBottom: 16,
  },
  swiperNavButton: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    padding: `${theme.spacing(2)}px !important`,
    background: `${PRIMARY} !important`,
  },
  locationCardPrice: {
    padding: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    background: WHITE,
    borderRadius: theme.spacing(1),
    zIndex: 3,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  fontPending: {
    color: WARNING,
  },
  locationCardReviewAvatar: {
    marginLeft: theme.spacing(-2),
    border: "3px solid white",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  locationCardReviewStar: {
    color: colors.yellow[800],
    marginRight: theme.spacing(1 / 2),
  },
  reviewCount: {
    marginLeft: theme.spacing(1),
  },
  audioRoot: {
    height: 300,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: "40%",
  },
  audioRootFemale: {
    backgroundImage: `url("/custom_images/hero/woman_shadow.jpg")`,
  },
  audioRootMale: {
    backgroundImage: `url("/custom_images/hero/man_shadow.jpg")`,
  },
  audioContainer: {
    marginBottom: 16,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  audioHelperText: {
    color: WHITE,
    textAlign: "center",
  },
  swiperRoot: {
    // width: "100%",
  },
  image: {
    borderBottomLeftRadius: "40%",
  },
  iconButton: {
    width: 50,
    height: 50,
    boxShadow: "0 2px 10px 0 rgba(23,70,161,.11)",
  },
  iconReveal: {
    background: LABEL,
    "&:hover": {
      background: LABEL,
    },
  },
  iconInvite: {
    background: SUCCESS,
    "&:hover": {
      background: SUCCESS,
    },
  },
  icon: {
    color: WHITE,
    width: 25,
    height: 25,
  },
  genderPreference: {
    color: LABEL,
    borderColor: LABEL,
  },
}));

const MALE = "male";

const Form = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const authUser = useSelector((state) => state.auth.authUser);
  const [mockReveal, setMockReveal] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [images, setImages] = useState([]);
  const [audioURL, setAudioURL] = useState(null);
  const [countryOptions, setCountryOptions] = useState({});
  const [status, setStatus] = useState(PENDING);
  const [gender, setGender] = useState("");
  const [genderPreference, setGenderPreference] = useState("");
  const [age, setAge] = useState("");

  useEffect(() => {
    if (authUser) {
      const { profile } = authUser;
      const { demographic } = profile;
      if (profile.photoURL) {
        setImages([{ src: profile.photoURL, srcSet: profile.photoURL }]);
      }
      setStatus(profile.status);
      setAudioURL(profile.audioURL);
      setDisplayName(profile.displayName);
      setCountryOptions(retrieveCountryOptionWithCode(demographic.country));
      setGender(demographic.gender);
      setGenderPreference(demographic.genderPreference);
      setAge(demographic.age);
    }
  }, [authUser]);

  const handleReveal = () => {
    if (mockReveal) {
      dispatch(
        setSnackBarMessage({
          message: INVITE_PROMPT(displayName),
          snackColor: INFO,
          autoHideDuration: 2000,
        })
      );
    } else {
      dispatch(
        setSnackBarMessage({
          message: REVEAL_PROMPT(displayName),
          snackColor: INFO,
          autoHideDuration: 2000,
        })
      );
    }
    setMockReveal(!mockReveal);
  };

  const renderAudio = () => {
    return (
      <div
        className={clsx(
          classes.audioRoot,
          gender === MALE ? classes.audioRootMale : classes.audioRootFemale
        )}
      >
        <div className={classes.audioContainer}>
          <Typography variant="body1" className={classes.audioHelperText}>
            {AUDIO_HELPER_TEXT}
          </Typography>
        </div>
        <AudioPlayer src={audioURL} volume={0.5} />
      </div>
    );
  };

  const renderReveal = () => {
    return (
      <SwiperImage
        className={classes.swiperRoot}
        navigationButtonStyle={classes.swiperNavButton}
        items={images}
        imageClassName={classes.image}
      />
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={10} data-aos="fade-up">
          <CardProduct
            className={classes.previewRoot}
            withShadow
            liftUp
            mediaContent={
              <>
                {!mockReveal ? renderAudio() : renderReveal()}

                <div className={classes.locationCardPrice}>
                  <Typography
                    variant="body1"
                    color="primary"
                    className={clsx(classes.fontWeight700, classes.fontPending)}
                  >
                    {APPROVAL_STATUS(status)}
                  </Typography>
                </div>
              </>
            }
            cardContent={
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    align="left"
                    className={classes.fontWeight700}
                  >
                    {displayName}, {age}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    align="left"
                  >
                    {countryOptions.title}
                  </Typography>
                </Grid>
                <Grid item container justify="space-between" xs={12}>
                  <Grid item container xs={6} wrap="nowrap">
                    <Chip
                      className={classes.genderPreference}
                      label={GENDER_PREFERENCE(genderPreference)}
                      color="secondary"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="flex-end"
                    xs={6}
                  >
                    <div>
                      <Tooltip
                        title={
                          !mockReveal
                            ? REVEAL_PHOTO_TOOLTIP
                            : MATCH_REQUEST_TOOLTIP(displayName)
                        }
                        placement="bottom-start"
                        arrow
                      >
                        <IconButton
                          className={clsx(
                            classes.iconButton,
                            !mockReveal
                              ? classes.iconReveal
                              : classes.iconInvite
                          )}
                          onClick={handleReveal}
                        >
                          {!mockReveal ? (
                            <FavoriteIcon className={classes.icon} />
                          ) : (
                            <PersonAddIcon className={classes.icon} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
                <Grid item container justify="flex-end" xs={12}>
                  <LearnMoreLink title="Edit Profile" href={ACCOUNT_ONBOARD} />
                </Grid>
              </Grid>
            }
          />
          <Button
            size="large"
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            href={BROWSE_ROUTE}
          >
            browse dates
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Form.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Form);
