import React from "react";
import {
  MapContainer as ReactMap,
  TileLayer,
  Marker,
  Popup,
} from "react-leaflet";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import iconRetina from "assets/images/leaflet/marker-icon-2x.png";
import icon from "assets/images/leaflet/marker-icon.png";
import shadow from "assets/images/leaflet/marker-shadow.png";

import "leaflet/dist/leaflet.css";
import "./index.scss";

import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  shadowUrl: shadow,
});

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
  },
}));

/**
 * Component to display the map
 *
 * @param {Object} props
 */
const Map = (props) => {
  const { zoom, center, pins, className, ...rest } = props;

  const classes = useStyles();

  return (
    <ReactMap
      zoom={zoom}
      center={center}
      className={clsx("map", classes.root, className)}
      {...rest}
    >
      <TileLayer
        className="map__tile-layer"
        detectRetina={true}
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {pins &&
        pins.length &&
        pins.map((item, i) => (
          <Marker
            className="map__marker"
            position={[item.location.y, item.location.x]}
            key={i}
          >
            <Popup>{item.location.address}</Popup>
          </Marker>
        ))}
    </ReactMap>
  );
};

Map.defaultProps = {
  zoom: 10,
  center: [0, 0],
};

Map.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Map zoom
   */
  zoom: PropTypes.node,
  /**
   * Map center
   */
  center: PropTypes.array.isRequired,
  /**
   * data of the locations. Example: [{ location: { x: number, y: number } }]
   */
  pins: PropTypes.array,
};

export default Map;
