import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { getIdToken } from "store/actions/auth";

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, auth, ...rest } = props;
  // Check for localStorage token. Otherwise we always get redirect immediately
  // on hard page reload before the redux action call finishes to fetch the authUser
  const token = getIdToken();
  return (
    <Route
      {...rest}
      render={(matchProps) =>
        auth && !token ? (
          <Redirect to="/" />
        ) : (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  auth: PropTypes.bool,
};

export default RouteWithLayout;
