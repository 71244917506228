import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isNumber from "is-number";
import isEmpty from "lodash.isempty";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  colors,
  Fab,
  Grid,
  Button,
  Typography,
  Chip,
  IconButton,
} from "@material-ui/core";
import { Icon } from "components/atoms";
import { SectionHeader, Dialog, SwiperImage } from "components/molecules";
import { getPaginatedMembers, resetMembers } from "store/actions/members";
import { useDispatch, useSelector } from "react-redux";
import { retrieveCountryOptionWithCode } from "shared/config/ui";
import {
  ACCOUNT_CREATION_INVITATION_MESSAGE,
  ACCOUNT_CREATION_INVITATION_TITLE,
  APPROVAL_STATUS,
  AUDIO_HELPER_TEXT,
  AUDIO_UNAVAILABLE_TEXT,
  GENDER_PREFERENCE,
  LOAD_MORE,
  LOADING,
  MATCH_REQUEST_MESSAGE,
  MATCH_REQUEST_PENDING_TOOLTIP,
  MATCH_REQUEST_TITLE,
  MATCH_REQUEST_TOOLTIP,
  MATCHED_TOOLTIP,
  OWNER_FORBIDDEN_TOOLTIP,
  REVEAL_PHOTO_MESSAGE,
  REVEAL_PHOTO_TITLE,
  REVEAL_PHOTO_TOOLTIP,
  REVEAL_PHOTO_MESSAGE_EXCEEDED,
  MATCH_REQUEST_MESSAGE_EXCEEDED,
  DATES_NONE,
  DATES_NONE_ADDITIONAL,
  GENDER,
  ACCOUNT_INCOMPLETE,
  ACCOUNT_INCOMPLETE_MESSAGE,
  ACCOUNT_CREATION_FILTER_MESSAGE,
} from "constants/messages";
import FilterListIcon from "@material-ui/icons/FilterList";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Tooltip from "@material-ui/core/Tooltip";
import { CardProduct } from "components/organisms";
import AudioPlayer from "react-h5-audio-player";
import { LABEL, PRIMARY, SUCCESS, WARNING, WHITE } from "constants/colors";
import { APPROVED, INCOMPLETE } from "constants/auth";
import { resetDialog, setDialog, setFilterModal } from "store/actions/ui";
import {
  createAccountDialogActions,
  revealPhotoDialogActions,
  sendMatchRequestExceededDialogActions,
  revealPhotoExceededDialogActions,
  sendMatchRequestDialogActions,
  completeProfileDialogActions,
} from "store/utils/ui";
import { sendRevealRequest, sendMatchRequest } from "store/actions/members";
import { PRICING_ROUTE } from "constants/routes";
import { generateIncompleteProfileText } from "store/utils/members";

const MALE = "male";

const useStyles = makeStyles((theme) => ({
  root: {},
  previewRoot: {
    marginBottom: 16,
  },
  swiperNavButton: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    padding: `${theme.spacing(2)}px !important`,
    background: `${PRIMARY} !important`,
  },
  locationCardPrice: {
    padding: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    background: WHITE,
    borderRadius: theme.spacing(1),
    zIndex: 3,
  },
  fontPending: {
    color: WARNING,
  },
  locationCardReviewAvatar: {
    marginLeft: theme.spacing(-2),
    border: "3px solid white",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  locationCardReviewStar: {
    color: colors.yellow[800],
    marginRight: theme.spacing(1 / 2),
  },
  reviewCount: {
    marginLeft: theme.spacing(1),
  },
  audioRoot: {
    // width: 429,
    height: 300,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: "40%",
  },
  audioRootFemale: {
    backgroundImage: `url("/custom_images/hero/woman_shadow.jpg")`,
  },
  audioRootMale: {
    backgroundImage: `url("/custom_images/hero/man_shadow.jpg")`,
  },
  audioContainer: {
    marginBottom: 16,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  audioHelperText: {
    color: WHITE,
    textAlign: "center",
  },
  swiperRoot: {},
  image: {
    borderBottomLeftRadius: "40%",
  },
  iconButton: {
    width: 50,
    height: 50,
    boxShadow: "0 2px 10px 0 rgba(23,70,161,.11)",
  },
  iconReveal: {
    background: LABEL,
    "&:hover": {
      background: LABEL,
    },
    "&:disabled": {
      background: LABEL,
      opacity: 0.4,
    },
  },
  iconInvite: {
    background: SUCCESS,
    "&:hover": {
      background: SUCCESS,
    },
    "&:disabled": {
      background: SUCCESS,
      opacity: 0.4,
    },
  },
  iconInvitePending: {
    background: WARNING,
    "&:hover": {
      background: WARNING,
    },
    "&:disabled": {
      background: WARNING,
      opacity: 0.4,
    },
  },
  iconInviteAccepted: {
    background: SUCCESS,
    "&:hover": {
      background: SUCCESS,
    },
    "&:disabled": {
      background: SUCCESS,
      opacity: 0.4,
    },
  },
  icon: {
    color: WHITE,
    width: 25,
    height: 25,
  },
  gender: {
    color: LABEL,
    borderColor: LABEL,
  },
  genderContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  genderPreference: {
    color: PRIMARY,
    borderColor: PRIMARY,
    marginLeft: 8,
  },
  loadMoreContainer: {
    display: "flex",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  noDatesContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    height: 325,
  },
  noDatesAdditional: {
    marginTop: 16,
    textAlign: "center",
  },
  filterIcon: {
    position: "fixed",
    bottom: 16,
    right: 8,
    zIndex: 2,
  },
}));

const MATCH_PENDING = "pending";
const MATCH_REJECT = "rejected";
const MATCH_ACCEPT = "accepted";

const Members = (props) => {
  const { className, history, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  const planIsLoading = useSelector((state) => state.ui.planIsLoading);
  const authUser = useSelector((state) => state.auth.authUser);
  const membersIsLoading = useSelector((state) => state.ui.membersIsLoading);
  const members = useSelector((state) => state.members.members);
  const plan = useSelector((state) => state.plan.plan);
  const configs = useSelector((state) => state.configs.configs);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [reveal, setReveal] = useState({});

  useEffect(() => {
    dispatch(resetMembers());
    dispatch(getPaginatedMembers(6, APPROVED));
  }, [dispatch, userId]);

  useEffect(() => {
    if (authUser) {
      const { profile } = authUser;
      const { revealed } = profile;
      if (revealed) {
        setReveal({ ...revealed });
      }
      setUserId(profile.userId);
    } else {
      setUserId(null);
    }
  }, [authUser]);

  const handleReveal = (memberId) => {
    if (!authUser || authUser.profile?.status === INCOMPLETE) {
      return;
    }
    dispatch(sendRevealRequest(memberId));
    dispatch(resetDialog());
    reveal[memberId] = !reveal[memberId];
    setReveal({ ...reveal });
  };

  const handleMatch = (memberId, memberDisplayName) => {
    if (!authUser || authUser.profile?.status === INCOMPLETE) {
      return;
    }
    dispatch(sendMatchRequest(memberId, memberDisplayName));
    dispatch(resetDialog());
  };

  const isOwner = (memberId) => authUser?.profile?.userId === memberId;

  const iconInviteStyles = (memberId) => {
    if (!authUser || !reveal[memberId]) {
      return classes.iconReveal;
    }
    const { profile } = authUser;
    const { matched, outBoundMatchedRequests } = profile;
    if (matched[memberId]) {
      return classes.iconInviteAccepted; // return chat style
    } else if (
      outBoundMatchedRequests[memberId] === MATCH_PENDING ||
      outBoundMatchedRequests[memberId] === MATCH_REJECT
    ) {
      return classes.iconInvitePending;
    } else {
      return classes.iconInvite;
    }
  };

  const isIconInviteDisabled = (memberId) => {
    if (!authUser) {
      return false;
    }
    if (planIsLoading) {
      return true;
    }
    const { profile } = authUser;
    const { outBoundMatchedRequests, matched } = profile;
    if (isOwner(memberId)) {
      return true;
    }
    return (
      outBoundMatchedRequests[memberId] === MATCH_PENDING ||
      outBoundMatchedRequests[memberId] === MATCH_REJECT ||
      matched[memberId]
    );
  };

  const configureToolTipStatus = (memberId, memberDisplayName) => {
    if (!authUser || !reveal[memberId]) {
      return REVEAL_PHOTO_TOOLTIP;
    }
    const { profile } = authUser;
    const { matched, outBoundMatchedRequests } = profile;
    if (isOwner(memberId)) {
      return OWNER_FORBIDDEN_TOOLTIP;
    }
    if (matched[memberId]) {
      return MATCHED_TOOLTIP(memberDisplayName);
    }
    if (
      outBoundMatchedRequests[memberId] === MATCH_PENDING ||
      outBoundMatchedRequests[memberId] === MATCH_REJECT
    ) {
      return MATCH_REQUEST_PENDING_TOOLTIP;
    }
    return MATCH_REQUEST_TOOLTIP(memberDisplayName);
  };

  const renderAudio = (gender, audioURL) => {
    return (
      <div
        className={clsx(
          classes.audioRoot,
          gender === MALE ? classes.audioRootMale : classes.audioRootFemale
        )}
      >
        <div className={classes.audioContainer}>
          <Typography variant="body1" className={classes.audioHelperText}>
            {audioURL ? AUDIO_HELPER_TEXT : AUDIO_UNAVAILABLE_TEXT}
          </Typography>
        </div>
        {audioURL && <AudioPlayer src={audioURL} volume={0.5} />}
      </div>
    );
  };

  const renderReveal = (images) => {
    return (
      <SwiperImage
        className={classes.swiperRoot}
        navigationButtonStyle={classes.swiperNavButton}
        items={images}
        imageClassName={classes.image}
      />
    );
  };

  const loadMore = () => {
    dispatch(getPaginatedMembers(6, APPROVED));
  };

  const handleFilter = () => {
    if (!authUser) {
      dispatch(
        setDialog(
          ACCOUNT_CREATION_INVITATION_TITLE,
          ACCOUNT_CREATION_FILTER_MESSAGE,
          createAccountDialogActions(dispatch, history)
        )
      );
      return;
    }

    dispatch(setFilterModal(true));
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title="Our latest members"
        subtitle="Use the filter option to narrow down your ideal dates"
        data-aos="fade-up"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {isEmpty(members) && !membersIsLoading && (
          <Grid item xs={12} data-aos="fade-up">
            <div className={classes.noDatesContainer}>
              <Typography variant="h6" color="textPrimary">
                {DATES_NONE}
              </Typography>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.noDatesAdditional}
              >
                {DATES_NONE_ADDITIONAL}
              </Typography>
            </div>
          </Grid>
        )}
        {Object.keys(members).map((docId, index) => {
          const member = members[docId];
          const { userId, audioURL, status, displayName, demographic } = member;
          const { age, country, gender, genderPreference } = demographic || {};
          const countryOptions = retrieveCountryOptionWithCode(country);
          const { title } = countryOptions;
          const images = [{ src: member.photoURL, srcSet: member.photoURL }];
          return (
            <Grid item xs={12} sm={6} md={4} key={index} data-aos="fade-up">
              <CardProduct
                className={classes.previewRoot}
                withShadow
                liftUp
                mediaContent={
                  <>
                    {!reveal[userId]
                      ? renderAudio(gender, audioURL)
                      : renderReveal(images)}

                    <div className={classes.locationCardPrice}>
                      <Typography
                        variant="body1"
                        color="primary"
                        className={clsx(
                          classes.fontWeight700,
                          classes.fontPending
                        )}
                      >
                        {APPROVAL_STATUS(status)}
                      </Typography>
                    </div>
                  </>
                }
                cardContent={
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        align="left"
                        className={classes.fontWeight700}
                      >
                        {displayName}, {age}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="left"
                      >
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item container justify="space-between" xs={12}>
                      <Grid
                        item
                        container
                        xs={6}
                        wrap="nowrap"
                        className={classes.genderContainer}
                      >
                        {/*<Chip*/}
                        {/*  className={classes.gender}*/}
                        {/*  label={GENDER(gender)}*/}
                        {/*  color="secondary"*/}
                        {/*  variant="outlined"*/}
                        {/*/>*/}
                        {gender === "female" ? (
                          <Icon
                            fontIconClass="fas fa-female"
                            size="medium"
                            fontIconColor={colors.pink[500]}
                          />
                        ) : (
                          <Icon
                            fontIconClass="fas fa-male"
                            size="medium"
                            fontIconColor={colors.blue[500]}
                          />
                        )}
                        <Chip
                          className={classes.genderPreference}
                          label={GENDER_PREFERENCE(genderPreference)}
                          color="secondary"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        justify="flex-end"
                        xs={6}
                      >
                        <Tooltip
                          title={configureToolTipStatus(userId, displayName)}
                          placement="bottom-start"
                          arrow
                        >
                          <div>
                            <IconButton
                              className={clsx(
                                classes.iconButton,
                                iconInviteStyles(userId)
                              )}
                              disabled={isIconInviteDisabled(userId)}
                              onClick={() => {
                                if (!authUser) {
                                  dispatch(
                                    setDialog(
                                      ACCOUNT_CREATION_INVITATION_TITLE,
                                      ACCOUNT_CREATION_INVITATION_MESSAGE(
                                        displayName
                                      ),
                                      createAccountDialogActions(
                                        dispatch,
                                        history
                                      )
                                    )
                                  );
                                } else if (
                                  authUser?.profile?.status === INCOMPLETE
                                ) {
                                  dispatch(
                                    setDialog(
                                      ACCOUNT_INCOMPLETE,
                                      ACCOUNT_INCOMPLETE_MESSAGE(
                                        generateIncompleteProfileText(
                                          authUser?.profile
                                        )
                                      ),
                                      completeProfileDialogActions(
                                        dispatch,
                                        history
                                      )
                                    )
                                  );
                                } else {
                                  let { pointsLimit } = plan || {};
                                  pointsLimit = isNumber(pointsLimit)
                                    ? pointsLimit
                                    : configs.pointsLimit;
                                  const revealPhoto = reveal[userId];
                                  if (revealPhoto) {
                                    if (isNumber(pointsLimit)) {
                                      if (pointsLimit <= 0) {
                                        dispatch(
                                          setDialog(
                                            MATCH_REQUEST_TITLE,
                                            MATCH_REQUEST_MESSAGE_EXCEEDED(
                                              displayName,
                                              pointsLimit,
                                              configs
                                            ),
                                            sendMatchRequestExceededDialogActions(
                                              dispatch,
                                              history
                                            )
                                          )
                                        );
                                      } else {
                                        dispatch(
                                          setDialog(
                                            MATCH_REQUEST_TITLE,
                                            MATCH_REQUEST_MESSAGE(
                                              displayName,
                                              pointsLimit,
                                              configs
                                            ),
                                            sendMatchRequestDialogActions(
                                              dispatch,
                                              () =>
                                                handleMatch(userId, displayName)
                                            )
                                          )
                                        );
                                      }
                                    }
                                  } else {
                                    if (isNumber(pointsLimit)) {
                                      if (pointsLimit <= 0) {
                                        dispatch(
                                          setDialog(
                                            REVEAL_PHOTO_TITLE,
                                            REVEAL_PHOTO_MESSAGE_EXCEEDED(
                                              displayName,
                                              pointsLimit,
                                              configs
                                            ),
                                            revealPhotoExceededDialogActions(
                                              dispatch,
                                              history
                                            )
                                          )
                                        );
                                      } else {
                                        dispatch(
                                          setDialog(
                                            REVEAL_PHOTO_TITLE,
                                            REVEAL_PHOTO_MESSAGE(
                                              displayName,
                                              pointsLimit,
                                              configs
                                            ),
                                            revealPhotoDialogActions(
                                              dispatch,
                                              () => handleReveal(userId)
                                            )
                                          )
                                        );
                                      }
                                    }
                                  }
                                }
                              }}
                            >
                              {!reveal[userId] ? (
                                <FavoriteIcon className={classes.icon} />
                              ) : (
                                <PersonAddIcon className={classes.icon} />
                              )}
                            </IconButton>
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          );
        })}
        {!isEmpty(members) && (
          <Grid item xs={12} container justify="center" data-aos="fade-up">
            <Button
              disabled={membersIsLoading}
              variant="outlined"
              color="primary"
              onClick={loadMore}
            >
              {membersIsLoading ? LOADING : LOAD_MORE}
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog />
      <Fab
        color="secondary"
        className={classes.filterIcon}
        onClick={handleFilter}
      >
        <FilterListIcon />
      </Fab>
    </div>
  );
};

Members.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Members;
