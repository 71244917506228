import { SET_AUTHENTICATED_USER } from "store/types/authTypes";

const initialState = {
  authUser: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      return {
        ...state,
        authUser: action.authUser,
      };
    default:
      return state;
  }
};

export default reducer;
