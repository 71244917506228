import { SET_CONFIG } from "../types/configTypes";

const initialState = {
  configs: {
    maxAudioLength: 15,
    minRate: 0.25,
    maxRate: 3,
    emailDurationHourlyDelay: 1, // in hours
    pushDurationMinutesDelay: 5, // in minutes
    pointsLimit: 12,
    revealCost: 1,
    matchCost: 2,
    blurRadius: 8,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        configs: action.configs,
      };
    default:
      return state;
  }
};

export default reducer;
