export const MEMBERS_SNAPSHOT = "MEMBERS_SNAPSHOT";
export const MEMBERS_MODERATE_SNAPSHOT = "MEMBERS_MODERATE_SNAPSHOT";
export const MEMBERS_REQUESTS_SNAPSHOT = "MEMBERS_REQUESTS_SNAPSHOT";
export const MEMBERS_MATCHES_SNAPSHOT = "MEMBERS_MATCHES_SNAPSHOT";
export const GET_MEMBERS_MODERATE = "GET_MEMBERS_MODERATE";
export const GET_MEMBERS = "GET_MEMBERS";
export const RESET_MEMBERS = "RESET_MEMBERS";
export const GET_MEMBERS_REQUESTS = "GET_MEMBERS_REQUESTS";
export const RESET_MEMBERS_REQUESTS = "RESET_MEMBERS_REQUESTS";
export const RESET_MEMBERS_MODERATE = "RESET_MEMBERS_MODERATE";
export const GET_MEMBERS_MATCHES = "GET_MEMBERS_MATCHES";
export const RESET_MEMBERS_MATCHES = "RESET_MEMBERS_MATCHES";
export const GET_MEMBERS_FILTER = "GET_MEMBERS_FILTER";
