import { store } from "shared/firebase";
import isEmpty from "lodash.isempty";
import {
  membersUIStartLoading,
  membersUIStopLoading,
  membersUpdateUIStartLoading,
  membersUpdateUIStopLoading,
  planUIStartLoading,
  planUIStopLoading,
  resetDialog,
  setSnackBarMessage,
} from "store/actions/ui";
import {
  GET_MEMBERS,
  GET_MEMBERS_REQUESTS,
  MEMBERS_REQUESTS_SNAPSHOT,
  MEMBERS_MATCHES_SNAPSHOT,
  MEMBERS_SNAPSHOT,
  RESET_MEMBERS,
  RESET_MEMBERS_REQUESTS,
  GET_MEMBERS_MATCHES,
  RESET_MEMBERS_MATCHES,
  GET_MEMBERS_FILTER,
  GET_MEMBERS_MODERATE,
  MEMBERS_MODERATE_SNAPSHOT,
  RESET_MEMBERS_MODERATE,
} from "store/types/membersTypes";
import axios from "axios";
import { setAuthUser } from "./auth";
import { setPlan } from "./plans";
import {
  MATCH_REQUEST_FAILED,
  MATCH_REQUEST_SUCCESSFUL,
  UNMATCHED_REQUEST_SUCCESSFUL,
} from "constants/messages";
import { ERROR, SUCCESS } from "constants/colors";
import { ALL, APPROVED } from "constants/auth";
import { MATCH_PENDING } from "constants/members";

const profileCollection = store.collection("profiles");

export const queryMembersModerate = (
  prevSnapshot,
  pageSize,
  status = APPROVED,
  hidden = false
) => {
  try {
    let query = profileCollection.orderBy("createdAt", "desc");
    query = status !== ALL ? query.where("status", "==", status) : query;
    query = status !== ALL ? query.where("type", "==", "user") : query;
    query = hidden ? query.where("hidden", "==", true) : query;
    query = prevSnapshot ? query.startAfter(prevSnapshot) : query;
    query = query.limit(pageSize);
    return query;
  } catch (err) {
    console.log("err", err);
    return null;
  }
};

export const queryMembers = (
  prevSnapshot,
  pageSize,
  status = APPROVED,
  gender,
  genderPreference
) => {
  try {
    let query = profileCollection.orderBy("createdAt", "desc");
    query = query.where("status", "==", status);
    query = query.where("hidden", "==", false);
    query = query.where("type", "==", "user");
    query =
      gender && status !== ALL
        ? query.where("demographic.genderPreference", "==", gender)
        : query;
    query =
      genderPreference && status !== ALL
        ? query.where("demographic.gender", "==", genderPreference)
        : query;
    query = prevSnapshot ? query.startAfter(prevSnapshot) : query;
    query = query.limit(pageSize);
    return query;
  } catch (err) {
    console.log("err", err);
  }
};

export const queryMembersByFilter = (prevSnapshot, pageSize, filter = {}) => {
  try {
    const { country, gender } = filter || {};
    let query = profileCollection.orderBy("createdAt", "desc");
    query = query.where("status", "==", APPROVED);
    query = query.where("type", "==", "user");
    query = gender ? query.where("demographic.gender", "==", gender) : query;
    query = country ? query.where("demographic.country", "==", country) : query;
    query = prevSnapshot ? query.startAfter(prevSnapshot) : query;
    query = query.limit(pageSize);
    return query;
  } catch (err) {
    console.log("err", err);
  }
};

export const queryMembersRequests = (prevSnapshot, pageSize, userID) => {
  try {
    let query = profileCollection
      .where(`outBoundMatchedRequests.${userID}`, "==", MATCH_PENDING)
      .where("status", "==", APPROVED);
    query = prevSnapshot ? query.startAfter(prevSnapshot) : query;
    query = query.limit(pageSize);
    return query;
  } catch (err) {
    console.log("err", err);
  }
};

export const queryMembersMatches = (prevSnapshot, pageSize, userID) => {
  try {
    let query = profileCollection
      .where(`matched.${userID}`, "==", true)
      .where("status", "==", APPROVED);
    query = prevSnapshot ? query.startAfter(prevSnapshot) : query;
    query = query.limit(pageSize);
    return query;
  } catch (err) {
    console.log("err", err);
  }
};

export const getPaginatedMembersModerate = (
  pageSize = 12,
  status = ALL,
  hidden = false
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(membersUIStartLoading());
      const prevSnapshot = getState().members.membersModerateSnapshot;
      const documents = await queryMembersModerate(
        prevSnapshot,
        pageSize,
        status,
        hidden
      ).get();
      const members = {};
      const lastSnapshot = !documents.empty
        ? documents.docs[documents.docs.length - 1]
        : null;
      const { docs } = documents;
      docs.forEach((doc) => {
        const data = doc.data();
        members[doc.id] = {
          ...data,
          docId: doc.id,
        };
      });
      dispatch(membersUIStopLoading());
      if (lastSnapshot) {
        dispatch(setMembersModerateSnapshot(lastSnapshot));
      }
      dispatch(setMembersModerate(members));
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getPaginatedMembers = (pageSize = 12, status = APPROVED) => {
  return async (dispatch, getState) => {
    try {
      dispatch(membersUIStartLoading());
      const authUser = getState().auth.authUser;
      const { profile } = authUser || {};
      const { demographic } = profile || {};
      const appliedFilter = getState().members.membersFilter;
      const prevSnapshot = getState().members.membersSnapshot;
      const documents = !isEmpty(appliedFilter)
        ? await queryMembersByFilter(
            prevSnapshot,
            pageSize,
            appliedFilter
          ).get()
        : await queryMembers(
            prevSnapshot,
            pageSize,
            status,
            demographic?.gender,
            demographic?.genderPreference
          ).get();
      const members = {};
      const lastSnapshot = !documents.empty
        ? documents.docs[documents.docs.length - 1]
        : null;
      const docs = documents.docs;
      docs.forEach((doc) => {
        const data = doc.data();
        members[doc.id] = {
          ...data,
          docId: doc.id,
        };
      });
      dispatch(membersUIStopLoading());
      dispatch(setMembersSnapshot(lastSnapshot));
      dispatch(setMembers(members));
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getPaginatedMembersRequests = (pageSize = 12) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      const prevSnapshot = getState().members.membersRequestsSnapshot;
      if (authUser) {
        dispatch(membersUIStartLoading());
        const { profile } = authUser;
        const { userId } = profile;
        const documents = await queryMembersRequests(
          prevSnapshot,
          pageSize,
          userId
        ).get();
        const members = {};
        const lastSnapshot = !documents.empty
          ? documents.docs[documents.docs.length - 1]
          : null;
        const docs = documents.docs;
        docs.forEach((doc) => {
          const data = doc.data();
          members[doc.id] = {
            ...data,
            docId: doc.id,
          };
        });
        dispatch(membersUIStopLoading());
        dispatch(setMembersRequestsSnapshot(lastSnapshot));
        dispatch(setMembersRequests(members));
      }
      dispatch(membersUIStopLoading());
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getPaginatedMembersMatches = (pageSize = 12) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      const prevSnapshot = getState().members.membersMatchesSnapshot;
      if (authUser) {
        dispatch(membersUIStartLoading());
        const { profile } = authUser;
        const { userId } = profile;
        const documents = await queryMembersMatches(
          prevSnapshot,
          pageSize,
          userId
        ).get();
        const members = {};
        const lastSnapshot = !documents.empty
          ? documents.docs[documents.docs.length - 1]
          : null;
        const docs = documents.docs;
        docs.forEach((doc) => {
          const data = doc.data();
          members[doc.id] = {
            ...data,
            docId: doc.id,
          };
        });
        dispatch(membersUIStopLoading());
        dispatch(setMembersMatchesSnapshot(lastSnapshot));
        dispatch(setMembersMatches(members));
      }
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const sendRevealRequest = (revealedUserId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(planUIStartLoading());
      const res = await axios.put("/members/revealRequest", {
        revealedUserId,
      });
      const plan = res.data;
      dispatch(setPlan(plan));
      dispatch(planUIStopLoading());
    } catch (err) {
      dispatch(planUIStopLoading());
      console.error("create plan err", err);
    }
  };
};

export const sendMatchRequest = (matchedUserId, matchedDisplayName) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      if (!authUser) return;
      dispatch(planUIStartLoading());
      const res = await axios.put("/members/matchRequest", {
        matchedUserId,
      });
      const { session } = authUser;
      const { plan, profile } = res.data;
      const userData = { session, profile };
      dispatch(setAuthUser(userData)); // refresh the userData profile
      dispatch(setPlan(plan));
      dispatch(resetDialog());
      dispatch(planUIStopLoading());

      // send toast
      dispatch(
        setSnackBarMessage({
          message: MATCH_REQUEST_SUCCESSFUL(matchedDisplayName),
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (err) {
      dispatch(planUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: MATCH_REQUEST_FAILED(matchedDisplayName),
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
      console.error("create plan err", err);
    }
  };
};

export const matchApprove = (requestUserId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(membersUpdateUIStartLoading());
      const authUser = getState().auth.authUser;
      const res = await axios.put("/members/matchApprove", {
        requestUserId,
      });
      const { session } = authUser;
      const profile = res.data;
      const userData = { session, profile };
      dispatch(setAuthUser(userData)); // refresh the userData profile
      dispatch(membersUpdateUIStopLoading());
    } catch (error) {
      dispatch(membersUpdateUIStopLoading());
    }
  };
};

export const matchReject = (requestUserId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(membersUIStartLoading());
      const authUser = getState().auth.authUser;
      const res = await axios.put("/members/matchReject", {
        requestUserId,
      });
      const { session } = authUser;
      const profile = res.data;
      const userData = { session, profile };
      dispatch(setAuthUser(userData)); // refresh the userData profile
      dispatch(membersUpdateUIStopLoading());
    } catch (error) {
      dispatch(membersUIStopLoading());
    }
  };
};

export const unMatch = (unMatchedUserId, matchedDocID, matchedDisplayName) => {
  return async (dispatch, getState) => {
    try {
      dispatch(membersUIStartLoading());
      const authUser = getState().auth.authUser;
      const membersMatches = getState().members.membersMatches;
      const res = await axios.put("/members/unMatch", {
        unMatchedUserId,
      });
      const { session } = authUser;
      const profile = res.data;
      const userData = { session, profile };
      delete membersMatches[matchedDocID];
      dispatch(setAuthUser(userData)); // refresh the userData profile
      dispatch(setMembersMatches({ ...membersMatches }));
      dispatch(membersUpdateUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: UNMATCHED_REQUEST_SUCCESSFUL(matchedDisplayName),
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      dispatch(membersUIStopLoading());
    }
  };
};

export const setMembersModerateSnapshot = (membersModerateSnapshot) => {
  return {
    type: MEMBERS_MODERATE_SNAPSHOT,
    membersModerateSnapshot,
  };
};

export const setMembersModerate = (membersModerate) => {
  return {
    type: GET_MEMBERS_MODERATE,
    membersModerate,
  };
};

export const resetMembersModerate = () => {
  return {
    type: RESET_MEMBERS_MODERATE,
  };
};

export const setMembersSnapshot = (membersSnapshot) => {
  return {
    type: MEMBERS_SNAPSHOT,
    membersSnapshot: membersSnapshot,
  };
};

export const setMembers = (members) => {
  return {
    type: GET_MEMBERS,
    members: members,
  };
};

export const resetMembers = () => {
  return {
    type: RESET_MEMBERS,
  };
};

export const setMembersRequestsSnapshot = (membersRequestsSnapshot) => {
  return {
    type: MEMBERS_REQUESTS_SNAPSHOT,
    membersRequestsSnapshot: membersRequestsSnapshot,
  };
};

export const setMembersRequests = (membersRequests) => {
  return {
    type: GET_MEMBERS_REQUESTS,
    membersRequests: membersRequests,
  };
};

export const resetMembersRequests = () => {
  return {
    type: RESET_MEMBERS_REQUESTS,
  };
};

export const setMembersMatchesSnapshot = (membersMatchesSnapshot) => {
  return {
    type: MEMBERS_MATCHES_SNAPSHOT,
    membersMatchesSnapshot: membersMatchesSnapshot,
  };
};

export const setMembersMatches = (membersMatches) => {
  return {
    type: GET_MEMBERS_MATCHES,
    membersMatches: membersMatches,
  };
};

export const resetMembersMatches = () => {
  return {
    type: RESET_MEMBERS_MATCHES,
  };
};

export const setMembersFilter = (membersFilter) => {
  return {
    type: GET_MEMBERS_FILTER,
    membersFilter: membersFilter,
  };
};
