import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import isEqual from "lodash.isequal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "store/actions/auth";
import { HOME_ROUTE } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
  loadingIndicator: {
    margin: 8,
  },
  mismatch: {
    marginTop: 8,
    color: theme.palette.error.main,
  },
}));

const schema = {
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
  },
  passwordConfirm: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
  },
};

const Form = ({ history, location }) => {
  const dispatch = useDispatch();
  const changeIsLoading = useSelector((state) => state.ui.changeIsLoading);
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [mismatch, setMismatch] = useState(false);
  const [oobCode, setOobCode] = useState(null);
  const [mode, setMode] = useState(null);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query?.oobCode && query?.mode) {
      setOobCode(query.oobCode);
      setMode(query.mode);
    } else {
      history.replace(HOME_ROUTE);
    }
  }, [dispatch, history, location.search]);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    const { password, passwordConfirm } = formState.values;
    if (!errors && !isEqual(password, passwordConfirm)) {
      setMismatch(true);
    } else {
      setMismatch(false);
    }

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      const { values } = formState;
      const { password, passwordConfirm } = values;
      if (!isEqual(password, passwordConfirm)) {
        setMismatch(true);
      } else {
        dispatch(resetPassword(password, oobCode));
      }
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.root}>
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="Password"
              label="Password *"
              variant="outlined"
              size="medium"
              name="password"
              fullWidth
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              error={hasError("password")}
              onChange={handleChange}
              type="password"
              value={formState.values.password || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Confirm Password"
              label="Confirm Password *"
              variant="outlined"
              size="medium"
              name="passwordConfirm"
              fullWidth
              helperText={
                hasError("passwordConfirm")
                  ? formState.errors.passwordConfirm[0]
                  : null
              }
              error={hasError("passwordConfirm")}
              onChange={handleChange}
              type="password"
              value={formState.values.passwordConfirm || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                Fields that are marked with * sign are required.
              </Typography>
            </i>
            {mismatch && (
              <i>
                <Typography variant="subtitle2" className={classes.mismatch}>
                  Passwords do not match.
                </Typography>
              </i>
            )}
          </Grid>
          {!changeIsLoading ? (
            <Grid item xs={12}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.loadingContainer}>
              <CircularProgress
                className={classes.loadingIndicator}
                color="primary"
                size={30}
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

Form.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Form);
