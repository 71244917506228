import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import { SectionHeader } from "components/molecules";
import { HeroShaped, Map } from "components/organisms";

const useStyles = makeStyles(() => ({
  root: {},
  map: {
    zIndex: 9,
  },
  icon: {
    background: "transparent",
    borderRadius: 0,
  },
}));

const Contact = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <HeroShaped
        leftSide={
          <div>
            <SectionHeader
              title="Contact details"
              subtitle="Let us know about any feedback, questions or issues you have with Holler Date!"
              subtitleProps={{
                variant: "body1",
                color: "textPrimary",
              }}
              data-aos="fade-up"
              align="left"
            />
            <List disablePadding>
              {/*<ListItem disableGutters data-aos="fade-up">*/}
              {/*  <ListItemAvatar>*/}
              {/*    <Avatar*/}
              {/*      src="/images/illustrations/contact-icon-phone.png"*/}
              {/*      srcSet="/images/illustrations/contact-icon-phone@2x.png 2x"*/}
              {/*      className={classes.icon}*/}
              {/*    />*/}
              {/*  </ListItemAvatar>*/}
              {/*  <ListItemText*/}
              {/*    className={classes.listItemText}*/}
              {/*    primary="Phone"*/}
              {/*    secondary="+39 659-657-0133"*/}
              {/*    primaryTypographyProps={{*/}
              {/*      className: classes.title,*/}
              {/*      variant: "subtitle1",*/}
              {/*      color: "textSecondary",*/}
              {/*    }}*/}
              {/*    secondaryTypographyProps={{*/}
              {/*      variant: "subtitle1",*/}
              {/*      color: "textPrimary",*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</ListItem>*/}
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar
                    src="/images/illustrations/contact-icon-mail.png"
                    srcSet="/images/illustrations/contact-icon-mail@2x.png 2x"
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary="Email"
                  secondary="support@hollerdate.com"
                  primaryTypographyProps={{
                    className: classes.title,
                    variant: "subtitle1",
                    color: "textSecondary",
                  }}
                  secondaryTypographyProps={{
                    variant: "subtitle1",
                    color: "textPrimary",
                  }}
                />
              </ListItem>
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar
                    src="/images/illustrations/contact-icon-pin.png"
                    srcSet="/images/illustrations/contact-icon-pin@2x.png 2x"
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary="Office"
                  secondary="51 GOLDHILL PLAZA, #07-10/11 SINGAPORE 308900"
                  primaryTypographyProps={{
                    className: classes.title,
                    variant: "subtitle1",
                    color: "textSecondary",
                  }}
                  secondaryTypographyProps={{
                    variant: "subtitle1",
                    color: "textPrimary",
                  }}
                />
              </ListItem>
            </List>
          </div>
        }
        rightSide={
          <Map
            zoom={18}
            center={[1.318518218956279, 103.84290088213963]}
            pins={data}
            className={classes.map}
          />
        }
      />
    </div>
  );
};

Contact.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Contact;
