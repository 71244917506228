export const marshallSender = (senderProfile) => {
  if (!senderProfile) return;
  return {
    senderId: senderProfile.userId,
    senderImage: senderProfile.photoURL,
    senderName: senderProfile.displayName,
  };
};

export const marshallReceiver = (receiverProfile) => {
  if (!receiverProfile) return;
  return {
    receiverId: receiverProfile.userId,
    receiverImage: receiverProfile.photoURL,
    receiverName: receiverProfile.displayName,
  };
};
