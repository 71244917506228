import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "components/organisms";
import { DownloadFooter } from "components/molecules";
import { Members } from "./components";
import MembersFilterModal from "./components/Members/MembersFilterModal";

const meta = {
  title: "Holler Date | Browse Dates",
  description:
    "Browse for dates. Start listening to voice clips of potential matches.",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
}));

const Browse = ({ history }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Section>
        <Members history={history} />
      </Section>
      <MembersFilterModal />
      <DownloadFooter />
    </div>
  );
};

export default Browse;
