import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import axios from "axios";
import uiReducer from "store/reducers/ui";
import authReducer from "store/reducers/auth";
import membersReducer from "store/reducers/members";
import planReducer from "store/reducers/plan";
import chatReducer from "store/reducers/chat";
import configReducer from "store/reducers/configs";

import App from "./App";

// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : compose;

const composeEnhancers = compose;

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/holler-date-dev/us-central1/api"
    : "https://us-central1-holler-date-prod.cloudfunctions.net/api";

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  members: membersReducer,
  chat: chatReducer,
  plan: planReducer,
  configs: configReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
