import axios from "axios";
import isNumber from "is-number";
import { store, auth, EmailAuthProvider } from "shared/firebase";
import {
  authUIStartLoading,
  authUIStopLoading,
  changeUIStartLoading,
  changeUIStopLoading,
  registerUIStartLoading,
  registerUIStopLoading,
  resetUIStartLoading,
  resetUIStopLoading,
  setSnackBarMessage,
} from "./ui";
import { userSchema } from "store/utils/auth";
import { uploadImage, uploadAudio, deleteBlob } from "store/utils/storage";
import { SET_AUTHENTICATED_USER } from "store/types/authTypes";
import { createPlan, setPlan } from "./plans";
import { PRIMARY, SUCCESS, ERROR, INFO } from "constants/colors";
import {
  HERO_WELCOME_TITLE,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT_PENDING,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_ERROR,
  EMAIL_RESET_PASSWORD_SUCCESS,
  EMAIL_RESET_PASSWORD_ERROR,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_PENDING,
  PROFILE_UPDATE_ERROR,
  PROFILE_PHOTO_DELETE_PENDING,
  PROFILE_PHOTO_DELETE_ERROR,
  PROFILE_PHOTO_DELETE_SUCCESS,
  PROFILE_PHOTO_UPDATE_PENDING,
  PROFILE_PHOTO_UPDATE_SUCCESS,
  PROFILE_PHOTO_UPDATE_ERROR,
  PROFILE_AUDIO_DELETE_PENDING,
  PROFILE_AUDIO_DELETE_SUCCESS,
  PROFILE_AUDIO_DELETE_ERROR,
  PROFILE_AUDIO_UPDATE_PENDING,
  PROFILE_AUDIO_UPDATE_SUCCESS,
  PROFILE_AUDIO_UPDATE_ERROR,
  ACCOUNT_DELETE_PENDING,
  ACCOUNT_DELETE_ERROR,
  ACCOUNT_DELETE_SUCCESS,
  EMAIL_VERIFIED_SUCCESS,
  EMAIL_VERIFIED_ERROR,
} from "constants/messages";
import { HOME_ROUTE } from "../../constants/routes";

const INCOMPLETE = "INCOMPLETE";

const AuthToken = "AuthToken";
const profileCollection = store.collection("profiles");

export const signUp = (authData, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch(authUIStartLoading()); // disable auth related ui functions
      dispatch(registerUIStartLoading()); // we need this to disable onAuthStateChanged flow
      // const { email, password, firstName, lastName } = authData;
      const { email, password, displayName } = authData;
      const credential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const user = credential.user;
      await user.updateProfile({
        displayName: displayName,
      });
      await user.sendEmailVerification();
      const token = await user.getIdToken();
      setIdToken(token);
      dispatch(createPlan());
      const currentUser = auth.currentUser;
      const userDoc = userSchema(
        currentUser.uid,
        currentUser.displayName,
        null
      );
      await profileCollection.doc(currentUser.uid).set(userDoc);
      const profileDoc = await profileCollection.doc(currentUser.uid).get();
      if (profileDoc.exists) {
        const userData = { session: currentUser, profile: profileDoc.data() };
        dispatch(setAuthUser(userData));
        dispatch(
          setSnackBarMessage({
            message: HERO_WELCOME_TITLE,
            snackColor: PRIMARY,
            autoHideDuration: 6000,
          })
        );
      }
      dispatch(authUIStopLoading());
      dispatch(registerUIStopLoading());
    } catch (err) {
      dispatch(authUIStopLoading());
      dispatch(registerUIStopLoading());
      if (err && err.message) {
        dispatch(
          setSnackBarMessage({
            message: err.message,
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
        return;
      }
      dispatch(
        setSnackBarMessage({
          message: AUTH_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const signIn = (authData, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch(authUIStartLoading());
      const { email, password } = authData;
      const credential = await auth.signInWithEmailAndPassword(email, password);
      const user = credential.user;
      const token = await user.getIdToken();
      setIdToken(token);
      dispatch(createPlan());
      const profileDoc = await profileCollection.doc(user.uid).get();
      dispatch(authUIStopLoading());
      if (profileDoc.exists) {
        const profile = profileDoc.data();
        const userData = { session: user, profile: profile };
        dispatch(setAuthUser(userData));
        dispatch(
          setSnackBarMessage({
            message: AUTH_SUCCESS(user.displayName),
            snackColor: SUCCESS,
            autoHideDuration: 3000,
          })
        );
      } else {
        dispatch(setAuthUser(null));
        dispatch(
          setSnackBarMessage({
            message: AUTH_ERROR,
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
      }
    } catch (err) {
      dispatch(authUIStopLoading());
      if (err && err.message) {
        dispatch(
          setSnackBarMessage({
            message: err.message,
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
        return;
      }
      dispatch(
        setSnackBarMessage({
          message: AUTH_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const authenticated = (user) => {
  return async (dispatch) => {
    try {
      if (user) {
        const profileDoc = await profileCollection.doc(user.uid).get();
        if (profileDoc.exists) {
          const profile = profileDoc.data();
          const userData = { session: user, profile: profile };
          const token = await user.getIdToken();
          setIdToken(token);
          dispatch(createPlan());
          dispatch(setAuthUser(userData));
          dispatch(
            setSnackBarMessage({
              message: AUTH_SUCCESS(user.displayName),
              snackColor: SUCCESS,
              autoHideDuration: 3000,
            })
          );
        } else {
          dispatch(setAuthUser(null));
        }
      } else {
        dispatch(setAuthUser(null));
      }
    } catch (err) {
      console.error("error setting authenticated user", err);
    }
  };
};

export const signOut = () => {
  return async (dispatch) => {
    try {
      dispatch(
        setSnackBarMessage({
          message: AUTH_LOGOUT_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      dispatch(setPlan(null));
      dispatch(setAuthUser(null));
      dispatch(
        setSnackBarMessage({
          message: AUTH_LOGOUT_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
      await auth.signOut();
    } catch (err) {
      dispatch(
        setSnackBarMessage({
          message: AUTH_LOGOUT_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const sendResetInstructions = (email) => {
  return async (dispatch) => {
    try {
      dispatch(resetUIStartLoading());
      await auth.sendPasswordResetEmail(email);
      dispatch(
        setSnackBarMessage({
          message: EMAIL_RESET_PASSWORD_SUCCESS(email),
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
      dispatch(resetUIStopLoading());
    } catch (err) {
      dispatch(resetUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: EMAIL_RESET_PASSWORD_ERROR(email),
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const resetPassword = (passwordNew, oobCode) => {
  return async (dispatch) => {
    try {
      dispatch(changeUIStartLoading());
      await auth.verifyPasswordResetCode(oobCode);
      await auth.confirmPasswordReset(oobCode, passwordNew);
      dispatch(changeUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PASSWORD_RESET_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (err) {
      console.error("error reset password", JSON.stringify(err));
      dispatch(changeUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PASSWORD_RESET_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const resetPasswordReauthenticate = (passwordNew, passwordOld) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      if (!authUser || !passwordNew || !passwordOld) return;
      dispatch(changeUIStartLoading());
      const { session } = authUser;
      const { email } = session;
      const credential = EmailAuthProvider.credential(email, passwordOld);
      await session.reauthenticateWithCredential(credential);
      await session.updatePassword(passwordNew);
      dispatch(changeUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PASSWORD_RESET_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (err) {
      console.error("error reset password", JSON.stringify(err));
      dispatch(changeUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PASSWORD_RESET_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const verifyEmail = (oobCode, history) => {
  return async (dispatch) => {
    try {
      await auth.checkActionCode(oobCode);
      await auth.applyActionCode(oobCode);
      dispatch(
        setSnackBarMessage({
          message: EMAIL_VERIFIED_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
      history.replace(HOME_ROUTE);
    } catch (err) {
      console.error("error verifying email", JSON.stringify(err));
      dispatch(
        setSnackBarMessage({
          message: EMAIL_VERIFIED_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const profileUpdate = (profileData) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      dispatch(authUIStartLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_UPDATE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const { session } = authUser;
      const { uid } = session;
      const { displayName, demographic } = profileData;
      // Note: order is important, when you call session.updateProfile, there will be a call
      // to refresh the user object including the profile. If you do profile collections update
      // after session.updateProfile, the updated user object will not contain profile changes
      await profileCollection.doc(uid).update({ displayName, demographic });
      await session.updateProfile({
        displayName,
      });
      const profileDoc = await profileCollection.doc(session.uid).get();
      const userData = { session, profile: profileDoc.data() };
      dispatch(setAuthUser(userData)); // refresh the userData profile
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_UPDATE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (err) {
      console.log("err", err);
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_UPDATE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const profileImageUpload = (imageBlob) => {
  return async (dispatch, getState) => {
    try {
      if (imageBlob) {
        const authUser = getState().auth.authUser;
        const { session, profile } = authUser;
        const { uid } = session;
        dispatch(authUIStartLoading());
        dispatch(
          setSnackBarMessage({
            message: PROFILE_PHOTO_UPDATE_PENDING,
            snackColor: INFO,
            autoHideDuration: 3000,
          })
        );

        const uploadURL = await uploadImage(imageBlob, "profiles/images");
        if (profile.photoRef) {
          try {
            await deleteBlob(profile.photoRef);
          } catch (err) {
            console.error("failed to delete image", profile.photoRef);
          }
        }
        const { downloadURL, ref } = uploadURL;
        const updateData = {
          photoURL: downloadURL,
          photoRef: ref,
        };
        await profileCollection.doc(uid).update(updateData);
        await session.updateProfile({
          photoURL: updateData.photoURL,
        });
        const res = await axios.put("/members/autoApprove", {});
        const updatedProfile = res.data;
        const userData = { session, profile: updatedProfile };
        dispatch(setAuthUser(userData)); // refresh the userData profile
        dispatch(authUIStopLoading());
        dispatch(
          setSnackBarMessage({
            message: PROFILE_PHOTO_UPDATE_SUCCESS,
            snackColor: SUCCESS,
            autoHideDuration: 3000,
          })
        );
      }
    } catch (err) {
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_PHOTO_UPDATE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const profileImageDelete = () => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      dispatch(authUIStartLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_PHOTO_DELETE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const { session, profile } = authUser;
      const { uid } = session;
      if (profile.photoRef) {
        try {
          await deleteBlob(profile.photoRef);
        } catch (err) {
          console.error("failed to delete image", profile.photoRef);
        }
      }
      const updateData = {
        photoURL: null,
        photoRef: null,
        status: INCOMPLETE, // image delete will result in incomplete status
      };
      // Note: order is important, when you call session.updateProfile, there will be a call
      // to refresh the user object including the profile. If you do profile collections update
      // after session.updateProfile, the updated user object will not contain profile changes
      await profileCollection.doc(uid).update(updateData);
      await session.updateProfile({
        photoURL: null,
      });
      const profileDoc = await profileCollection.doc(session.uid).get();
      const userData = { session, profile: profileDoc.data() };
      dispatch(setAuthUser(userData)); // refresh the userData profile
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_PHOTO_DELETE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (err) {
      dispatch(authUIStopLoading());
      console.error("profile update error", err);
      dispatch(
        setSnackBarMessage({
          message: PROFILE_PHOTO_DELETE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const profileAudioUpload = (audioBlob, audioDuration) => {
  return async (dispatch, getState) => {
    try {
      if (audioBlob) {
        const authUser = getState().auth.authUser;
        const { session, profile } = authUser;
        const { uid } = session;
        dispatch(authUIStartLoading());
        dispatch(
          setSnackBarMessage({
            message: PROFILE_AUDIO_UPDATE_PENDING,
            snackColor: INFO,
            autoHideDuration: 3000,
          })
        );

        const uploadURL = await uploadAudio(audioBlob, "profiles/audios");
        if (profile.audioRef) {
          try {
            await deleteBlob(profile.audioRef);
          } catch (err) {
            console.error("failed to delete audio", profile.audioRef);
          }
        }
        const { downloadURL, ref } = uploadURL;
        const updateData = {
          audioURL: downloadURL,
          audioRef: ref,
          audioDuration: isNumber(audioDuration) ? audioDuration : 0,
        };

        await profileCollection.doc(uid).update(updateData);
        const res = await axios.put("/members/autoApprove", {});
        const updatedProfile = res.data;
        const userData = { session, profile: updatedProfile };
        dispatch(setAuthUser(userData)); // refresh the userData profile
        dispatch(authUIStopLoading());
        dispatch(
          setSnackBarMessage({
            message: PROFILE_AUDIO_UPDATE_SUCCESS,
            snackColor: SUCCESS,
            autoHideDuration: 3000,
          })
        );
      }
    } catch (err) {
      console.log("err", err);
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_AUDIO_UPDATE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const profileAudioDelete = () => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      dispatch(authUIStartLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_AUDIO_DELETE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const { session, profile } = authUser;
      const { uid } = session;
      if (profile.audioRef) {
        try {
          await deleteBlob(profile.audioRef);
        } catch (err) {
          console.error("failed to delete audio", profile.audioRef);
        }
      }
      const updateData = {
        audioURL: null,
        audioRef: null,
        status: INCOMPLETE,
      };
      // Note: order is important, when you call session.updateProfile, there will be a call
      // to refresh the user object including the profile. If you do profile collections update
      // after session.updateProfile, the updated user object will not contain profile changes
      await profileCollection.doc(uid).update(updateData);
      const profileDoc = await profileCollection.doc(session.uid).get();
      const userData = { session, profile: profileDoc.data() };
      dispatch(setAuthUser(userData)); // refresh the userData profile
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_AUDIO_DELETE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (err) {
      dispatch(authUIStopLoading());
      console.error("profile update error", err);
      dispatch(
        setSnackBarMessage({
          message: PROFILE_AUDIO_DELETE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const deleteAccount = (history) => {
  return async (dispatch, getState) => {
    try {
      dispatch(authUIStartLoading());
      dispatch(
        setSnackBarMessage({
          message: ACCOUNT_DELETE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const res = await axios.delete("/members/delete");
      const { status } = res.data;
      if (status) {
        dispatch(
          setSnackBarMessage({
            message: ACCOUNT_DELETE_SUCCESS,
            snackColor: SUCCESS,
            autoHideDuration: 3000,
          })
        );
        history.replace("/"); // navigate back to home route
        window.location.reload(); // clear dirty ui state
      } else {
        dispatch(
          setSnackBarMessage({
            message: ACCOUNT_DELETE_ERROR,
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
      }
    } catch (error) {
      dispatch(
        setSnackBarMessage({
          message: ACCOUNT_DELETE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
      console.error("profile deleting error", error);
    }
  };
};

export const setAuthUser = (authUser) => {
  if (!authUser) {
    removeIdToken();
  }
  return {
    type: SET_AUTHENTICATED_USER,
    authUser: authUser,
  };
};

export const getIdToken = () => {
  return localStorage.getItem(AuthToken);
};

const setIdToken = (token) => {
  localStorage.setItem(AuthToken, token);
  setAuthorizationHeader(token);
};

const removeIdToken = () => {
  localStorage.removeItem(AuthToken);
  unsetAuthorizationHeader();
};

const setAuthorizationHeader = (token) => {
  // console.log("Auth", `Bearer ${token}`);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const unsetAuthorizationHeader = () => {
  delete axios.defaults.headers.common.Authorization;
};
