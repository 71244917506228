import {
  GET_MEMBERS,
  RESET_MEMBERS,
  MEMBERS_SNAPSHOT,
  GET_MEMBERS_REQUESTS,
  RESET_MEMBERS_REQUESTS,
  MEMBERS_REQUESTS_SNAPSHOT,
  MEMBERS_MODERATE_SNAPSHOT,
  GET_MEMBERS_MATCHES,
  RESET_MEMBERS_MATCHES,
  RESET_MEMBERS_MODERATE,
  MEMBERS_MATCHES_SNAPSHOT,
  GET_MEMBERS_FILTER,
  GET_MEMBERS_MODERATE,
} from "store/types/membersTypes";

const initialState = {
  membersSnapshot: null,
  membersModerateSnapshot: null,
  membersRequestsSnapshot: null,
  membersMatchesSnapshot: null,
  members: {},
  membersRequests: {},
  membersMatches: {},
  membersModerate: {},
  membersFilter: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBERS:
      return {
        ...state,
        members: {
          ...state.members,
          ...action.members,
        },
      };
    case RESET_MEMBERS:
      return {
        ...state,
        members: {},
        membersFilter: {},
        membersSnapshot: null,
      };
    case MEMBERS_SNAPSHOT: {
      return {
        ...state,
        membersSnapshot: action.membersSnapshot,
      };
    }
    case GET_MEMBERS_MODERATE:
      return {
        ...state,
        membersModerate: {
          ...state.membersModerate,
          ...action.membersModerate,
        },
      };
    case RESET_MEMBERS_MODERATE:
      return {
        ...state,
        membersModerate: {},
        membersModerateSnapshot: null,
        resultsModerateEnd: false,
      };
    case MEMBERS_MODERATE_SNAPSHOT: {
      return {
        ...state,
        membersModerateSnapshot: action.membersModerateSnapshot,
      };
    }
    case GET_MEMBERS_REQUESTS:
      return {
        ...state,
        membersRequests: {
          ...state.membersRequests,
          ...action.membersRequests,
        },
      };
    case RESET_MEMBERS_REQUESTS:
      return {
        ...state,
        membersRequests: {},
        membersRequestsSnapshot: null,
      };
    case MEMBERS_REQUESTS_SNAPSHOT: {
      return {
        ...state,
        membersRequestsSnapshot: action.membersRequestsSnapshot,
      };
    }
    case GET_MEMBERS_MATCHES:
      return {
        ...state,
        membersMatches: {
          ...state.membersMatches,
          ...action.membersMatches,
        },
      };
    case RESET_MEMBERS_MATCHES:
      return {
        ...state,
        membersMatches: {},
        membersMatchesSnapshot: null,
      };
    case MEMBERS_MATCHES_SNAPSHOT: {
      return {
        ...state,
        membersMatchesSnapshot: action.membersMatchesSnapshot,
      };
    }
    case GET_MEMBERS_FILTER:
      return {
        ...state,
        membersFilter: action.membersFilter,
      };
    default:
      return state;
  }
};

export default reducer;
