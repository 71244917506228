import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  useMediaQuery,
  Grid,
  // Typography,
  // Divider,
  colors,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Image } from "components/atoms";
import { SectionHeader } from "components/molecules";
import { DOWNLOAD_SUBTITLE, DOWNLOAD_TITLE } from "constants/messages";
import { isMobileDevice } from "store/utils/ui";
import VideoPlayer from "./VideoPlayer";
import { APP_STORE_ROUTE, PLAY_STORE_ROUTE } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {},
  playStoreBtn: {
    maxWidth: 200,
  },
  cover: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  coverImg: {
    objectFit: "contain",
  },
  cardBase: {
    borderRadius: "35px",
    border: `2px solid ${colors.blueGrey[50]}`,
    maxWidth: 300,
  },
  dots: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(3, 0),
  },
  dot: {
    display: "block",
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: "100%",
    background: colors.grey[500],
    marginRight: theme.spacing(1),
    "&:last-child": {
      marginRight: 0,
    },
  },
  dotHighlighted: {
    background: colors.grey[900],
  },
  divider: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  highlight: {
    color: theme.palette.primary.main,
  },
}));

const YOUTUBE_LINK = "https://youtu.be/WLiflB9S9mo";

const Download = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div id="download" className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <Grid container alignItems="flex-start" justify="center">
            {!isMobileDevice(navigator) && (
              <VideoPlayer
                url={YOUTUBE_LINK}
                muted
                loop
                playing
                controls
                width={480}
              />
            )}

            {/*<CardBase className={classes.cardBase} withShadow liftUp>*/}
            {/*  <Image*/}
            {/*    src="/images/illustrations/travelers.svg"*/}
            {/*    alt="..."*/}
            {/*    // className={classes.cardImage}*/}
            {/*    lazy={false}*/}
            {/*  />*/}
            {/*  <div className={classes.dots}>*/}
            {/*    <span className={classes.dot} />*/}
            {/*    <span className={classes.dot} />*/}
            {/*    <span className={clsx(classes.dot, classes.dotHighlighted)} />*/}
            {/*    <span className={classes.dot} />*/}
            {/*  </div>*/}
            {/*  <Typography variant="h6">TRAVEL TOGETHER</Typography>*/}
            {/*  <Divider className={classes.divider} />*/}
            {/*</CardBase>*/}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          sm={6}
          data-aos="fade-up"
        >
          <SectionHeader
            title={
              <span>
                <span className={clsx("text-highlighted", classes.highlight)}>
                  Now available
                </span>{" "}
                {DOWNLOAD_TITLE}
              </span>
            }
            subtitle={DOWNLOAD_SUBTITLE}
            ctaGroup={[
              <a
                href={PLAY_STORE_ROUTE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="/images/mobile-addons/play-store.png"
                  alt="Download on Google Play"
                  className={classes.playStoreBtn}
                  lazy={false}
                />
              </a>,
              <a
                href={APP_STORE_ROUTE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="/images/mobile-addons/app-store.png"
                  alt="Download on App Store"
                  className={classes.playStoreBtn}
                  lazy={false}
                />
              </a>,
            ]}
            align={isMd ? "left" : "center"}
            disableGutter
            titleVariant="h3"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Download.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Download;
