import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import StepWizard from "react-step-wizard";
import { Grid } from "@material-ui/core";
import Preferences from "../Preferences";
import {
  genderPreferences,
  regionPreferences,
  agePreferences,
  imagePreferences,
  voicePreferences,
} from "views/Onboard/data";
import { useDispatch, useSelector } from "react-redux";
import {
  profileUpdate,
  profileImageDelete,
  profileImageUpload,
  profileAudioUpload,
  profileAudioDelete,
} from "store/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
  loadingIndicator: {
    margin: 8,
  },
}));

const Form = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const authUser = useSelector((state) => state.auth.authUser);
  const [onboardState, setOnboardState] = useState({});
  const handleUpdateProfile = (formStateValues) => {
    const updatedState = { ...onboardState, ...formStateValues };
    setOnboardState(updatedState);
    if (authUser) {
      const profileData = {
        displayName: authUser.profile.displayName,
        demographic: {
          ...authUser.profile.demographic,
          ...updatedState,
        },
      };
      dispatch(profileUpdate(profileData));
    }
  };

  const handleProfileImageUpdate = (blob) => {
    dispatch(profileImageUpload(blob));
  };

  const handleProfileImageDelete = () => {
    dispatch(profileImageDelete());
  };

  const handleProfileAudioUpdate = (blob, duration) => {
    if (blob) {
      dispatch(profileAudioUpload(blob.blob, duration));
    }
  };

  const handleProfileAudioDelete = (blob) => {
    dispatch(profileAudioDelete());
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StepWizard>
            <Preferences
              history={history}
              data={voicePreferences}
              submit={(formStateValues) => handleUpdateProfile(formStateValues)}
              audioUpload={(blob, duration) =>
                handleProfileAudioUpdate(blob, duration)
              }
              audioDelete={handleProfileAudioDelete}
              imageUpload={(blob) => handleProfileImageUpdate(blob)}
              imageDelete={handleProfileImageDelete}
            />
            <Preferences
              history={history}
              data={genderPreferences}
              submit={(formStateValues) => handleUpdateProfile(formStateValues)}
              audioUpload={(blob, duration) =>
                handleProfileAudioUpdate(blob, duration)
              }
              audioDelete={handleProfileAudioDelete}
              imageUpload={(blob) => handleProfileImageUpdate(blob)}
              imageDelete={handleProfileImageDelete}
            />
            <Preferences
              history={history}
              data={regionPreferences()}
              submit={(formStateValues) => handleUpdateProfile(formStateValues)}
              audioUpload={(blob, duration) =>
                handleProfileAudioUpdate(blob, duration)
              }
              audioDelete={handleProfileAudioDelete}
              imageUpload={(blob) => handleProfileImageUpdate(blob)}
              imageDelete={handleProfileImageDelete}
            />
            <Preferences
              history={history}
              data={agePreferences}
              submit={(formStateValues) => handleUpdateProfile(formStateValues)}
              audioUpload={(blob, duration) =>
                handleProfileAudioUpdate(blob, duration)
              }
              audioDelete={handleProfileAudioDelete}
              imageUpload={(blob) => handleProfileImageUpdate(blob)}
              imageDelete={handleProfileImageDelete}
            />
            <Preferences
              history={history}
              data={imagePreferences}
              submit={(formStateValues) => handleUpdateProfile(formStateValues)}
              audioUpload={(blob) => handleProfileAudioUpdate(blob)}
              audioDelete={handleProfileAudioDelete}
              imageUpload={(blob) => handleProfileImageUpdate(blob)}
              imageDelete={handleProfileImageDelete}
            />
          </StepWizard>
        </Grid>
      </Grid>
    </div>
  );
};

Form.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Form);
