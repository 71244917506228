import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  CircularProgress,
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Divider,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordReauthenticate, signOut } from "store/actions/auth";
import { withRouter } from "react-router-dom";
import { HOME_ROUTE } from "constants/routes";
import validate from "validate.js";
import isEqual from "lodash.isequal";

const useStyles = makeStyles((theme) => ({
  root: {},
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  switchTitle: {
    fontWeight: 700,
  },
  titleCta: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mismatch: {
    marginTop: 0,
    color: theme.palette.error.main,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
  loadingIndicator: {
    margin: 8,
  },
}));

const schema = {
  oldPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
  },
  passwordConfirm: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
  },
};

const Security = (props) => {
  const { className, history, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const changeIsLoading = useSelector((state) => state.ui.changeIsLoading);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const handleSignOut = () => {
    dispatch(signOut());
    history.replace(HOME_ROUTE);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [mismatch, setMismatch] = useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    const { password, passwordConfirm } = formState.values;
    if (!errors && !isEqual(password, passwordConfirm)) {
      setMismatch(true);
    } else {
      setMismatch(false);
    }

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.isValid) {
      const { values } = formState;
      const { oldPassword, password, passwordConfirm } = values;
      if (!isEqual(password, passwordConfirm)) {
        setMismatch(true);
      } else {
        dispatch(resetPasswordReauthenticate(password, oldPassword));
      }
    }
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <div className={classes.titleCta}>
            <Typography variant="h6" color="textPrimary">
              Change Password
            </Typography>
            <Button variant="outlined" color="primary" onClick={handleSignOut}>
              Log out
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Current password
          </Typography>
          <TextField
            placeholder="Old password"
            variant="outlined"
            size="medium"
            name="oldPassword"
            fullWidth
            type="password"
            helperText={
              hasError("oldPassword") ? formState.errors.oldPassword[0] : null
            }
            error={hasError("oldPassword")}
            onChange={handleChange}
            value={formState.values.oldPassword || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            New password
          </Typography>
          <TextField
            placeholder="New password"
            variant="outlined"
            size="medium"
            name="password"
            fullWidth
            type="password"
            helperText={
              hasError("password") ? formState.errors.password[0] : null
            }
            error={hasError("password")}
            onChange={handleChange}
            value={formState.values.password || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Confirm password
          </Typography>
          <TextField
            placeholder="Confirm password"
            variant="outlined"
            size="medium"
            name="passwordConfirm"
            fullWidth
            type="password"
            helperText={
              hasError("passwordConfirm")
                ? formState.errors.passwordConfirm[0]
                : null
            }
            error={hasError("passwordConfirm")}
            onChange={handleChange}
            value={formState.values.passwordConfirm || ""}
          />
        </Grid>
        {mismatch && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.mismatch}>
              New and Confirm passwords do not match.
            </Typography>
          </Grid>
        )}
        {/*<Grid item xs={12}>*/}
        {/*  <Divider />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*  <FormControlLabel*/}
        {/*    control={<Switch color="primary" defaultChecked />}*/}
        {/*    label={*/}
        {/*      <Typography*/}
        {/*        variant="subtitle1"*/}
        {/*        color="textPrimary"*/}
        {/*        className={classes.switchTitle}*/}
        {/*      >*/}
        {/*        Public Profile*/}
        {/*      </Typography>*/}
        {/*    }*/}
        {/*    labelPlacement="end"*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*  <Divider />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*  <FormControlLabel*/}
        {/*    control={<Switch color="primary" />}*/}
        {/*    label={*/}
        {/*      <Typography*/}
        {/*        variant="subtitle1"*/}
        {/*        color="textPrimary"*/}
        {/*        className={classes.switchTitle}*/}
        {/*      >*/}
        {/*        Expose your email*/}
        {/*      </Typography>*/}
        {/*    }*/}
        {/*    labelPlacement="end"*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {!changeIsLoading ? (
          <Grid item container justify="flex-start" xs={12}>
            <Button
              disabled={!formState.isValid || mismatch}
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              onClick={handleSubmit}
            >
              save
            </Button>
          </Grid>
        ) : (
          <Grid item container justify="flex-start" xs={12}>
            <CircularProgress
              className={classes.loadingIndicator}
              color="primary"
              size={30}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

Security.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default withRouter(Security);
