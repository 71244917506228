/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ACCOUNT, BROWSE_DATES, LOGIN, REGISTER } from "constants/messages";
import {
  ACCOUNT_GENERAL_ROUTE,
  BROWSE_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
} from "constants/routes";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  navLink: {
    fontWeight: 300,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  listItemIcon: {
    minWidth: "auto",
  },
  closeIcon: {
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  menu: {
    display: "flex",
  },
  menuItem: {
    marginRight: theme.spacing(8),
    "&:last-child": {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: "uppercase",
  },
  divider: {
    width: "100%",
  },
}));

const SidebarNav = (props) => {
  const { pages, onClose, className, history, ...rest } = props;
  const classes = useStyles();
  const authUser = useSelector((state) => state.auth.authUser);

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.closeIcon} onClick={onClose}>
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography
          variant="h6"
          color="primary"
          component="a"
          target="blank"
          href={BROWSE_ROUTE}
        >
          {BROWSE_DATES}
        </Typography>
      </ListItem>
      {!authUser ? (
        <>
          <ListItem className={classes.listItem}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              component="a"
              target="blank"
              href={LOGIN_ROUTE}
            >
              {LOGIN}
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              component="a"
              target="blank"
              href={REGISTER_ROUTE}
            >
              {REGISTER}
            </Button>
          </ListItem>
        </>
      ) : (
        <>
          <ListItem className={classes.listItem}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              component="a"
              target="blank"
              href={ACCOUNT_GENERAL_ROUTE}
            >
              {ACCOUNT}
            </Button>
          </ListItem>
        </>
      )}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default withRouter(SidebarNav);
