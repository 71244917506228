import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Box, List, ListItem, Grid, Typography } from "@material-ui/core";
import { SectionAlternate, CardBase } from "components/organisms";
import {
  Hero,
  General,
  Security,
  Moderate,
  Matches,
  MatchRequests,
} from "./components";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  section: {
    "& .section-alternate__content": {
      paddingTop: 0,
      marginTop: theme.spacing(-5),
      position: "relative",
      zIndex: 1,
    },
    "& .card-base__content": {
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3),
      },
    },
  },
  menu: {
    height: "auto",
  },
  list: {
    display: "inline-flex",
    overflow: "auto",
    flexWrap: "nowrap",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      marginRight: theme.spacing(-3),
      marginLeft: theme.spacing(-3),
    },
  },
  listItem: {
    marginRight: theme.spacing(2),
    flex: 0,
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      borderLeft: "2px solid transparent",
    },
  },
  listItemActive: {
    [theme.breakpoints.up("md")]: {
      borderLeft: `2px solid ${theme.palette.primary.dark}`,
    },
    "& .menu__item": {
      color: theme.palette.text.primary,
    },
  },
}));

const subPages = [
  {
    id: "general",
    href: "/account/general",
    title: "General",
  },
  {
    id: "security",
    href: "/account/security",
    title: "Security",
  },
  {
    id: "matches",
    href: "/account/matches",
    title: "My Matches",
  },
  {
    id: "match_requests",
    href: "/account/match_requests",
    title: "Match Requests",
  },
];

const adminPages = [
  ...subPages,
  {
    id: "moderate",
    href: "/account/moderate",
    title: "Moderate Members",
  },
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box component="div" hidden={value !== index} {...other}>
      {value === index && children}
    </Box>
  );
};

const Account = () => {
  const classes = useStyles();
  const authUser = useSelector((state) => state.auth.authUser);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (authUser) {
      const { profile } = authUser;
      if (profile.type === "admin") {
        setIsAdmin(true);
      }
    }
  }, [authUser]);

  let { pageId } = useParams();
  if (!pageId) {
    pageId = "general";
  }

  return (
    <div className={classes.root}>
      <Hero />
      <SectionAlternate className={classes.section}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <CardBase withShadow align="left" className={classes.menu}>
              <List disablePadding className={classes.list}>
                {(isAdmin ? adminPages : subPages).map((item, index) => (
                  <ListItem
                    key={index}
                    component={Link}
                    to={item.href}
                    className={clsx(
                      classes.listItem,
                      pageId === item.id ? classes.listItemActive : {}
                    )}
                    disableGutters
                  >
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color="textSecondary"
                      className="menu__item"
                    >
                      {item.title}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </CardBase>
          </Grid>
          <Grid item xs={12} md={9}>
            <CardBase withShadow align="left">
              <TabPanel value={pageId} index={"general"}>
                <General />
              </TabPanel>
              <TabPanel value={pageId} index={"security"}>
                <Security />
              </TabPanel>
              <TabPanel value={pageId} index={"matches"}>
                <Matches />
              </TabPanel>
              <TabPanel value={pageId} index={"match_requests"}>
                <MatchRequests />
              </TabPanel>
              {isAdmin && (
                <TabPanel value={pageId} index={"moderate"}>
                  <Moderate />
                </TabPanel>
              )}
            </CardBase>
          </Grid>
        </Grid>
      </SectionAlternate>
    </div>
  );
};

export default Account;
