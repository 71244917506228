import React, { forwardRef, useEffect, useState } from "react";
import isEmpty from "lodash.isempty";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import { FILTER_APPLY, FILTER_TITLE } from "constants/messages";
import { useDispatch, useSelector } from "react-redux";
import { setFilterModal } from "store/actions/ui";
import { MenuItem, TextField } from "@material-ui/core";
import {
  FILTER_GENDER_PLACEHOLDER,
  FILTER_GENDER_LABEL,
  FILTER_COUNTRY_PLACEHOLDER,
  FILTER_COUNTRY_LABEL,
} from "constants/messages";
import { formatCountryOptions } from "shared/config/ui";
import Button from "@material-ui/core/Button";
import {
  getPaginatedMembers,
  resetMembers,
  setMembersFilter,
} from "store/actions/members";
import { APPROVED } from "constants/auth";

const genderOptions = {
  male: { title: "Man", code: "male" },
  female: { title: "Woman", code: "female" },
};

const useStyles = makeStyles((theme) => ({
  root: {},
  countryOptions: {
    marginTop: 16,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MembersFilterModal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [countryRegions, setCountryRegions] = useState(formatCountryOptions());
  const [genderPreference, setGenderPreference] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const filterDisplay = useSelector((state) => state.ui.filterDisplay);
  const handleClose = () => {
    dispatch(setFilterModal(false));
  };
  const handleApplyFilter = () => {
    if (countryCode || !isEmpty(genderPreference)) {
      const appliedFilter = {};
      if (countryCode) {
        appliedFilter.country = countryCode;
      }
      if (genderPreference) {
        appliedFilter.gender = genderPreference;
      }
      dispatch(resetMembers());
      dispatch(setMembersFilter(appliedFilter));
      dispatch(getPaginatedMembers(6, APPROVED));
    }
    dispatch(setFilterModal(false));
  };

  return (
    <Dialog
      open={filterDisplay}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      // disableBackdropClick
    >
      <DialogTitle>{FILTER_TITLE}</DialogTitle>
      <DialogContent>
        <TextField
          select
          placeholder={FILTER_GENDER_PLACEHOLDER}
          label={FILTER_GENDER_LABEL}
          variant="outlined"
          size="medium"
          name="genderPreference"
          value={genderPreference}
          fullWidth
          onChange={(event) => {
            setGenderPreference(event.target.value);
          }}
        >
          {Object.keys(genderOptions).map((optionKey) => {
            const option = genderOptions[optionKey];
            const { title, code } = option;
            return (
              <MenuItem key={code} value={code}>
                {title}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          select
          placeholder={FILTER_COUNTRY_PLACEHOLDER}
          label={FILTER_COUNTRY_LABEL}
          variant="outlined"
          size="medium"
          name="country"
          className={classes.countryOptions}
          value={countryCode}
          fullWidth
          onChange={(event) => {
            setCountryCode(event.target.value);
          }}
        >
          {Object.keys(countryRegions).map((code) => {
            const regionData = countryRegions[code];
            const { title } = regionData;
            return (
              <MenuItem key={code} value={code}>
                {title}
              </MenuItem>
            );
          })}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleApplyFilter} color="secondary">
          {FILTER_APPLY}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MembersFilterModal;
