import uuid from "uuid-v4";
import { storage } from "shared/firebase";

export const uploadAudio = (blob, refPath) => {
  return new Promise(async (resolve, reject) => {
    try {
      const filename = `${uuid()}.mp3`; // Generate unique name
      console.log("filename", filename);
      const bucketRef = storage.ref(`${refPath}/${filename}`);
      await bucketRef.put(blob);
      const downloadURL = await bucketRef.getDownloadURL();
      resolve({ downloadURL, ref: bucketRef.fullPath });
    } catch (err) {
      reject(err);
    }
  });
};

export const deleteBlob = (path) => {
  return new Promise(async (resolve, reject) => {
    try {
      const bucketRef = storage.ref(path);
      await bucketRef.delete();
      resolve(path);
    } catch (err) {
      reject(err);
    }
  });
};

export const uploadImage = (blob, refPath) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { type } = blob;
      let metaData = { contentType: type };
      let ext = "jpg";
      if (type === "image/png") {
        ext = "png";
      }
      // const ext = blob.name.split(".").pop(); // Extract image extension
      const filename = `${uuid()}.${ext}`; // Generate unique name
      const bucketRef = storage.ref(`${refPath}/${filename}`);
      await bucketRef.put(blob, metaData);
      const downloadURL = await bucketRef.getDownloadURL();
      resolve({ downloadURL, ref: bucketRef.fullPath });
    } catch (err) {
      console.log("upload err", err);
      reject(err);
    }
  });
};

export const uploadImageString = (imageDataUrl, refPath) => {
  return new Promise(async (resolve, reject) => {
    try {
      let metaData = { contentType: "image/jpeg" };
      const tokens = imageDataUrl.split(";");
      let ext = "jpg";
      if (tokens[0] !== "data:image/jpeg" && tokens[0] !== "data:image/png") {
        reject("Invalid file format");
      }
      // if (tokens[0] === "data:image/jpeg") {
      //   ext = "jpg";
      // }
      if (tokens[0] === "data:image/png") {
        ext = "png";
        metaData = { contentType: "image/png" };
      }
      const filename = `${uuid()}.${ext}`; // Generate unique name
      const bucketRef = storage.ref(`${refPath}/${filename}`);
      await bucketRef.putString(imageDataUrl, "data_url", metaData);
      const downloadURL = await bucketRef.getDownloadURL();
      resolve({ downloadURL, ref: bucketRef.fullPath });
    } catch (err) {
      console.log("upload err", err);
      reject(err);
    }
  });
};
