import { formatCountryOptions } from "shared/config/ui";
import {
  SELECT_TYPE,
  NUMERIC_TYPE,
  IMAGE_TYPE,
  AUDIO_TYPE,
} from "constants/ui";
import { RECORD_AUDIO, RECORD_STOP_AUDIO } from "constants/messages";

export const genderPreferences = [
  {
    title: "I am interested in <select gender preference>...",
    type: SELECT_TYPE,
    label: "Gender preference *",
    placeholder: "Gender preference",
    name: "genderPreference",
    options: {
      male: { title: "Man", code: "male" },
      female: { title: "Woman", code: "female" },
    },
    schema: {
      genderPreference: {
        presence: { allowEmpty: false, message: "is required" },
        length: {
          maximum: 120,
        },
      },
    },
  },
  {
    title: "My gender is...",
    type: SELECT_TYPE,
    label: "Your gender *",
    placeholder: "Your gender",
    name: "gender",
    options: {
      male: { title: "Man", code: "male" },
      female: { title: "Woman", code: "female" },
    },
    schema: {
      gender: {
        presence: { allowEmpty: false, message: "is required" },
        length: {
          maximum: 120,
        },
      },
    },
  },
];

export const regionPreferences = () => {
  return [
    {
      title: "Which country are you from?",
      type: SELECT_TYPE,
      label: "Your country *",
      placeholder: "Your country",
      name: "country",
      options: formatCountryOptions(),
      schema: {
        country: {
          presence: { allowEmpty: false, message: "is required" },
          length: {
            maximum: 120,
          },
        },
      },
    },
  ];
};

export const agePreferences = [
  {
    title: "What is your age?",
    type: NUMERIC_TYPE,
    label: "Your age (between 18 to 90) *",
    placeholder: "Your age (between 18 to 90)",
    name: "age",
    options: null,
    schema: {
      age: {
        presence: { allowEmpty: false, message: "is required" },
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 18,
          lessThanOrEqualTo: 90,
        },
      },
    },
  },
];

export const voicePreferences = [
  {
    title:
      "Record a 15 seconds voice clip 🎤 introducing yourself. Press record audio to get started!",
    secondaryTitle:
      "You're on air 😎. Say something! Sing, tell a story or share anything interesting!",
    type: AUDIO_TYPE,
    label: "",
    placeholder: "",
    name: "audio",
    required: true,
    options: {
      audio: {
        title: RECORD_AUDIO,
        inverseTitle: RECORD_STOP_AUDIO,
        code: "audio",
      },
    },
    schema: null,
  },
];

export const imagePreferences = [
  {
    title: "Upload a profile picture of yourself.",
    subtitle:
      "(Your photo will only be shown if someone favourites your voice clip)",
    type: IMAGE_TYPE,
    label: "",
    placeholder: "",
    name: "image",
    required: true,
    options: {
      selfie: { title: "Take selfie", code: "selfie" },
      album: { title: "Choose from album", code: "album" },
    },
    schema: null,
  },
];
