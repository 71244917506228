import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { TopBar, Footer, Sidebar } from "./components";
import { ConsecutiveSnackbars } from "components/molecules";
import {
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  RESET_ROUTE,
  TERMS_ROUTE,
  PRIVACY_ROUTE,
  RULES_ROUTE,
  PRICING_ROUTE,
  FAQ_ROUTE,
  CONTACT_ROUTE,
} from "constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "shared/firebase";
import { authenticated } from "store/actions/auth";
import {
  LOGIN,
  PLAN_UPDATED,
  REGISTER,
  RESET_PASSWORD,
} from "constants/messages";
import { queryPlan, setPlan } from "store/actions/plans";
import { setSnackBarMessage } from "store/actions/ui";
import { SUCCESS } from "constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));

const Main = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const authUser = useSelector((state) => state.auth.authUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      dispatch(authenticated(authUser));
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  useEffect(() => {
    let unSubPlan;
    if (authUser) {
      const query = queryPlan(authUser.session.uid);
      unSubPlan = query?.onSnapshot((snapshot) => {
        snapshot?.docChanges()?.forEach((change) => {
          if (change.type === "modified") {
            const plan = change.doc.data();
            const { pointsLimit } = plan;
            dispatch(setPlan(plan));
            dispatch(
              setSnackBarMessage({
                message: PLAN_UPDATED(pointsLimit),
                snackColor: SUCCESS,
                autoHideDuration: 2000,
              })
            );
          }
        });
      });
    }
    return () => {
      if (unSubPlan) {
        unSubPlan();
      }
    };
  }, [authUser, dispatch]);

  const pages = {
    tnc: {
      title: "Terms and Conditions",
      id: "tnc-pages",
      children: {
        tnc: {
          groupTitle: "Terms",
          pages: [
            {
              title: "Pricing",
              href: PRICING_ROUTE,
            },
            {
              title: "Terms ",
              href: TERMS_ROUTE,
            },
            {
              title: "Privacy Policy",
              href: PRIVACY_ROUTE,
            },
            {
              title: "Community Rules",
              href: RULES_ROUTE,
            },
          ],
        },
      },
    },
    contact: {
      title: "Contact",
      id: "contact-pages",
      children: {
        contact: {
          groupTitle: "Contact",
          pages: [
            {
              title: "Contact Us",
              href: CONTACT_ROUTE,
            },
            {
              title: "FAQs",
              href: FAQ_ROUTE,
            },
          ],
        },
      },
    },
    account: {
      title: "Account",
      id: "account-pages",
      children: {
        account: {
          groupTitle: "Account",
          pages: authUser
            ? [
                {
                  title: RESET_PASSWORD,
                  href: RESET_ROUTE,
                },
              ]
            : [
                {
                  title: REGISTER,
                  href: REGISTER_ROUTE,
                },
                {
                  title: LOGIN,
                  href: LOGIN_ROUTE,
                },
                {
                  title: RESET_PASSWORD,
                  href: RESET_ROUTE,
                },
              ],
        },
      },
    },
  };

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <TopBar onSidebarOpen={handleSidebarOpen} pages={pages} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>{children}</main>
      <Footer pages={pages} />
      <ConsecutiveSnackbars />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
