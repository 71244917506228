import { colors } from "@material-ui/core";
import { CONTACT_ROUTE, PRICING_ROUTE, RULES_ROUTE } from "constants/routes";

export const questions = {
  title: "Frequently asked questions",
  subtitle: "Please find our most frequently asked questions.",
  icon: "fas fa-question",
  color: colors.pink,
  lastUpdate: "Updated on 20th Feb, 2021",
  items: [
    {
      id: "faq-1",
      title: "What is Holler Date about?",
      subtitle: "Find out more about holler date.",
      text: "Holler Date is a voice dating platform that allows participants to upload 15 seconds voice clips introducing themselves to prospective dates. Photos are only revealed when a user selects the reveal option for a voice clip. A reveal action costs 1 reveal point.",
      link: "Contact support team for more questions",
      href: CONTACT_ROUTE,
    },
    {
      id: "faq-2",
      title: "What are match points?",
      subtitle: "Find out how more about match points.",
      text: "To ensure genuine interests and matches in voice clips, we imposed a daily free limit on the number of reveals and matches you can perform per day. This helps preserves the anonymity aspect of the site and ensure photos are only revealed to dates who are genuinely interested in your voice clip. However, we do offer a pricing model where serious daters can purchase more match points. Check out our pricing page for more information.",
      link: "View our pricing page for more details",
      href: PRICING_ROUTE,
    },
    {
      id: "faq-3",
      title: "Is Holler Date free to use?",
      subtitle: "Find out about Holler Date pricing model.",
      text: "Yes, Holler Date is free to sign up and use. However, we do have a pricing model that will be applicable if you wish to purchase more match points. Check out our pricing page for more information.",
      link: "See our pricing page for more information",
      href: PRICING_ROUTE,
    },
    {
      id: "faq-4",
      title: "Can I omit my voice clip or photo?",
      subtitle: "Account related information.",
      text: "Yes you can. However, your account will be deemed incomplete and you will not be able to send match requests. In addition, your profile will also not be visible to others.",
      link: "Contact support team for more questions",
      href: CONTACT_ROUTE,
    },
    {
      id: "faq-5",
      title: "How can I start chatting with other participants?",
      subtitle: "Match related information.",
      text: "You will need to send a match request to the person you're keen to chat with. The person will have to accept your match request in order for the chat option to appear. You can start chatting with your dates in the Matches section of your profile page.",
      link: "Contact support team for more questions",
      href: CONTACT_ROUTE,
    },
    {
      id: "faq-6",
      title: "Can I delete my account?",
      subtitle: "Account deletion information.",
      text: "Absolutely. You will always remain in complete control of your account information. You can delete your account in the General section of your profile page. This removes all associated data of your account on our system.",
      link: "Contact support team for more questions",
      href: CONTACT_ROUTE,
    },
    {
      id: "faq-7",
      title: "Is there an age limit during registration?",
      subtitle: "Age limit and terms of use.",
      text: "Holler Date is strictly meant for adults aged 18 and above. We strictly do not allow minors to use this site and actively scout our members list to ensure no minors are on our platform. Please read our Privacy Policy and Terms of service for more information.",
      link: "Contact support team for more questions",
      href: CONTACT_ROUTE,
    },
    {
      id: "faq-8",
      title: "Is there a code of conduct on Holler Date?",
      subtitle: "Community rules and code of conduct.",
      text: "Absolutely. Holler Date is strictly meant for people who are genuinely looking to find love. We do not allow any hookups, escort services, abusive material, illegal content or anything sexual on our platform. Violators will face account suspension, and in serious cases, we will refer the case to our local authorities for their investigation. Please see our Community Rules for more information.",
      link: "See our community rules for more information",
      href: RULES_ROUTE,
    },
  ],
};
