export const partners = [
  {
    name: "Airbnb",
    logo: "/images/logos/airbnb.svg",
  },
  {
    name: "Coinbase",
    logo: "/images/logos/coinbase.svg",
  },
  {
    name: "Dribbble",
    logo: "/images/logos/dribbble.svg",
  },
  {
    name: "Instagram",
    logo: "/images/logos/instagram.svg",
  },
  {
    name: "Netflix",
    logo: "/images/logos/netflix.svg",
  },
  {
    name: "Pinterest",
    logo: "/images/logos/pinterest.svg",
  },
];

export const advantages = [
  {
    icon: "fas fa-microphone",
    title: "Integrated audio",
    subtitle:
      "Use our audio tool to submit a voice recording introducing yourself. Or browse through voice clips of other prospective dates. ",
  },
  {
    icon: "fas fa-camera",
    title: "Verified photos",
    subtitle:
      "Authenticity means everything to us. All members have to upload a profile photo showing their faces clearly. We'll make this process seamless for you.",
  },
  {
    icon: "fab fa-gratipay",
    title: "Voice first, looks second",
    subtitle:
      'Your profile photo is shown only when someone chooses to "reveal" your photo after hearing your clip. To prevent frivolous reveals, we impose a daily "reveal" limit.',
  },
  {
    icon: "fas fa-comments",
    title: "Chat support",
    subtitle:
      "Use our chat feature to exchange messages. Exchange contacts only when you're both comfortable with each other.",
  },
];

export const locations = [
  {
    images: [
      {
        src: "/images/photos/coworking/place1.jpg",
        srcSet: "/images/photos/coworking/place1@2x.jpg 2x",
        alt: "...",
      },
      {
        src: "/images/photos/coworking/place2.jpg",
        srcSet: "/images/photos/coworking/place2@2x.jpg 2x",
        alt: "...",
      },
      {
        src: "/images/photos/coworking/place3.jpg",
        srcSet: "/images/photos/coworking/place3@2x.jpg 2x",
        alt: "...",
      },
    ],
    title: "Soho Square",
    address: "Via E. Gola 4, 20147 Milan, Italy",
    price: "$980 / month",
    reviews: [
      {
        authorPhoto: {
          src: "/images/photos/people/veronica-adams.jpg",
          srcSet: "/images/photos/people/veronica-adams@2x.jpg 2x",
        },
        authorName: "Veronica Adams",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/akachi-luccini.jpg",
          srcSet: "/images/photos/people/akachi-luccini@2x.jpg 2x",
        },
        authorName: "Akachi Luccini",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/jack-smith.jpg",
          srcSet: "/images/photos/people/jack-smith@2x.jpg 2x",
        },
        authorName: "Jack Smith",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/kate-segelson.jpg",
          srcSet: "/images/photos/people/kate-segelson@2x.jpg 2x",
        },
        authorName: "Kate Segelson",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/alex-johnson.jpg",
          srcSet: "/images/photos/people/alex-johnson@2x.jpg 2x",
        },
        authorName: "Alex Johnson",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/valeria-kogan.jpg",
          srcSet: "/images/photos/people/valeria-kogan@2x.jpg 2x",
        },
        authorName: "Valeria Kogan",
      },
    ],
    score: "5.0",
    reviewCount: 28,
  },
  {
    images: [
      {
        src: "/images/photos/coworking/place2.jpg",
        srcSet: "/images/photos/coworking/place2@2x.jpg 2x",
        alt: "...",
      },
      {
        src: "/images/photos/coworking/place1.jpg",
        srcSet: "/images/photos/coworking/place1@2x.jpg 2x",
        alt: "...",
      },
      {
        src: "/images/photos/coworking/place3.jpg",
        srcSet: "/images/photos/coworking/place3@2x.jpg 2x",
        alt: "...",
      },
    ],
    title: "Moose Lab",
    address: "Via E. Gola 4, 20147 Milan, Italy",
    price: "$980 / month",
    reviews: [
      {
        authorPhoto: {
          src: "/images/photos/people/veronica-adams.jpg",
          srcSet: "/images/photos/people/veronica-adams@2x.jpg 2x",
        },
        authorName: "Veronica Adams",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/akachi-luccini.jpg",
          srcSet: "/images/photos/people/akachi-luccini@2x.jpg 2x",
        },
        authorName: "Akachi Luccini",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/jack-smith.jpg",
          srcSet: "/images/photos/people/jack-smith@2x.jpg 2x",
        },
        authorName: "Jack Smith",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/kate-segelson.jpg",
          srcSet: "/images/photos/people/kate-segelson@2x.jpg 2x",
        },
        authorName: "Kate Segelson",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/alex-johnson.jpg",
          srcSet: "/images/photos/people/alex-johnson@2x.jpg 2x",
        },
        authorName: "Alex Johnson",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/valeria-kogan.jpg",
          srcSet: "/images/photos/people/valeria-kogan@2x.jpg 2x",
        },
        authorName: "Valeria Kogan",
      },
    ],
    score: "4.9",
    reviewCount: 38,
  },
  {
    images: [
      {
        src: "/images/photos/coworking/place3.jpg",
        srcSet: "/images/photos/coworking/place3@2x.jpg 2x",
        alt: "...",
      },
      {
        src: "/images/photos/coworking/place2.jpg",
        srcSet: "/images/photos/coworking/place2@2x.jpg 2x",
        alt: "...",
      },
      {
        src: "/images/photos/coworking/place1.jpg",
        srcSet: "/images/photos/coworking/place1@2x.jpg 2x",
        alt: "...",
      },
    ],
    title: "Tenoha Space",
    address: "Via E. Gola 4, 20147 Milan, Italy",
    price: "$980 / month",
    reviews: [
      {
        authorPhoto: {
          src: "/images/photos/people/veronica-adams.jpg",
          srcSet: "/images/photos/people/veronica-adams@2x.jpg 2x",
        },
        authorName: "Veronica Adams",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/akachi-luccini.jpg",
          srcSet: "/images/photos/people/akachi-luccini@2x.jpg 2x",
        },
        authorName: "Akachi Luccini",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/jack-smith.jpg",
          srcSet: "/images/photos/people/jack-smith@2x.jpg 2x",
        },
        authorName: "Jack Smith",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/kate-segelson.jpg",
          srcSet: "/images/photos/people/kate-segelson@2x.jpg 2x",
        },
        authorName: "Kate Segelson",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/alex-johnson.jpg",
          srcSet: "/images/photos/people/alex-johnson@2x.jpg 2x",
        },
        authorName: "Alex Johnson",
      },
      {
        authorPhoto: {
          src: "/images/photos/people/valeria-kogan.jpg",
          srcSet: "/images/photos/people/valeria-kogan@2x.jpg 2x",
        },
        authorName: "Valeria Kogan",
      },
    ],
    score: "5.0",
    reviewCount: 18,
  },
];

export const properties = [
  {
    title: "24/7 concierge",
    icon: "fas fa-phone-alt",
  },
  {
    title: "Coworking spaces",
    icon: "fas fa-users",
  },
  {
    title: "Shared spaces",
    icon: "fas fa-couch",
  },
  {
    title: "Room cleaning",
    icon: "fas fa-pump-soap",
  },
  {
    title: "Gym membership",
    icon: "fas fa-dumbbell",
  },
  {
    title: "Super fast WiFi",
    icon: "fas fa-wifi",
  },
  {
    title: "Washing machines & dryers",
    icon: "fas fa-soap",
  },
  {
    title: "Cultural events programme",
    icon: "fas fa-calendar-week",
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: "/images/photos/people/veronica-adams.jpg",
      srcSet: "/images/photos/people/veronica-adams@2x.jpg 2x",
    },
    authorName: "Veronica Adams",
    authorOccupation: "Growth Marketer, Crealytics",
    feedback:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    authorPhoto: {
      src: "/images/photos/people/akachi-luccini.jpg",
      srcSet: "/images/photos/people/akachi-luccini@2x.jpg 2x",
    },
    authorName: "Akachi Luccini",
    authorOccupation: "Lead Generation, Alternative Capital",
    feedback:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    authorPhoto: {
      src: "/images/photos/people/jack-smith.jpg",
      srcSet: "/images/photos/people/jack-smith@2x.jpg 2x",
    },
    authorName: "Jack Smith",
    authorOccupation: "Head of Operations, Parkfield Commerce",
    feedback:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];

export const events = [
  {
    cover: {
      src: "/images/photos/expo-gallery/gallery1.jpg",
      srcSet: "/images/photos/expo-gallery/gallery1@2x.jpg 2x",
    },
    title: "Sales philosophy and future",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    place: "Dojo Club",
    time: "2:00 PM - 5:00 PM",
  },
  {
    cover: {
      src: "/images/photos/expo-gallery/gallery2.jpg",
      srcSet: "/images/photos/expo-gallery/gallery2@2x.jpg 2x",
    },
    title: "Introduction to Design Thinking",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    place: "Upstairs",
    time: "11:30 AM - 1:00 AM",
  },
  {
    cover: {
      src: "/images/photos/expo-gallery/gallery3.jpg",
      srcSet: "/images/photos/expo-gallery/gallery3@2x.jpg 2x",
    },
    title: "Instagram grow experiments",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    place: "Loop Lab",
    time: "6:15 PM - 8:00 PM",
  },
];

export const gallery = [
  {
    image: {
      src: "/images/photos/people/akachi-luccini.jpg",
      srcSet: "/images/photos/people/akachi-luccini@2x.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
  {
    image: {
      src: "/images/photos/people/alex-johnson.jpg",
      srcSet: "/images/photos/people/alex-johnson@2x.jpg 2x",
    },
    title: "gallery",
    cols: 2,
  },
  {
    image: {
      src: "/images/photos/people/jack-smith.jpg",
      srcSet: "/images/photos/people/jack-smith@2x.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
  {
    image: {
      src: "/images/photos/people/kate-segelson.jpg",
      srcSet: "/images/photos/people/kate-segelson@2x.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
  {
    image: {
      src: "/images/photos/people/veronica-adams.jpg",
      srcSet: "/images/photos/people/veronica-adams@2x.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
  {
    image: {
      src: "/images/photos/people/valeria-kogan.jpg",
      srcSet: "/images/photos/people/valeria-kogan@2x.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
];

export const mapData = [
  {
    location: {
      y: 45.453211,
      x: 9.248383,
      address: "Via A.G. Alaimo 147, 55027",
    },
    phone: "0383 4586173",
    products: [
      "/images/photos/coworking/coworking-main-hero.jpg",
      "/images/photos/coworking/place1.jpg",
      "/images/photos/coworking/place2.jpg",
      "/images/photos/coworking/place3.jpg",
    ],
  },
  {
    location: {
      y: 45.419211,
      x: 9.021383,
      address: "Via Rocca de Baldi 95, 440368",
    },
    phone: "0358 4880742",
    products: [
      "/images/photos/coworking/coworking-main-hero.jpg",
      "/images/photos/coworking/place1.jpg",
      "/images/photos/coworking/place2.jpg",
      "/images/photos/coworking/place3.jpg",
    ],
  },
  {
    location: {
      y: 45.473211,
      x: 9.298383,
      address: "Via Firenze 134, 45588",
    },
    phone: "0315 1869381",
    products: [
      "/images/photos/coworking/coworking-main-hero.jpg",
      "/images/photos/coworking/place1.jpg",
      "/images/photos/coworking/place2.jpg",
      "/images/photos/coworking/place3.jpg",
    ],
  },
  {
    location: {
      y: 45.461211,
      x: 9.000383,
      address: "Via Cavour 29, 201558",
    },
    phone: "0387 5074352",
    products: [
      "/images/photos/coworking/coworking-main-hero.jpg",
      "/images/photos/coworking/place1.jpg",
      "/images/photos/coworking/place2.jpg",
      "/images/photos/coworking/place3.jpg",
    ],
  },
  {
    location: {
      y: 45.413211,
      x: 9.398383,
      address: "Via Bologna 33, 220156",
    },
    phone: "0315 1869985",
    products: [
      "/images/photos/coworking/coworking-main-hero.jpg",
      "/images/photos/coworking/place1.jpg",
      "/images/photos/coworking/place2.jpg",
      "/images/photos/coworking/place3.jpg",
    ],
  },
  {
    location: {
      y: 45.569211,
      x: 9.128383,
      address: "Vicolo Tre Marchetti 127, 350125",
    },
    phone: "0352 9890835",
    products: [
      "/images/photos/coworking/coworking-main-hero.jpg",
      "/images/photos/coworking/place1.jpg",
      "/images/photos/coworking/place2.jpg",
      "/images/photos/coworking/place3.jpg",
    ],
  },
  {
    location: {
      y: 45.483211,
      x: 9.148383,
      address: "Via Lombardi 146, 45830",
    },
    phone: "0379 1768288",
    products: [
      "/images/photos/coworking/coworking-main-hero.jpg",
      "/images/photos/coworking/place1.jpg",
      "/images/photos/coworking/place2.jpg",
      "/images/photos/coworking/place3.jpg",
    ],
  },
  {
    location: {
      y: 45.313211,
      x: 9.012383,
      address: "Via Guantai Nuovi 99, 56989",
    },
    phone: "0379 1768849",
    products: [
      "/images/photos/coworking/coworking-main-hero.jpg",
      "/images/photos/coworking/place1.jpg",
      "/images/photos/coworking/place2.jpg",
      "/images/photos/coworking/place3.jpg",
    ],
  },
];
