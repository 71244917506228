import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import validate from "validate.js";
import { LearnMoreLink } from "components/atoms";
import { signUp } from "store/actions/auth";
import { ACCOUNT_ONBOARD, LOGIN_ROUTE } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
  loadingIndicator: {
    margin: 8,
  },
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
  // firstName: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 120,
  //   },
  // },
  // lastName: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 120,
  //   },
  // },
  displayName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 120,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
  },
};

const Form = ({ history }) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.authUser);
  const registerIsLoading = useSelector((state) => state.ui.registerIsLoading);
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    if (authUser) {
      history.replace(ACCOUNT_ONBOARD);
    }
  }, [authUser, history]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.isValid) {
      // history.push("/");
      const { values } = formState;
      dispatch(signUp(values, history));
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.root}>
      <form name="register-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="Public display name"
              label="Public display name *"
              variant="outlined"
              size="medium"
              name="displayName"
              fullWidth
              helperText={
                hasError("displayName") ? formState.errors.displayName[0] : null
              }
              error={hasError("displayName")}
              onChange={handleChange}
              type="text"
              value={formState.values.displayName || ""}
            />
          </Grid>
          {/*<Grid item xs={6}>*/}
          {/*  <TextField*/}
          {/*    placeholder="First name"*/}
          {/*    label="First name *"*/}
          {/*    variant="outlined"*/}
          {/*    size="medium"*/}
          {/*    name="firstName"*/}
          {/*    fullWidth*/}
          {/*    helperText={*/}
          {/*      hasError("firstName") ? formState.errors.firstName[0] : null*/}
          {/*    }*/}
          {/*    error={hasError("firstName")}*/}
          {/*    onChange={handleChange}*/}
          {/*    type="text"*/}
          {/*    value={formState.values.firstName || ""}*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={6}>*/}
          {/*  <TextField*/}
          {/*    placeholder="Last name"*/}
          {/*    label="Last name *"*/}
          {/*    variant="outlined"*/}
          {/*    size="medium"*/}
          {/*    name="lastName"*/}
          {/*    fullWidth*/}
          {/*    helperText={*/}
          {/*      hasError("lastName") ? formState.errors.lastName[0] : null*/}
          {/*    }*/}
          {/*    error={hasError("lastName")}*/}
          {/*    onChange={handleChange}*/}
          {/*    type="text"*/}
          {/*    value={formState.values.lastName || ""}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <TextField
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError("email") ? formState.errors.email[0] : null}
              error={hasError("email")}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Password"
              label="Password *"
              variant="outlined"
              size="medium"
              name="password"
              fullWidth
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              error={hasError("password")}
              onChange={handleChange}
              type="password"
              value={formState.values.password || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                Fields that are marked with * sign are required.
              </Typography>
            </i>
          </Grid>
          {!registerIsLoading ? (
            <Grid item xs={12}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.loadingContainer}>
              <CircularProgress
                className={classes.loadingIndicator}
                color="primary"
                size={30}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
              Already have an account?{" "}
              <LearnMoreLink title="Sign in" href={LOGIN_ROUTE} />
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

Form.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Form);
