import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "./components";
import { LearnMoreLink } from "components/atoms";
import { DownloadFooter, SectionHeader } from "components/molecules";
import { Section } from "components/organisms";
import { REGISTER_ROUTE } from "constants/routes";
import { SIGN_IN_TITLE, SIGN_UP_TITLE } from "constants/messages";

const meta = {
  title: "Holler Date | Login",
  description: "Login to your account.",
};

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const SignIn = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            title={SIGN_IN_TITLE}
            subtitle={
              <span>
                Don’t have an account?{" "}
                <LearnMoreLink
                  title={`${SIGN_UP_TITLE}.`}
                  href={REGISTER_ROUTE}
                  typographyProps={{ variant: "h6" }}
                />
              </span>
            }
            titleProps={{
              variant: "h3",
            }}
          />
          <Form />
        </div>
      </Section>
      <DownloadFooter />
    </div>
  );
};

export default SignIn;
