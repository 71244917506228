import React from "react";
import ReactWebcam from "react-webcam";
import { useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user",
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Webcam({ showWebcam, closeWebcam, takeScreenshot }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const webcamRef = React.useRef(null);
  const onCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    takeScreenshot(imageSrc);
  }, [webcamRef]);

  const handleClose = () => {
    closeWebcam();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="image-crop-dialog-title"
      open={showWebcam}
      PaperProps={{
        style: {
          width: "100%",
        },
      }}
    >
      <DialogTitle id="image-crop-dialog-title" onClose={handleClose}>
        Take Selfie
      </DialogTitle>
      <DialogContent dividers>
        <ReactWebcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          height={400}
          width={400}
          videoConstraints={videoConstraints}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCapture} color="secondary">
          Take photo
        </Button>
      </DialogActions>
    </Dialog>
  );
}
