import isEmpty from "lodash.isempty";
import {
  MISSING_AUDIO,
  MISSING_PHOTO,
  MISSING_GENDER,
} from "constants/members";

export const incompleteFieldsHelper = (profile) => {
  if (!profile) {
    return {
      [MISSING_AUDIO]: true,
      [MISSING_PHOTO]: true,
      [MISSING_GENDER]: true,
    };
  }
  const incompleteFields = {};
  const { audioURL, photoURL, demographic } = profile;
  if (isEmpty(audioURL)) {
    incompleteFields[MISSING_AUDIO] = true;
  }
  if (isEmpty(photoURL)) {
    incompleteFields[MISSING_PHOTO] = true;
  }
  if (isEmpty(demographic?.gender)) {
    incompleteFields[MISSING_GENDER] = true;
  }
  return incompleteFields;
};

export const generateIncompleteProfileText = (profile) => {
  let incompleteFieldsText = "";
  const incompleteFields = incompleteFieldsHelper(profile);
  if (incompleteFields[MISSING_GENDER]) {
    incompleteFieldsText += "gender";
  }
  if (incompleteFields[MISSING_AUDIO]) {
    incompleteFieldsText += !isEmpty(incompleteFieldsText)
      ? ", voice clip"
      : "voice clip";
  }
  if (incompleteFields[MISSING_GENDER]) {
    incompleteFieldsText += !isEmpty(incompleteFieldsText)
      ? ", profile photo"
      : "profile photo";
  }
  return incompleteFieldsText;
};
