import {
  AUTH_UI_START_LOADING,
  AUTH_UI_STOP_LOADING,
  REGISTER_UI_START_LOADING,
  REGISTER_UI_STOP_LOADING,
  RESET_UI_START_LOADING,
  RESET_UI_STOP_LOADING,
  MEMBERS_UI_START_LOADING,
  MEMBERS_UI_STOP_LOADING,
  MEMBERS_UI_UPDATE_START_LOADING,
  MEMBERS_UI_UPDATE_STOP_LOADING,
  CHANGE_UI_START_LOADING,
  CHANGE_UI_STOP_LOADING,
  ADMIN_UI_START_LOADING,
  ADMIN_UI_STOP_LOADING,
  DIALOG_UI_DISPLAY,
  SET_SNACK_BAR_MESSAGE,
  DIALOG_UI_RESET_DISPLAY,
  PLAN_UI_START_LOADING,
  PLAN_UI_STOP_LOADING,
  CHAT_UI_START_LOADING,
  CHAT_UI_STOP_LOADING,
  CHAT_UPDATE_START_LOADING,
  CHAT_UPDATE_STOP_LOADING,
  SELECTED_PLAN_CONFIRMATION_UI_MODAL_DISPLAY,
  FILTER_DISPLAY_UI_MODAL_DISPLAY,
} from "store/types/uiTypes";

const initialState = {
  authIsLoading: false,
  adminIsLoading: false,
  registerIsLoading: false,
  resetIsLoading: false,
  changeIsLoading: false,
  membersIsLoading: false,
  membersUpdateIsLoading: false,
  chatIsLoading: false,
  chatUpdateIsLoading: false,
  planIsLoading: false,
  dialog: null,
  snackBarMessage: null,
  selectedPricingPlan: null,
  filterDisplay: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_UI_START_LOADING:
      return {
        ...state,
        authIsLoading: true,
      };
    case AUTH_UI_STOP_LOADING:
      return {
        ...state,
        authIsLoading: false,
      };
    case ADMIN_UI_START_LOADING:
      return {
        ...state,
        adminIsLoading: true,
      };
    case ADMIN_UI_STOP_LOADING:
      return {
        ...state,
        adminIsLoading: false,
      };
    case REGISTER_UI_START_LOADING:
      return {
        ...state,
        registerIsLoading: true,
      };
    case REGISTER_UI_STOP_LOADING:
      return {
        ...state,
        registerIsLoading: false,
      };
    case RESET_UI_START_LOADING:
      return {
        ...state,
        resetIsLoading: true,
      };
    case RESET_UI_STOP_LOADING:
      return {
        ...state,
        resetIsLoading: false,
      };
    case CHANGE_UI_START_LOADING:
      return {
        ...state,
        changeIsLoading: true,
      };
    case CHANGE_UI_STOP_LOADING:
      return {
        ...state,
        changeIsLoading: false,
      };
    case MEMBERS_UI_START_LOADING:
      return {
        ...state,
        membersIsLoading: true,
      };
    case MEMBERS_UI_STOP_LOADING:
      return {
        ...state,
        membersIsLoading: false,
      };
    case MEMBERS_UI_UPDATE_START_LOADING:
      return {
        ...state,
        membersUpdateIsLoading: true,
      };
    case MEMBERS_UI_UPDATE_STOP_LOADING:
      return {
        ...state,
        membersUpdateIsLoading: false,
      };
    case PLAN_UI_START_LOADING:
      return {
        ...state,
        planIsLoading: true,
      };
    case PLAN_UI_STOP_LOADING:
      return {
        ...state,
        planIsLoading: false,
      };
    case CHAT_UI_START_LOADING:
      return {
        ...state,
        chatIsLoading: true,
      };
    case CHAT_UI_STOP_LOADING:
      return {
        ...state,
        chatIsLoading: false,
      };
    case CHAT_UPDATE_START_LOADING:
      return {
        ...state,
        chatUpdateIsLoading: true,
      };
    case CHAT_UPDATE_STOP_LOADING:
      return {
        ...state,
        chatUpdateIsLoading: false,
      };
    case DIALOG_UI_DISPLAY:
      return {
        ...state,
        dialog: action.dialog,
      };
    case DIALOG_UI_RESET_DISPLAY:
      return {
        ...state,
        dialog: null,
      };
    case SELECTED_PLAN_CONFIRMATION_UI_MODAL_DISPLAY:
      return {
        ...state,
        selectedPricingPlan: action.selectedPricingPlan,
      };
    case FILTER_DISPLAY_UI_MODAL_DISPLAY:
      return {
        ...state,
        filterDisplay: action.filterDisplay,
      };
    case SET_SNACK_BAR_MESSAGE:
      return {
        ...state,
        snackBarMessage: action.snackBarMessage,
      };
    default:
      return state;
  }
};

export default reducer;
