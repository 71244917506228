import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash.isempty";
import validate from "validate.js";
import { ReactMicGold } from "react-mic-gold";
import AudioPlayer from "react-h5-audio-player";
import {
  profileImageUpload,
  profileImageDelete,
  profileUpdate,
  signOut,
  profileAudioUpload,
  profileAudioDelete,
  deleteAccount,
} from "store/actions/auth";
import { ACCOUNT_ONBOARD, HOME_ROUTE } from "constants/routes";
import { withRouter } from "react-router-dom";
import { Image, IconText } from "components/atoms";
import {
  Dialog,
  DownloadFooter,
  ImageCrop,
  StopWatch,
  Webcam,
} from "components/molecules";
import { getCroppedImg } from "shared/images";
import { profileImg, FB_LARGE } from "shared/avatar";
import { formatCountryOptions } from "shared/config/ui";
import {
  APPROVAL_STATUS,
  AUDIO_UNSUPPORTED,
  COMPLETE_PROFILE,
  MISSING_AUDIO_TAG_LABEL,
  MISSING_GENDER_TAG_LABEL,
  MISSING_PHOTO_TAG_LABEL,
  RECORD_AUDIO,
  RECORD_STOP_AUDIO,
  DELETE_CONFIRMATION_MESSAGE,
  DELETE_CONFIRMATION_TITLE,
  DELETE_ACCOUNT,
  AUDIO_PERMISSION_REQUEST,
} from "constants/messages";
import { WARNING, SUCCESS, ERROR, LABEL, WHITE } from "constants/colors";
import { APPROVED, INCOMPLETE, SUSPENDED } from "constants/auth";
import { MISSING_AUDIO, MISSING_PHOTO } from "constants/members";
import { resetDialog, setDialog, setSnackBarMessage } from "store/actions/ui";
import { isMobileSafari, isSafari } from "react-device-detect";
import { incompleteFieldsHelper } from "store/utils/members";
import { deleteAccountDialogActions } from "store/utils/ui";

const useStyles = makeStyles((theme) => ({
  root: {},
  image: {
    objectFit: "cover",
    borderRadius: theme.spacing(1),
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  titleCta: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  profileImageCta: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  profileImageContainer: {
    display: "flex",
    flex: 0.2,
  },
  profileImageOptionsContainer: {
    display: "flex",
    flex: 0.5,
    flexDirection: "column",
    margin: "0 32px",
    alignItems: "flex-start",
  },
  profileImageOptions: {
    margin: "8px 0",
    width: 150,
  },
  statusIncomplete: {
    backgroundColor: WARNING,
    color: WHITE,
    margin: "8px 0",
  },
  statusLive: {
    backgroundColor: SUCCESS,
    color: WHITE,
    margin: "8px 0",
  },
  statusSuspended: {
    backgroundColor: ERROR,
    color: WHITE,
    margin: "8px 0",
  },
  statusProcessing: {
    backgroundColor: LABEL,
    color: WHITE,
    margin: "8px 0",
  },
  completePrompt: {
    margin: "0 8px",
    borderColor: WARNING,
    color: WARNING,
    "&:hover": {
      borderColor: WARNING,
      color: WARNING,
    },
  },
  statusContainer: {
    paddingTop: "0px !important",
  },
  incompleteTagContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 8,
  },
  audioCta: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
  },
  canvasContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "8px 0",
  },
  canvas: {
    width: "60%",
  },
  recordButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  timerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 16,
  },
  playBackContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 16,
    width: "80%",
  },
  deleteAccount: {
    margin: "0 8px",
  },
}));

const schema = {
  displayName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 120,
    },
  },
  age: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 18,
      lessThanOrEqualTo: 90,
    },
  },
};

const genderOptions = {
  male: { title: "Man", code: "male" },
  female: { title: "Woman", code: "female" },
};

const unsupportedBrowsers = () => isSafari || isMobileSafari;

const General = (props) => {
  const { className, history, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.authUser);
  const authIsLoading = useSelector((state) => state.ui.authIsLoading);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [profile, setProfile] = useState(null);
  const [countryRegions, setCountryRegions] = useState(formatCountryOptions());
  const [regions, setRegions] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [regionCode, setRegionCode] = useState("");
  const [recording, setRecording] = useState(false);
  const [runAudioTimer, setRunAudioTimer] = useState(false);
  const [gender, setGender] = useState("");
  const [genderPreference, setGenderPreference] = useState("");

  // photo uploads
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [selfie, setSelfie] = useState(false);
  // end photo uploads

  // audio uploads
  const [audioURL, setAudioURL] = useState(null);
  const duration = useRef(0);
  // end audio uploads

  useEffect(() => {
    if (authUser && countryCode && !isEmpty(countryRegions)) {
      const regionData = countryRegions[countryCode];
      const { regions } = regionData;
      setRegions(regions);
      const { demographic } = profile || {};
      if (countryCode === demographic.country) {
        setRegionCode(demographic.region);
      } else {
        setRegionCode("");
      }
    }
  }, [countryCode, authUser, countryRegions, profile]);

  useEffect(() => {
    if (authUser) {
      setProfile(authUser.profile);
      const { demographic, displayName } = authUser.profile;
      const { country, gender, genderPreference, region, age } =
        demographic || {};
      setCountryCode(country);
      setRegionCode(region);
      setGenderPreference(genderPreference);
      setGender(gender);
      setAudioURL(authUser.profile.audioURL);
      setFormState((formState) => ({
        ...formState,
        values: {
          age,
          gender,
          region,
          country,
          genderPreference,
          email: authUser.session.email,
          displayName,
        },
      }));
    }
  }, [authUser]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    if (!recording) {
      setRunAudioTimer(false);
    }
  }, [runAudioTimer, recording]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.isValid) {
      const profileData = {
        displayName: formState.values.displayName,
        demographic: {
          age: formState.values.age,
          country: formState.values.country,
          region: formState.values.region,
          gender: formState.values.gender,
          genderPreference: formState.values.genderPreference,
        },
      };
      dispatch(profileUpdate(profileData));
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const handleDeleteAccount = () => {
    dispatch(resetDialog());
    dispatch(deleteAccount(history));
  };

  const handleDeleteAccountPrompt = (event) => {
    event.preventDefault();
    dispatch(
      setDialog(
        DELETE_CONFIRMATION_TITLE,
        DELETE_CONFIRMATION_MESSAGE,
        deleteAccountDialogActions(dispatch, () => handleDeleteAccount())
      )
    );
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const handleSignOut = () => {
    dispatch(signOut());
    history.replace(HOME_ROUTE);
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const handleUploadSelect = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const handleSelfieSelect = () => {
    setSelfie(true);
  };

  const handleProfilePictureDelete = () => {
    dispatch(profileImageDelete());
  };

  const handleProfileAudioUpdate = (blob) => {
    if (blob) {
      dispatch(profileAudioUpload(blob.blob, duration.current));
    }
  };

  const handleProfileAudioDelete = (blob) => {
    dispatch(profileAudioDelete());
  };

  const handleBlock = () => {
    dispatch(
      setSnackBarMessage({
        message: AUDIO_PERMISSION_REQUEST,
        snackColor: ERROR,
        autoHideDuration: 3000,
      })
    );
  };

  // const onData = (blob) => {
  //   if (blob) {
  //     // setAudioDataStart(true);
  //   }
  // };

  const onStart = () => {
    setRunAudioTimer(true);
  };

  // called from audio mic => cannot retrieve redux state
  // only use ref works
  const onStop = (blob) => {
    handleProfileAudioUpdate(blob);
  };

  const configureStatusStyles = (status) => {
    if (status === APPROVED) {
      return classes.statusLive;
    } else if (status === INCOMPLETE) {
      return classes.statusIncomplete;
    } else if (status === SUSPENDED) {
      return classes.statusSuspended;
    } else {
      return classes.statusProcessing;
    }
  };

  const renderIncompleteTags = () => {
    const incompleteFields = incompleteFieldsHelper(profile);
    if (isEmpty(incompleteFields)) return null;
    return (
      <div className={classes.incompleteTagContainer}>
        {Object.keys(incompleteFields).map((keyID) => {
          if (keyID === MISSING_PHOTO && incompleteFields[keyID]) {
            return (
              <IconText
                key={keyID}
                iconProps={{
                  size: "extraSmall",
                }}
                fontIconClass="fas fa-portrait"
                color={ERROR}
                title={MISSING_PHOTO_TAG_LABEL}
              />
            );
          } else if (keyID === MISSING_AUDIO) {
            return (
              <IconText
                key={keyID}
                iconProps={{
                  size: "extraSmall",
                }}
                fontIconClass="fas fa-microphone"
                color={ERROR}
                title={MISSING_AUDIO_TAG_LABEL}
              />
            );
          } else {
            return (
              <IconText
                key={keyID}
                iconProps={{
                  size: "extraSmall",
                }}
                fontIconClass="fas fa-venus-mars"
                color={ERROR}
                title={MISSING_GENDER_TAG_LABEL}
              />
            );
          }
        })}
      </div>
    );
  };

  const recordAudio = () => {
    if (unsupportedBrowsers()) {
      dispatch(
        setSnackBarMessage({
          message: AUDIO_UNSUPPORTED,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
      return;
    }

    if (recording) {
      console.log("ending audio data start");
      setRecording(false);
    } else {
      console.log("starting audio data start");
      setRecording(true);
    }
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
  };

  const saveCropDialog = async (imageElement, crop) => {
    if (imageElement && crop.width && crop.height && authUser) {
      try {
        const croppedImage = await getCroppedImg(imageElement, crop, document);
        dispatch(profileImageUpload(croppedImage));
        closeCropDialog();
      } catch (err) {
        console.error("failed to crop image", err);
      }
    }
  };

  const closeCropDialog = () => {
    setFile(null);
    setImage(null);
    document.getElementById("imageInput").value = "";
  };

  const avatar = profileImg(
    {
      photoURL: profile?.photoURL,
      displayName: profile?.displayName,
      socialAvatar: profile?.socialAvatar,
    },
    160,
    FB_LARGE
  );

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <input
        type="file"
        id="imageInput"
        hidden="hidden"
        onChange={handleImageChange}
      />
      <ImageCrop
        image={image}
        closeCrop={closeCropDialog}
        saveCrop={saveCropDialog}
        aspect={1}
        keepSelection={true}
        minWidth={200}
        minHeight={200}
        maxWidth={400}
        maxHeight={400}
      />
      <Webcam
        showWebcam={selfie}
        closeWebcam={() => {
          setSelfie(false);
        }}
        takeScreenshot={(imageSrc) => {
          setSelfie(false);
          setImage(imageSrc);
        }}
      />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <div className={classes.titleCta}>
            <div>
              <Typography variant="h6" color="textPrimary">
                Basic Information
              </Typography>
            </div>
            <Button variant="outlined" color="primary" onClick={handleSignOut}>
              Log out
            </Button>
          </div>
        </Grid>
        <Grid item md={8} xs={12} className={classes.statusContainer}>
          <div>
            <Chip
              className={configureStatusStyles(profile?.status)}
              label={APPROVAL_STATUS(profile?.status)}
              color="secondary"
              // variant="outlined"
            />
            {profile?.status === INCOMPLETE && (
              <Button
                className={classes.completePrompt}
                variant="outlined"
                // color="primary"
                href={ACCOUNT_ONBOARD}
              >
                {COMPLETE_PROFILE}
              </Button>
            )}
          </div>
          {renderIncompleteTags()}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Profile Picture
          </Typography>
          <div className={classes.profileImageCta}>
            <div className={classes.profileImageContainer}>
              <Image
                src={avatar}
                srcSet={avatar}
                className={classes.image}
                lazyProps={{ width: 100, height: 100 }}
              />
            </div>

            <div className={classes.profileImageOptionsContainer}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleUploadSelect}
                className={classes.profileImageOptions}
                // style={{ marginLeft: 16 }}
              >
                album
              </Button>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                size="large"
                className={classes.profileImageOptions}
                onClick={handleSelfieSelect}
              >
                selfie
              </Button>
              {profile?.photoRef && (
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className={classes.profileImageOptions}
                  onClick={handleProfilePictureDelete}
                >
                  delete
                </Button>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Audio Clip
          </Typography>
          <div className={classes.audioCta}>
            <div className={classes.timerContainer}>
              <StopWatch
                autoStart={runAudioTimer}
                durationCallback={(seconds) => {
                  duration.current = seconds;
                }}
                maxDuration={15}
                maxDurationCallback={() => {
                  setRecording(false);
                }}
              />
            </div>
            <div className={classes.canvasContainer}>
              <ReactMicGold
                className={classes.canvas}
                // visualSetting="frequencyBars"
                mimeType="audio/mp3"
                record={recording}
                // onData={(blob) => onData(blob)}
                onBlock={handleBlock} // available in gold version only
                onStart={onStart}
                onStop={onStop}
                strokeColor={LABEL}
                backgroundColor={WHITE}
                echoCancellation={true}
                autoGainControl={true}
                noiseSuppression={true}
                bitRate={320000}
              />
            </div>
            {audioURL && !recording && (
              <div className={classes.playBackContainer}>
                <AudioPlayer src={audioURL} volume={0.5} />
              </div>
            )}
            <div className={classes.recordButtonContainer}>
              <Button
                type="submit"
                variant="outlined"
                color={recording ? "secondary" : "primary"}
                size="large"
                onClick={recordAudio}
                style={{ width: 200, marginTop: 16 }}
              >
                {recording ? RECORD_STOP_AUDIO : RECORD_AUDIO}
              </Button>
            </div>
            {audioURL && !recording && (
              <div className={classes.recordButtonContainer}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={handleProfileAudioDelete}
                  style={{ width: 200, marginTop: 16 }}
                >
                  delete audio
                </Button>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Full name
          </Typography>
          <TextField
            placeholder="Your public display name"
            variant="outlined"
            size="medium"
            name="displayName"
            fullWidth
            type="text"
            helperText={
              hasError("displayName") ? formState.errors.displayName[0] : null
            }
            error={hasError("displayName")}
            onChange={handleChange}
            value={formState.values.displayName || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            E-mail
          </Typography>
          <TextField
            disabled
            placeholder="Your e-mail address"
            variant="outlined"
            size="medium"
            name="email"
            fullWidth
            type="email"
            helperText={hasError("email") ? formState.errors.email[0] : null}
            error={hasError("email")}
            onChange={handleChange}
            value={formState.values.email || ""}
          />
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <Typography*/}
        {/*    variant="subtitle1"*/}
        {/*    color="textPrimary"*/}
        {/*    className={classes.inputTitle}*/}
        {/*  >*/}
        {/*    Bio*/}
        {/*  </Typography>*/}
        {/*  <TextField*/}
        {/*    placeholder="Your bio"*/}
        {/*    variant="outlined"*/}
        {/*    name="bio"*/}
        {/*    fullWidth*/}
        {/*    multiline*/}
        {/*    rows={4}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Your age (between 18 to 90)
          </Typography>
          <TextField
            placeholder="Your age (between 18 to 90) *"
            variant="outlined"
            size="medium"
            name="age"
            fullWidth
            type="number"
            helperText={hasError("age") ? formState.errors.age[0] : null}
            error={hasError("age")}
            onChange={handleChange}
            value={formState.values.age || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            My Gender
          </Typography>
          <TextField
            select
            label="Your gender *"
            placeholder="Your gender"
            variant="outlined"
            size="medium"
            name="gender"
            value={gender}
            fullWidth
            onChange={(event) => {
              setGender(event.target.value);
              handleChange(event);
            }}
          >
            {Object.keys(genderOptions).map((optionKey) => {
              const option = genderOptions[optionKey];
              const { title, code } = option;
              return (
                <MenuItem key={code} value={code}>
                  {title}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            I am interested in ...
          </Typography>
          <TextField
            select
            label="Gender preference *"
            placeholder="Gender preference"
            variant="outlined"
            size="medium"
            name="genderPreference"
            value={genderPreference}
            fullWidth
            onChange={(event) => {
              setGenderPreference(event.target.value);
              handleChange(event);
            }}
          >
            {Object.keys(genderOptions).map((optionKey) => {
              const option = genderOptions[optionKey];
              const { title, code } = option;
              return (
                <MenuItem key={code} value={code}>
                  {title}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Country
          </Typography>
          <TextField
            select
            placeholder="Country"
            variant="outlined"
            size="medium"
            name="country"
            value={countryCode}
            fullWidth
            onChange={(event) => {
              setCountryCode(event.target.value);
              handleChange(event);
            }}
          >
            {Object.keys(countryRegions).map((code) => {
              const regionData = countryRegions[code];
              const { title } = regionData;
              return (
                <MenuItem key={code} value={code}>
                  {title}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Region
          </Typography>
          <TextField
            select
            placeholder="Region"
            variant="outlined"
            size="medium"
            name="region"
            fullWidth
            value={regionCode}
            onChange={(event) => {
              setRegionCode(event.target.value);
              handleChange(event);
            }}
          >
            {regions.map(({ regionName, regionShortCode }) => (
              <MenuItem key={regionShortCode} value={regionShortCode}>
                {regionName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item container justify="flex-start" xs={12}>
          <Button
            disabled={!formState.isValid}
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            onClick={handleSubmit}
          >
            save
          </Button>
          <Button
            disabled={authIsLoading}
            className={classes.deleteAccount}
            variant="contained"
            color="secondary"
            type="submit"
            size="large"
            onClick={handleDeleteAccountPrompt}
          >
            {DELETE_ACCOUNT}
          </Button>
        </Grid>
      </Grid>
      <Dialog />
      <DownloadFooter />
    </div>
  );
};

General.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default withRouter(General);
