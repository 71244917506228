import axios from "axios";
import { store } from "shared/firebase";
import { SET_PLAN } from "store/types/planTypes";

const planCollection = store.collection("plans");

export const queryPlan = (userId) => {
  try {
    return planCollection.where("userId", "==", userId).limit(1);
  } catch (err) {
    console.log("query plan err", err);
  }
};

export const createPlan = () => {
  return async (dispatch, getState) => {
    try {
      const res = await axios.post("/plan", {});
      const plan = res.data;
      dispatch(setPlan(plan));
    } catch (err) {
      console.error("create plan err", err);
    }
  };
};

export const setPlan = (plan) => {
  return {
    type: SET_PLAN,
    plan: plan,
  };
};
