import React from "react";
// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Image } from "../../atoms";
import { APP_STORE_ROUTE, PLAY_STORE_ROUTE } from "constants/routes";
import { isAndroidDevice, isIOSDevice } from "store/utils/ui";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 0,
    marginLeft: 0,
  },
  playStoreContainer: {
    display: "flex",
    justifyContent: "flex-end", // "center"
  },
  playStoreBtn: {
    maxWidth: 180,
  },
}));

export default function StoreOptions(props) {
  // const { playStoreWidth, playStoreHeight, appleWidth, appleHeight } = props;
  const classes = useStyles();
  if (isAndroidDevice(navigator)) {
    return (
      <Grid container className={classes.root}>
        <Grid item sm={12} className={classes.playStoreContainer}>
          <a href={PLAY_STORE_ROUTE} rel="noopener noreferrer" target="_blank">
            <Image
              src="/images/mobile-addons/play-store.png"
              alt="Get in on Play Market"
              className={classes.playStoreBtn}
              lazy={false}
            />
          </a>
        </Grid>
      </Grid>
    );
  }

  if (isIOSDevice(navigator)) {
    return (
      <Grid container className={classes.root}>
        <Grid item sm={12} className={classes.playStoreContainer}>
          <a href={APP_STORE_ROUTE} rel="noopener noreferrer" target="_blank">
            <Image
              src="/images/mobile-addons/app-store.png"
              alt="Get in on App Store"
              className={classes.playStoreBtn}
              lazy={false}
            />
          </a>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={6} sm={6} className={classes.playStoreContainer}>
        <a href={PLAY_STORE_ROUTE} rel="noopener noreferrer" target="_blank">
          <Image
            src="/images/mobile-addons/play-store.png"
            alt="Get in on Play Market"
            className={classes.playStoreBtn}
            lazy={false}
          />
        </a>
      </Grid>
      <Grid item md={6} sm={6} className={classes.playStoreContainer}>
        <a href={APP_STORE_ROUTE} rel="noopener noreferrer" target="_blank">
          <Image
            src="/images/mobile-addons/app-store.png"
            alt="Get in on App Store"
            className={classes.playStoreBtn}
            lazy={false}
          />
        </a>
      </Grid>
    </Grid>
  );
}
