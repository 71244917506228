import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Avatar,
  ButtonBase,
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
  colors,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Image } from "components/atoms";
import {
  ACCOUNT_GENERAL_ROUTE,
  BROWSE_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
} from "constants/routes";
import { BROWSE_DATES, LOGIN, REGISTER } from "constants/messages";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    background: theme.palette.white,
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolbar: {
    maxWidth: 1100,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
  },
  navLink: {
    fontWeight: 300,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: "pointer",
    "&:hover > .menu-item, &:hover svg": {
      color: theme.palette.primary.dark,
    },
  },
  listItemActive: {
    "&> .menu-item": {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  listItemIcon: {
    minWidth: "auto",
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: "0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)",
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up("md")]: {
      width: 120,
      height: 32,
    },
  },
  logoImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuItem: {
    marginRight: theme.spacing(5),
    "&:last-child": {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: "uppercase",
  },
}));

const TopBar = (props) => {
  const { className, onSidebarOpen, pages, history, ...rest } = props;
  const classes = useStyles();
  const authUser = useSelector((state) => state.auth.authUser);

  return (
    <AppBar
      {...rest}
      position="relative"
      className={clsx(classes.root, className)}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <a href="/" title="holler_date">
            <Image
              className={classes.logoImage}
              src="/custom_images/logos/logo.png"
              alt="holler_date"
              lazy={false}
            />
          </a>
        </div>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <List className={classes.navigationContainer}>
            <ListItem className={classes.listItem}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.listItemText}
                component="a"
                target="blank"
                href={BROWSE_ROUTE}
              >
                {BROWSE_DATES}
              </Typography>
            </ListItem>
            {!authUser ? (
              <>
                <ListItem className={classes.listItem}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.listItemText}
                    component="a"
                    target="blank"
                    href={LOGIN_ROUTE}
                  >
                    {LOGIN}
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    component="a"
                    target="blank"
                    href={REGISTER_ROUTE}
                  >
                    {REGISTER}
                  </Button>
                </ListItem>
              </>
            ) : (
              <>
                <ListItem className={classes.listItem}>
                  <ButtonBase href={ACCOUNT_GENERAL_ROUTE}>
                    <Avatar
                      alt={authUser.profile?.displayName}
                      src={authUser.profile?.photoURL}
                      className={classes.avatar}
                    />
                  </ButtonBase>

                  {/*<Button*/}
                  {/*  size="large"*/}
                  {/*  variant="contained"*/}
                  {/*  color="primary"*/}
                  {/*  component="a"*/}
                  {/*  target="blank"*/}
                  {/*  href={ACCOUNT_GENERAL_ROUTE}*/}
                  {/*>*/}
                  {/*  {ACCOUNT}*/}
                  {/*</Button>*/}
                </ListItem>
              </>
            )}
          </List>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            onClick={onSidebarOpen}
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
};

export default withRouter(TopBar);
