import React, { useEffect, useState, useRef } from "react";
import isNumber from "is-number";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  timer: {
    fontWeight: 700,
  },
}));

const RECORD_STARTER_TIME = "0 s";

const StopWatch = ({
  autoStart,
  durationCallback,
  maxDuration,
  maxDurationCallback,
}) => {
  const intervalRef = useRef(0);
  const classes = useStyles();
  const [seconds, setSeconds] = useState(0);
  const [formatted, setFormatted] = useState(RECORD_STARTER_TIME);

  useEffect(() => {
    const timer = () => {
      intervalRef.current = setInterval(() => {
        counter();
      }, 1000);
    };

    const counter = () => {
      setSeconds((prevSecond) => prevSecond + 1);
    };

    if (autoStart) {
      timer();
    } else {
      clearInterval(intervalRef.current);
      setSeconds(0);
    }
    return () => clearInterval(intervalRef.current);
  }, [autoStart]);

  useEffect(() => {
    if (isNumber(seconds) && seconds > 0) {
      durationCallback(seconds);
    }
  }, [durationCallback, seconds]);

  useEffect(() => {
    setFormatted(`${seconds} s`);
    if (seconds === maxDuration) {
      clearInterval(intervalRef.current);
      maxDurationCallback();
    }
  }, [maxDuration, maxDurationCallback, seconds]);

  return (
    <Typography variant="h4" color="textPrimary" className={classes.timer}>
      {formatted}
    </Typography>
  );
};

export default StopWatch;
