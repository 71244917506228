import isEmpty from "lodash.isempty";
import moment from "moment";
import { store, GeoPoint } from "shared/firebase";
import {
  chatUIStartLoading,
  chatUIStopLoading,
  chatUpdateStartLoading,
  chatUpdateStopLoading,
} from "./ui";
import {
  CHAT_SNAPSHOT,
  GET_CHATS,
  RESET_CHATS,
  SET_ROOM,
} from "../types/chatTypes";
import axios from "axios";

const roomsCollection = store.collection("rooms");
const chatsCollection = store.collection("chats");

export const queryRoom = (senderId, receiverId) => {
  return roomsCollection
    .where(`recipients.${senderId}`, "==", true)
    .where(`recipients.${receiverId}`, "==", true);
};

export const queryRoomById = (roomId) => {
  if (!roomId) return null;
  return roomsCollection.doc(roomId);
};

export const getRoom = (senderId, receiverId) => {
  return async (dispatch, getState) => {
    const query = queryRoom(senderId, receiverId);
    const documents = await query.get();
    const docs = documents.docs;
    const roomSnapshot = isEmpty(docs) ? null : docs[0];
    if (roomSnapshot?.exists) {
      const room = roomSnapshot.data();
      dispatch(setRoom({ docId: roomSnapshot.id, ...room }));
    } else {
      dispatch(setRoom(null));
    }
  };
};

export const queryChatLogs = (roomId, lastSnapshot) => {
  try {
    if (!roomId) return null;
    let query = chatsCollection
      .orderBy("createdAt", "asc")
      .where("roomId", "==", roomId);
    query = lastSnapshot ? query.startAfter(lastSnapshot) : query;
    return query;
  } catch (err) {
    console.log("err", err);
  }
};

export const getChatLogs = (roomId, pageSize) => {
  return async (dispatch, getState) => {
    try {
      const prevSnapshot = getState().chats.chatSnapshot;
      dispatch(chatUIStartLoading());
      const query = queryChatLogs(roomId, prevSnapshot);
      const documents = await query.get();
      const chats = {};
      const lastSnapshot = !documents.empty
        ? documents.docs[documents.docs.length - 1]
        : null;
      const docs = documents.docs;
      docs.forEach((doc) => {
        const data = doc.data();
        chats[doc.id] = {
          ...data,
          docId: doc.id,
        };
      });
      dispatch(chatUIStopLoading());
      dispatch(setChatSnapshot(lastSnapshot));
      dispatch(setChats(chats));
    } catch (err) {
      console.log("err", err);
      dispatch(chatUIStopLoading());
    }
  };
};

export const sendMessage = (
  message,
  roomId,
  sender,
  receiver,
  location = null,
  image = null
) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      const { senderId, senderName, senderImage } = sender;
      const { receiverId, receiverName, receiverImage } = receiver;
      if (!authUser || !senderId || !receiverId || senderId === receiverId) {
        return;
      }
      const { profile } = authUser;
      const { matched } = profile;
      if (!matched[receiverId]) {
        console.log("chat forbidden: receiver not matched with sender");
        return;
      }
      let finalRoomId = null;
      if (roomId) {
        const roomDocuments = roomsCollection.doc(roomId);
        const roomDoc = await roomDocuments.get();
        if (roomDoc.exists) {
          const room = roomDoc.data();
          const { blocked, updatedAt } = room;
          if (!blocked) {
            await roomsCollection.doc(roomId).update({
              updatedAt: new Date().toISOString(),
              lastMessage: message,
            });
            const updatedRoom = await roomDocuments.get();
            finalRoomId = updatedRoom.id;
            dispatch(setRoom({ docId: finalRoomId, ...updatedRoom.data() }));
            // send email notification
            const lastUpdatedDate = new Date(updatedAt);
            const duration = moment.duration(
              moment(new Date()).diff(moment(lastUpdatedDate))
            );
            const hours = duration.hours();
            if (hours >= 1) {
              await axios.post("/chat/notify", {
                receiverId,
              });
            }
          }
        }
      } else {
        const roomsDocument = await roomsCollection.add({
          // we cannot have multiple array-contains in a single query.
          // see https://stackoverflow.com/questions/54987399/firestore-search-array-contains-for-multiple-values for workaround
          recipients: {
            [senderId]: true,
            [receiverId]: true,
          },
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          lastMessage: message,
          blocked: false,
          receiverId,
          receiverName,
          receiverImage: receiverImage,
          senderId,
          senderImage,
          senderName,
          blockCulpritId: null,
        });
        const roomsDoc = await roomsDocument.get();
        finalRoomId = roomsDoc.id;
        dispatch(setRoom({ docId: finalRoomId, ...roomsDoc.data() }));
        // send email notification
        await axios.post("/chat/notify", {
          receiverId,
        });
      }
      if (finalRoomId) {
        await chatsCollection.add({
          roomId: finalRoomId,
          createdAt: new Date().toISOString(),
          authorName: senderName,
          authorImage: senderImage,
          authorId: senderId,
          recipientId: receiverId,
          message,
          location: location
            ? new GeoPoint(location.latitude, location.longitude)
            : null,
          image: image,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const setRoom = (room) => {
  return {
    type: SET_ROOM,
    room: room,
  };
};

export const setChats = (chats) => {
  return {
    type: GET_CHATS,
    chats: chats,
  };
};

export const setChatSnapshot = (chatSnapshot) => {
  return {
    type: CHAT_SNAPSHOT,
    chatSnapshot: chatSnapshot,
  };
};
