import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Form } from "./components";
import { Image } from "components/atoms";
import { HeroShaped } from "components/organisms";
import { SectionHeader } from "components/molecules";
import {
  BROWSE_DATES,
  ONBOARD_COMPLETE_SUBTITLE,
  ONBOARD_COMPLETE_TITLE,
} from "constants/messages";
import { BROWSE_ROUTE } from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .hero-shaped": {
      borderBottom: 0,
    },
    "& .hero-shaped__wrapper": {
      [theme.breakpoints.up("md")]: {
        minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
      },
    },
  },
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
      margin: `0 auto`,
    },
  },
  image: {
    objectFit: "cover",
  },
}));

const Complete = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <HeroShaped
        leftSide={
          <div className={classes.formContainer}>
            <SectionHeader
              title={ONBOARD_COMPLETE_TITLE}
              subtitle={ONBOARD_COMPLETE_SUBTITLE}
              titleProps={{
                variant: "h3",
              }}
              ctaGroup={[
                <Button color="primary" variant="outlined" href={BROWSE_ROUTE}>
                  {BROWSE_DATES}
                </Button>,
              ]}
            />
            <Form />
          </div>
        }
        rightSide={
          <Image
            src="/custom_images/hero/couple.jpg"
            srcSet="/custom_images/hero/couple.jpg"
            alt="couple"
            className={classes.image}
            lazy={false}
          />
        }
      />
    </div>
  );
};

export default Complete;
